import { ERROR_PAYMENT_METHOD_CREATE, SUCCESS_PAYMENT_METHOD_CREATE } from 'constants/notificationMessage';

import { PaymentMethodFormFields } from 'page/PaymentSetting/components/formData';
import usePaymentMethodCreate from 'shared/data-hook/paymentMethod/create/useCreatePaymentMethod';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreatePaymentMethod = () => {
    const { error, loading, paymentMethodCreate } = usePaymentMethodCreate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createPaymentMethod = async (input: PaymentMethodFormFields) => {
        const response = await paymentMethodCreate(input);

        if (error || !response) {
            showError(ERROR_PAYMENT_METHOD_CREATE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PAYMENT_METHOD_CREATE);
        }
    };

    return {
        createPaymentMethod,
        loading,
    };
};

export default useCreatePaymentMethod;
