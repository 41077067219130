import { gql } from '@apollo/client';
import { PriceT } from 'types/Price';

export type DeletePriceResponseData = {
    deletePrice: {
        id: PriceT['id'];
    };
} | null | undefined;

export type DeletePriceVarT = {
    id: PriceT['id'];
};

export const PRICE_DELETE = gql`
    mutation DeletePrice($id: String = "") {
        deletePrice: delete_price_by_pk(id: $id) {
            id
        }
    }
`;
