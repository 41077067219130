import { gql } from '@apollo/client';
import { PriceSeasonT } from 'types/PriceSeason';

export type DuplicatePriceSeasonResponseT = {
    priceDuplicateSeason: DuplicatePriceSeasonResponseDataT;
};

export type DuplicatePriceSeasonResponseDataT = {
    reason: string;
    status: string;
}

export type DuplicatePriceSeasonVarT = {
    appId: string;
    id: PriceSeasonT['id'];
};

export const EMPTY_DUPLICATE_RESULT_DATA: DuplicatePriceSeasonResponseDataT = {
    reason: 'Что-то пошло не так!',
    status: 'error',
};

export const DUPLICATE_PRICE_SEASON = gql`
    mutation DuplicatePriceSeason($appId: String!, $id: String!) {
        priceDuplicateSeason(app_id: $appId,price_season_id: $id,)
        {
            reason
            status
        }
    }
`;
