import { gql } from '@apollo/client';
import { ServiceCategoryT } from 'types/ServiceCategory';

export type DeleteMultipleServiceCategoryResponseT = {
    deleteMultipleServiceCategory: DeleteMultipleServiceCategoryResponseDataT;
};

export type DeleteMultipleServiceCategoryResponseDataT = {
    affectedRows: number;
}

export type DeleteMultipleServiceCategoryVarT = {
    ids: [ServiceCategoryT['id']];
}

export const DELETE_MULTIPLE_SERVICE_CATEGORY = gql`
    mutation DeleteMultipleServiceCategory($ids: [String!] = []) {
        deleteMultipleServiceCategory: delete_service_category(where: {id: {_in: $ids}}) {
            affected_rows
        }
    }
`;
