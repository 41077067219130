import { gql } from '@apollo/client';
import { CoachT } from 'types/Coach';

export type CoachResponseT = {
    coach: CoachT;
};

export type CoachesResponseT = {
    coaches: CoachT[];
    coachesCount: {
        count: number;
    };
}

export const COACHES_QUERY = gql`
    query Coach(
        $limit: Int,
        $offset: Int,
        $orderBy: coach_order_by! = {},
        $where: coach_bool_exp! = {}
    ) {
        coaches: coach(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            employee { full_name }
            color
            active
            work_days
            coach_category { name }
            service_app { label }
        }
        coachesCount: coach_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const COACH_QUERY = gql`
    query Coach($id: String!) {
        coach: coach_by_pk(id: $id) {
            id
            employee { full_name }
            color
            active
            work_days
            coach_category: category_id
            service_app: service_app_id
        }
    }
`;

export const COACH_FILTER_OPTIONS_QUERY = gql`
    query CoachFilterOptions {
        filterOptions: coach(
            order_by: {
                employee: { full_name: asc }
            }
        ) {
            value: id
            employee {
                full_name
            }
        }
    }
`;
