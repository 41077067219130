import { object, string } from 'yup';

export type PaymentMethodFormFields = {
    title: string;
    position: number;
    isActive: boolean;
};

export const PAYMENT_METHOD_DEFAULT_VALUES: PaymentMethodFormFields = {
    title: '',
    position: 0,
    isActive: false,
};

export enum PAYMENT_METHOD_FIELD_KEYS {
    title = 'title',
    position = 'position',
    isActive = 'isActive',
}

export const PAYMENT_METHOD_VALIDATION_SCHEMA = object().shape({
    title: string().required(),
}).required();
