import {
    ERROR_PRICE_SALES_ROUND_CREATE,
    SUCCESS_PRICE_SALES_ROUND_CREATE,
} from 'constants/notificationMessage';

import { PriceSalesRoundFormFields } from 'page/PriceType/components/PriceSalesRound/components/formData';
import usePriceSalesRoundCreate from 'shared/data-hook/priceSalesRound/create/usePriceSalesRoundCreate';
import { useStore } from 'store';

import { useNotification } from '../../useNotification';

const useCreatePriceSalesRound = () => {
    const { showError, showSuccess } = useNotification();
    const { priceTypeId } = useStore('createPriceSalesRound');
    const { priceSalesRoundCreate, loading, error } = usePriceSalesRoundCreate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createPriceSalesRound = async (input: PriceSalesRoundFormFields) => {
        const { status, reason } = await priceSalesRoundCreate({
            ...input,
            priceTypeId,
        });

        if (error || status === 'error') {
            showError(reason || ERROR_PRICE_SALES_ROUND_CREATE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PRICE_SALES_ROUND_CREATE);
        }
    };

    return {
        createPriceSalesRound,
        loading,
    };
};

export default useCreatePriceSalesRound;
