import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PriceMemberCardsT } from 'types/PriceMemberCard';

import { PriceMemberCardDTO } from './PriceMemberCardDTO';

const DTO = t.type({
    priceMemberCards: t.array(PriceMemberCardDTO),
    priceMemberCardsCount: AggregateDTO,
});

export type PriceMemberCardsDTOT = t.TypeOf<typeof DTO>;

export const PriceMemberCardsDTO = tPromise.extendDecoder<PriceMemberCardsDTOT, PriceMemberCardsT>(
    DTO,
    ({
        priceMemberCards,
        priceMemberCardsCount,
    }) => ({
        priceMemberCards,
        priceMemberCardsCount,
    }),
) as t.Type<PriceMemberCardsT, PriceMemberCardsDTOT>;
