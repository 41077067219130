import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

type PropsT = {
    error: boolean;
};

const RangeDateErrorMessage: FC<PropsT> = (props) => {
    const { error } = props;

    if (!error) {
        return null;
    }

    return (
        <Box sx={ { marginRight: 'auto' } }>
            <Typography variant="subtitle2" color="error">
                Время окончания должно быть больше времени начала
            </Typography>
        </Box>
    );
};

export default RangeDateErrorMessage;
