import {
    ERROR_COACH_UPDATE,
    SUCCESS_COACH_UPDATE,
} from 'constants/notificationMessage';

import { CoachFormFields } from 'page/Coach/components/formData';
import useCoachUpdate from 'shared/data-hook/coach/update/useCoachUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CourtT } from 'types/Court';

const useUpdateCoach = () => {
    const { error, loading, coachUpdate } = useCoachUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updateCoach = async (input: CoachFormFields, coachId: CourtT['id']) => {
        const response = await coachUpdate(input, coachId);

        if (error || !response || !response.updateCoach) {
            showError(ERROR_COACH_UPDATE);
        } else {
            showSuccess(SUCCESS_COACH_UPDATE);
        }

        toggleRefetchTrigger();
    };

    return {
        updateCoach,
        loading,
    };
};

export default useUpdateCoach;
