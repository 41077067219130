import { gql } from '@apollo/client';
import { CoachT } from 'types/Coach';

export type CoachDeleteResponseDataT = {
    deleteCoachType: {
        id: CoachT['id'];
    };
} | null | undefined;

export type CoachDeleteVarT = {
    id: CoachT['id'];
};

export const COACH_DELETE = gql`
    mutation DeleteCoach($id: String = "") {
        deleteCoach: delete_coach_by_pk(id: $id) {
            id
        }
    }
`;
