import { PAGE_TITLE_SCHEDULE_SLOT_RESERVE } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useScheduleSlotReserves from 'shared/data-hook/scheduleSlotReserve/fetch/fetchAll/useScheduleSlotReserves';
import { GROUP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/group/GroupQuery';
import { SERVICE_CATEGORY_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceCategory/ServiceCategoryQuery';
import useFetchCoachFilterOptions from 'shared/hook/coach/useFetchCoachFilterOptions';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import ScheduleSlotReserveList from './components/ScheduleSlotReserveList';
import { SCHEDULE_SLOT_RESERVE_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: scheduleSlotReservesData, loading: scheduleSlotReservesLoading, count: rowCount, fetchScheduleSlotReserves,
    } = useScheduleSlotReserves();
    const { setScheduleSlotReserves } = useStore('scheduleSlotReserve');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { loading: coachesLoading } = useFetchCoachFilterOptions();
    const { loading: serviceCategoriesLoading } = useFetchFilterOptions(SERVICE_CATEGORY_FILTER_OPTIONS_QUERY, 'serviceCategory');
    const { loading: groupsLoading } = useFetchFilterOptions(GROUP_FILTER_OPTIONS_QUERY, 'group');

    const loading = serviceCategoriesLoading || coachesLoading || groupsLoading;

    usePageTitle(PAGE_TITLE_SCHEDULE_SLOT_RESERVE);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchScheduleSlotReserves, refetchTrigger, SCHEDULE_SLOT_RESERVE_HEADER_DATA);

    useEffect(() => {
        setScheduleSlotReserves(scheduleSlotReservesData, rowCount);
    }, [scheduleSlotReservesData, scheduleSlotReservesLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel />
            <ScheduleSlotReserveList
                loading={ loading }
                pageSize={ pageSize }
                page={ page }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
        </StyledPageWrap>
    );
});

export default List;
