import {
    ERROR_PRICE_SALES_ROUND_UPDATE,
    SUCCESS_PRICE_SALES_ROUND_UPDATE,
} from 'constants/notificationMessage';

import { PriceSalesRoundFormFields } from 'page/PriceType/components/PriceSalesRound/components/formData';
import usePriceSalesRoundUpdate from 'shared/data-hook/priceSalesRound/update/usePriceSalesRoundUpdate';
import { useStore } from 'store';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

import { useNotification } from '../../useNotification';

const useUpdatePriceSalesRound = () => {
    const { error, loading, priceSalesRoundUpdate } = usePriceSalesRoundUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updatePriceSalesRound = async (
        input: PriceSalesRoundFormFields,
        priceSalesRoundId: PriceSalesRoundT['id'],
        priceTypeId: PriceSalesRoundT['priceTypeId'],
    ) => {
        const response = await priceSalesRoundUpdate(input, priceSalesRoundId, priceTypeId);

        if (error || !response || !response.updatePriceSalesRound) {
            showError(ERROR_PRICE_SALES_ROUND_UPDATE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PRICE_SALES_ROUND_UPDATE);
        }
    };

    return {
        updatePriceSalesRound,
        loading,
    };
};

export default useUpdatePriceSalesRound;
