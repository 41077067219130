import { useMutation } from '@apollo/client';
import { ServiceCategoryFormFields } from 'page/ServiceCategory/components/formData';
import { CreateServiceCategoryDataT } from 'shared/data-hook/serviceCategory/create/CreateServiceCategoryData';
import {
    CreateServiceCategoryVarT,
    SERVICE_CATEGORY_CREATE,
    ServiceCategoryCreateResponseDataT,
} from 'shared/graphql/mutation/serviceCategory/ServiceCategoryCreate';

const useCreateServiceCategory = (): CreateServiceCategoryDataT => {
    const [create, { loading, error }] = useMutation<ServiceCategoryCreateResponseDataT, CreateServiceCategoryVarT>(
        SERVICE_CATEGORY_CREATE,
    );

    const createAction = async (input: ServiceCategoryFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        name: input.name,
                        color: input.color,
                        min_duration: input.minDuration,
                        min_additional_duration: input.minAdditionalDuration,
                        position: input.position,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        loading,
        error,
        serviceCategoryCreate: createAction,
    };
};

export default useCreateServiceCategory;
