import {
    ERROR_SERVICE_CATEGORY_DELETE,
    SUCCESS_SERVICE_CATEGORY_DELETE,
} from 'constants/notificationMessage';

import useServiceCategoryDelete from 'shared/data-hook/serviceCategory/delete/single/useServiceCategoryDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { ServiceCategoryT } from 'types/ServiceCategory';

const useDeleteServiceCategory = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteServiceCategory } = useServiceCategoryDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const serviceCategoryDelete = async (serviceCategoryId: ServiceCategoryT['id']) => {
        const response = await deleteServiceCategory(serviceCategoryId);

        if (error || !response || !response.deleteServiceCategory) {
            showError(ERROR_SERVICE_CATEGORY_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_SERVICE_CATEGORY_DELETE);
        }
    };

    return { serviceCategoryDelete };
};

export default useDeleteServiceCategory;
