import { useMutation } from '@apollo/client';
import {
    DeleteServiceCategoryResponseDataT,
    DeleteServiceCategoryVarT,
    SERVICE_CATEGORY_DELETE,
} from 'shared/graphql/mutation/serviceCategory/ServiceCategoryDelete';
import { ServiceCategoryT } from 'types/ServiceCategory';

import { ServiceCategoryDeleteDataT } from './ServiceCategoryDeleteData';

const useServiceCategoryDelete = (): ServiceCategoryDeleteDataT => {
    const [deleteServiceCategory, { loading: deleteLoading, error: errorDelete }] = useMutation<
        DeleteServiceCategoryResponseDataT,
        DeleteServiceCategoryVarT
    >(SERVICE_CATEGORY_DELETE);

    const deleteAction = async (serviceCategoryId: ServiceCategoryT['id']) => {
        try {
            const { data } = await deleteServiceCategory({
                variables: {
                    id: serviceCategoryId,
                },
            });

            return data;
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        loading: deleteLoading,
        deleteServiceCategory: deleteAction,
        error: errorDelete,
    };
};

export default useServiceCategoryDelete;
