import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    PRICE_TYPES_QUERY,
    PriceTypesResponseT,
} from 'shared/graphql/query/priceType/PriceTypeQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_PRICE_TYPES_RESULT } from 'types/PriceType';

import { filterData } from '../../../utils/filter/filterData';

import { PriceTypesDataDTO } from './DTO/PriceTypesDTO';
import { PriceTypesDataT } from './PriceTypesData';

const usePriceTypes = (): PriceTypesDataT => {
    const [fetch, { data: priceTypeData, loading }] = useLazyQuery<PriceTypesResponseT, QueryVarsT>(
        PRICE_TYPES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedPriceTypeData = decodeDTO(
        PriceTypesDataDTO,
        EMPTY_PRICE_TYPES_RESULT,
        (priceType) => priceType,
        {
            priceTypes: priceTypeData?.priceTypes,
            priceTypesCount: priceTypeData?.priceTypesCount,
        },
    );

    const fetchPriceTypes = async ({
        limit, offset, orderBy = {}, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedPriceTypeData.priceTypes,
        count: decodedPriceTypeData.priceTypesCount,
        fetchPriceTypes,
        loading,
    };
};

export default usePriceTypes;
