import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PriceMemberCardT } from 'types/PriceMemberCard';

export type PriceMemberCardResponseT = {
    priceMemberCard: PriceMemberCardT;
};

export type PriceMemberCardsResponseT = {
    priceMemberCards: PriceMemberCardT[];
    priceMemberCardsCount: AggregateT;
};

export const PRICE_MEMBER_CARDS_QUERY = gql`
    query PriceMemberCardsQuery(
        $limit: Int,
        $offset: Int,
        $orderBy: member_card_type_order_by! = {},
        $where: member_card_type_bool_exp! = {}
        $priceSeasonId: String!,
        $priceTypeId: String!,
    ) {
        priceMemberCards: member_card_type(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            title
            member_card_type_discount_rates(where: {price_sales_round: {price_type_id: {_eq: $priceTypeId}}, price_season_id: {_eq: $priceSeasonId}}) {
                id
                price_sales_round {
                    position
                    title
                }
                price_sales_round_id
                discount_percent_fiat
                discount_percent_bonus
                multiplying_factor
                discount_percent_bonus_per_free_slot
                cashback_percent_bonus
                cashback_percent_fiat
            }
            member_card_type_price_season_prices(where: {price_season_id: {_eq: $priceSeasonId}}) {
                price
            }
            member_card_type_discount_full_returns(where: {price_season_id: {_eq: $priceSeasonId}}) {
                id
                full_return_quantity_limit
                enabled_at_months
                member_card_type_discount_full_return_price_sales_rounds {
                    price_sales_round {
                        id
                        title
                    }
                }
            }
        }
        priceMemberCardsCount: member_card_type_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const PRICE_MEMBER_CARD_QUERY = gql`
    query PriceMemberCardQuery(
        $priceSeasonId: String!,
        $priceTypeId: String!,
        $id: String!,
    ) {
        priceMemberCard: member_card_type_by_pk(id: $id) {
            id
            title
            member_card_type_discount_rates( order_by: {price_sales_round: {position: asc}}, where: {price_sales_round: {price_type_id: {_eq: $priceTypeId}}, price_season_id: {_eq: $priceSeasonId}}) {
                id
                price_sales_round {
                    id
                    position
                    title
                }
                price_sales_round_id
                discount_percent_fiat
                discount_percent_bonus
                multiplying_factor
                discount_percent_bonus_per_free_slot
                cashback_percent_bonus
                cashback_percent_fiat
            }
            member_card_type_price_season_prices(where: {price_season_id: {_eq: $priceSeasonId}}) {
                price
            }
            member_card_type_discount_full_returns(
                where: {
                    price_season_id: { _eq: $priceSeasonId },
                    member_card_type_id: { _eq: $id }
                }
            ) {
                id
                full_return_quantity_limit
                enabled_at_months
                member_card_type_id
                price_season_id
                member_card_type_discount_full_return_price_sales_rounds {
                    price_sales_round {
                        id
                        title
                    }
                }
            }
        }
    }
`;
