import { useMutation } from '@apollo/client';
import { DeletePriceResponseData, DeletePriceVarT, PRICE_DELETE } from 'shared/graphql/mutation/price/PriceDelete';
import { PriceT } from 'types/Price';

import { PriceDeleteDataT } from './PriceDeleteData';

const usePriceDelete = (): PriceDeleteDataT => {
    const [PriceDelete, { loading: deleteLoading, error: errorDelete }] = useMutation<
        DeletePriceResponseData,
        DeletePriceVarT
    >(PRICE_DELETE);

    const deleteAction = async (priceId: PriceT['id']) => {
        try {
            const { data } = await PriceDelete({
                variables: {
                    id: priceId,
                },
            });

            return data;
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        loading: deleteLoading,
        deletePrice: deleteAction,
        error: errorDelete,
    };
};

export default usePriceDelete;
