import dayjs, { Dayjs } from 'dayjs';

export const dateModification = (
    date: Dayjs | null,
    month: number,
    monthFromStart: boolean,
    week: number,
    weekFromStart: boolean,
    day: number,
    dayFromStart: boolean,
    hour: number,
    hourFromStart: boolean,
) => {
    let newDate = date;

    if (month) {
        newDate = dayjs(newDate).subtract(-month, 'month');
    }

    if (monthFromStart) {
        newDate = dayjs(newDate).startOf('month').hour(dayjs(date).hour()).minute(dayjs(date).minute());
    }

    if (week) {
        newDate = dayjs(newDate).subtract(-week, 'week');
    }

    if (weekFromStart) {
        newDate = dayjs(newDate).locale('ru').startOf('week').hour(dayjs(date).hour())
            .minute(dayjs(date).minute());
    }

    if (day) {
        newDate = dayjs(newDate).subtract(-day, 'day');
    }

    if (dayFromStart) {
        newDate = dayjs(newDate).startOf('day');
    }

    if (hour) {
        newDate = dayjs(newDate).subtract(-hour, 'hour');
    }

    if (hourFromStart) {
        newDate = dayjs(newDate).startOf('hour');
    }

    return dayjs(newDate);
};
