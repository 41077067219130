import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import useDeleteMultiplePriceType from 'shared/hook/priceTypes/delete/useDeleteMultiplePriceType';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { PRICE_TYPE_HEADER_DATA } from '../domain/HeaderData';

import { PRICE_SALES_ROUND_HEADER_DATA } from './PriceSalesRound/domain/HeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const PriceTypeList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;
    const { priceTypes, count } = useStore('priceTypes');
    const { deleteMultiplePriceType } = useDeleteMultiplePriceType();

    return (
        <DataGrid
            rows={ priceTypes }
            rowCount={ count }
            columns={ PRICE_TYPE_HEADER_DATA }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
            detailPanelColumns={ PRICE_SALES_ROUND_HEADER_DATA }
            multipleDeleteAction={ deleteMultiplePriceType }
        />
    );
});

export default PriceTypeList;
