import React, { FC } from 'react';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
    Badge, IconButton, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';

import useMenu from '../hooks/useMenu';

import HeaderMenu from './HeaderMenu';

type Notification = {
    id: number;
    title: string;
};

const notifications: Notification[] = [
    { id: 1, title: 'Уведомление 1' },
    { id: 2, title: 'Уведомление 2' },
    { id: 3, title: 'Уведомление 3' },
    { id: 4, title: 'Уведомление 4' },
];

const NotificationMenu: FC = () => {
    const { anchorEl, handleOpen, handleClose } = useMenu();

    return (
        <>
            <IconButton onClick={ handleOpen }>
                <Badge badgeContent={ notifications.length } color="error">
                    <NotificationsIcon color="inherit" />
                </Badge>
            </IconButton>
            <HeaderMenu id="notification-app-bar" anchor={ anchorEl } handleClose={ handleClose }>
                <List>
                    { notifications.map((notification) => (
                        <ListItem key={ notification.id } button>
                            <ListItemIcon>
                                <MailIcon />
                            </ListItemIcon>
                            <ListItemText primary={ notification.title } />
                        </ListItem>
                    )) }
                </List>
            </HeaderMenu>
        </>
    );
};

export default NotificationMenu;
