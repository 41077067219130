import { useMutation } from '@apollo/client';
import {
    EMPTY_PRICE_MULTIPLE_CREATE, PRICE_MULTIPLE_CREATE,
    PriceFormFieldsPayloadT, PriceMultipleCreateResponseT,
    PriceMultipleCreateT,
    PriceMultipleCreateVarT,
} from 'shared/graphql/mutation/price/PriceMultipleCreate';
import { useStore } from 'store';

import { PriceMultipleCreateDTO } from './DTO/PriceMultipleCreateDTO';
import { PriceCreateMultipleDataT } from './PriceMultipleCreateData';

const usePriceMultipleCreate = (): PriceCreateMultipleDataT => {
    const [create, { loading, error }] = useMutation<PriceMultipleCreateT, PriceMultipleCreateVarT>(
        PRICE_MULTIPLE_CREATE,
    );
    const { serviceApp } = useStore('serviceApp');

    const priceMultipleCreate = async (input: PriceFormFieldsPayloadT) => {
        try {
            const { data } = await create({
                variables: {
                    appId: serviceApp.id,
                    payload: PriceMultipleCreateDTO.encode(input),
                },
            });

            return data?.priceMultipleCreate as PriceMultipleCreateResponseT;
        } catch {
            return EMPTY_PRICE_MULTIPLE_CREATE;
        }
    };

    return {
        priceMultipleCreate,
        loading,
        error,
    };
};

export default usePriceMultipleCreate;
