import { ERROR_PAYMENT_TYPE_DELETE, SUCCESS_PAYMENT_TYPE_DELETE } from 'constants/notificationMessage';

import usePaymentTypeDelete from 'shared/data-hook/paymentType/delete/single/usePaymentTypeDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { PaymentTypeT } from 'types/PaymentType';

const useDeletePaymentType = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deletePaymentType } = usePaymentTypeDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const paymentTypeDelete = async (paymentTypeId: PaymentTypeT['id']) => {
        const response = await deletePaymentType(paymentTypeId);

        if (error || !response || !response.deletePaymentType) {
            showError(ERROR_PAYMENT_TYPE_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PAYMENT_TYPE_DELETE);
        }
    };

    return {
        paymentTypeDelete,
    };
};

export default useDeletePaymentType;
