import { GridColumnGroup } from '@mui/x-data-grid-premium';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

export const setGroupHeaderData = (groupHeaderData: GridColumnGroup[], priceSalesRound: PriceSalesRoundT, index: number) => {
    groupHeaderData.push({
        groupId: priceSalesRound.id,
        headerName: priceSalesRound.title,
        headerAlign: 'center',
        headerClassName: `data-grid-section data-grid-section-${index % 2 === 0 ? 'even' : 'odd'}`,
        children: [
            {
                field: `saleRate-${index}`,
            },
            {
                field: `minRate-${index}`,
            },
            {
                field: `transferCost-${index}`,
            },
            {
                field: `performerSaleIncomeValue-${index}`,
            },
            {
                field: `shareOfPaymentFiat-${index}`,
            },
            {
                field: `shareOfPaymentBonus-${index}`,
            },
            {
                field: `transferable-${index}`,
            },
            {
                field: `transferDisabledBeforeHours-${index}`,
            },
            {
                field: `cancellable-${index}`,
            },
            {
                field: `returnRate-${index}`,
            },
            {
                field: `performerReturnIncomeValue-${index}`,
            },
        ],
    });
};
