import { PROPERTY_IS_ACTIVE, PROPERTY_STATUS, PROPERTY_TITLE } from 'constants/propertyNames';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchPaymentMethod from 'shared/hook/paymentMethod/fetch/fetchOne/useFetchPaymentMethod';
import useUpdatePaymentMethod from 'shared/hook/paymentMethod/update/useUpdatePaymentMethod';
import { useStore } from 'store';

import {
    PAYMENT_METHOD_DEFAULT_VALUES, PAYMENT_METHOD_FIELD_KEYS,
    PAYMENT_METHOD_VALIDATION_SCHEMA,
    PaymentMethodFormFields,
} from './formData';

const UpdatePaymentMethodSettingModal: FC = observer(() => {
    const {
        isOpen, updatedItemId, onClose, paymentMethod,
    } = useStore('updatePaymentMethod');
    const { fetch: fetchPaymentMethod, loading: loadingFetch } = useFetchPaymentMethod();
    const { updatePaymentMethod, loading: loadingSave } = useUpdatePaymentMethod();
    const {
        control, handleSubmit, setValue, reset, formState: { isDirty, isValid },
    } = useForm<PaymentMethodFormFields>({
        defaultValues: PAYMENT_METHOD_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(PAYMENT_METHOD_VALIDATION_SCHEMA),
    });

    fetchPaymentMethod();

    const handleSubmitForm = (data: PaymentMethodFormFields) => {
        updatePaymentMethod(data, updatedItemId).then(() => {
            onClose();
            reset();
        });
    };

    useEffect(() => {
        if (paymentMethod) {
            Object.keys(paymentMethod).forEach((key: string) => {
                setValue(key as keyof PaymentMethodFormFields, paymentMethod[key as keyof PaymentMethodFormFields]);
            });
        }
    }, [paymentMethod]);

    return (
        <Modal open={ isOpen } maxWidth="sm">
            <ModalTitle onClose={ onClose }>{ loadingFetch ? <Skeleton height={ 32 } /> : (<>Редактирование &quot;{ paymentMethod?.title }&quot;</>) }</ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch ? <Spinner /> : (
                    <Grid2 spacing={ 2 } container padding={ 2 }>
                        <Grid2 xs={ 12 }>
                            <TextField
                                name={ PAYMENT_METHOD_FIELD_KEYS.title }
                                key={ `key-payment-method-setting-${paymentMethod?.title}` }
                                control={ control }
                                label={ PROPERTY_TITLE }
                                type="text"
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <TextField
                                name={ PAYMENT_METHOD_FIELD_KEYS.position }
                                key={ `key-payment-method-setting-${paymentMethod?.position}` }
                                control={ control }
                                label={ PROPERTY_IS_ACTIVE }
                                type="number"
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <Switch
                                name={ PAYMENT_METHOD_FIELD_KEYS.isActive }
                                key={ `key-payment-method-setting-${paymentMethod?.isActive}` }
                                control={ control }
                                label={ PROPERTY_STATUS }
                                type="boolean"
                            />
                        </Grid2>
                    </Grid2>
                ) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ onClose }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    onClick={ handleSubmit(handleSubmitForm) }
                    loading={ loadingSave }
                    disabled={ !isDirty || !isValid }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdatePaymentMethodSettingModal;
