import { useLazyQuery } from '@apollo/client';
import { PriceDTO } from 'shared/data-hook/price/fetch/DTO/PriceDTO';
import { PriceDataT } from 'shared/data-hook/price/fetch/fetchOne/PriceData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { PRICE_QUERY, PriceResponseT } from 'shared/graphql/query/price/PriceQuery';
import { EMPTY_PRICE_RESULT, PriceT } from 'types/Price';

const usePrice = (): PriceDataT => {
    const [fetch, { loading, error }] = useLazyQuery<PriceResponseT>(PRICE_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchPrice = async (id: string): Promise<PriceT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(PriceDTO, EMPTY_PRICE_RESULT, (price) => price, data?.price);
        } catch {
            return EMPTY_PRICE_RESULT;
        }
    };

    return {
        fetchPrice,
        loading,
        error,
    };
};

export default usePrice;
