import { gql } from '@apollo/client';
import { PriceSeasonT } from 'types/PriceSeason';

export type DeleteMultiplePriceSeasonResponseT = {
    deleteMultiplePriceSeason: DeleteMultiplePriceSeasonResponseDataT;
};

export type DeleteMultiplePriceSeasonResponseDataT = {
    affectedRows: number;
}

export type DeleteMultiplePriceSeasonVarT = {
    ids: [PriceSeasonT['id']];
};

export const DELETE_MULTIPLE_PRICE_SEASON = gql`
    mutation DeleteMultiplePriceSeason($ids: [String!] = []) {
        deleteMultiplePriceSeason: delete_price_season(where: {id: {_in: $ids}}) {
            affected_rows
        }
    }
`;
