import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { InputLabel } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';

import {
    PRICE_SALES_ROUND_DEFAULT_VALUES,
    PRICE_SALES_ROUND_LABEL_DATA,
    PriceSalesRoundFormFields,
} from './formData';

type PropsT = {
    control: Control<PriceSalesRoundFormFields>;
    loading?: boolean;
    item: {
        field: string;
        switcher: string;
    };
};

const PriceSalesRoundSettingsLine: FC<PropsT> = (props) => {
    const { control, loading, item } = props;

    return (
        <Grid2 container columnSpacing={ 2 } direction="row" alignItems="center" key={ item.field } xs={ 12 } sx={ { marginBottom: '12px' } }>
            <Grid2 xs={ 4.5 }>
                <InputLabel>
                    { PRICE_SALES_ROUND_LABEL_DATA[item.field as keyof PriceSalesRoundFormFields] }
                </InputLabel>
            </Grid2>
            <Grid2 xs={ 3 }>
                <TextField
                    size="small"
                    loading={ loading }
                    key={ item.field }
                    name={ item.field as keyof PriceSalesRoundFormFields }
                    type={ typeof PRICE_SALES_ROUND_DEFAULT_VALUES[item.field as keyof PriceSalesRoundFormFields] }
                    control={ control }
                    withoutError={ true }
                />
            </Grid2>
            <Grid2 xs={ 4.5 }>
                <Switch
                    size="small"
                    loading={ loading }
                    key={ item.switcher }
                    name={ item.switcher as keyof PriceSalesRoundFormFields }
                    type={ typeof PRICE_SALES_ROUND_DEFAULT_VALUES[item.switcher as keyof PriceSalesRoundFormFields] }
                    control={ control }
                    label={ PRICE_SALES_ROUND_LABEL_DATA[item.switcher as keyof PriceSalesRoundFormFields] }
                />
            </Grid2>
        </Grid2>
    );
};

export default PriceSalesRoundSettingsLine;
