import {
    ERROR_COACH_CATEGORY_DELETE,
    SUCCESS_COACH_CATEGORY_DELETE,
} from 'constants/notificationMessage';

import useCoachCategoryDelete from 'shared/data-hook/coachCategory/delete/useCoachCategoryDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CoachCategoryT } from 'types/CoachCategory';

const useDeleteCoachCategory = () => {
    const { error, deleteCoachCategory } = useCoachCategoryDelete();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const coachCategoryDelete = async (coachCategoryId: CoachCategoryT['id']) => {
        const response = await deleteCoachCategory(coachCategoryId);

        if (error || !response) {
            showError(ERROR_COACH_CATEGORY_DELETE);
        } else {
            showSuccess(SUCCESS_COACH_CATEGORY_DELETE);
        }

        toggleRefetchTrigger();
    };

    return {
        coachCategoryDelete,
    };
};

export default useDeleteCoachCategory;
