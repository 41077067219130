import { gql } from '@apollo/client';
import { PaymentTypeT } from 'types/PaymentType';

export type PaymentTypeDeleteResponseDataT = {
    deletePaymentType: {
        id: string;
    };
} | null | undefined;

export type PaymentTypeDeleteVarT = {
    id: PaymentTypeT['id'];
};

export const PAYMENT_TYPE_DELETE = gql`
    mutation DeletePaymentType($id: String = "") {
        deletePaymentType: delete_payment_type_by_pk(id: $id) {
            id
        }
    }
`;
