export type MemberCardTypeDiscountFullReturnsT = {
    id: string;
    enabledAtMonths: string[];
    fullReturnQuantityLimit: number;
    memberCardTypeDiscountFullReturnPriceSalesRounds: Array<{
        priceSalesRound: {
            id: string;
            title: string;
        };
    }>;
}

export type MemberCardTypeDiscountRatesT = {
    id: string;
    priceSalesRound: {
        position: number;
        title: string;
    };
    priceSalesRoundId: string;
    discountPercentFiat: number;
    discountPercentBonus: number;
    multiplyingFactor: number;
    discountPercentBonusPerFreeSlot: number;
    cashbackPercentBonus: number;
    cashbackPercentFiat: number;
}

export type PriceMemberCardT = {
    id: string;
    title: string;
    price: number;
    memberCardTypeDiscountRates: MemberCardTypeDiscountRatesT[];
    memberCardTypeDiscountFullReturns: MemberCardTypeDiscountFullReturnsT[];
};

export type PriceMemberCardsT = {
    priceMemberCards: PriceMemberCardT[];
    priceMemberCardsCount: number;
};

export const EMPTY_PRICE_MEMBER_CARD_RESULT: PriceMemberCardT = {
    id: '',
    title: '',
    price: 0,
    memberCardTypeDiscountRates: [],
    memberCardTypeDiscountFullReturns: [],
};

export const EMPTY_PRICE_MEMBER_CARDS_RESULT: PriceMemberCardsT = {
    priceMemberCards: [],
    priceMemberCardsCount: 0,
};

export type ActivePriceSeasonT = {
    id: string;
    title: string;
};
