import {
    BONUS, FIAT, MULTIPLYING_FACTOR, PROMO,
} from 'constants/currency';

import { GridColumns, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

type HeaderDataT<T> = {
    params: any;
    property: T;
    id: string;
}

const renderCell = ({ params, property, id }: HeaderDataT<'discountPercentFiat' | 'cashbackPercentFiat' | 'discountPercentBonus' | 'cashbackPercentBonus' | 'discountPercentBonusPerFreeSlot' | 'multiplyingFactor' >): string | null => {
    const priceMemberCardDiscount = params.row.memberCardTypeDiscountRates.find((item: any) => item.priceSalesRoundId === id);

    return priceMemberCardDiscount ?
        `${priceMemberCardDiscount[property]} ${property === 'multiplyingFactor' ? '' : '%'}`
        : null;
};

export const setHeaderData = (headerData: GridColumns, priceSalesRound: PriceSalesRoundT) => {
    headerData.push(
        {
            field: `${priceSalesRound.id}-fiat-discount`,
            headerName: `${FIAT} discount %`,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'discountPercentFiat', id: priceSalesRound.id }),
        },
        {
            field: `${priceSalesRound.id}-fiat-cashback`,
            headerName: `${FIAT} cashback %`,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'cashbackPercentFiat', id: priceSalesRound.id }),
        },
        {
            field: `${priceSalesRound.id}-bonus-discount`,
            headerName: `${BONUS} discount %`,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'discountPercentBonus', id: priceSalesRound.id }),
        },
        {
            field: `${priceSalesRound.id}-bonus-cashback`,
            headerName: `${BONUS} cashback %`,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'cashbackPercentBonus', id: priceSalesRound.id }),
        },
        {
            field: `${priceSalesRound.id}-promo`,
            headerName: `${PROMO} % `,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'discountPercentBonusPerFreeSlot', id: priceSalesRound.id }),
        },
        {
            field: `${priceSalesRound.id}-multiply`,
            headerName: MULTIPLYING_FACTOR,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            valueGetter: (params: GridValueGetterParams) => renderCell({ params, property: 'multiplyingFactor', id: priceSalesRound.id }),
        },
    );
};
