import { ERROR_SCHEDULE_RECREATE } from 'constants/notificationMessage';

import { gql } from '@apollo/client';

export type ScheduleSlotRateRecreateForPricePayloadT = {
    price_season_id: string;
    price_id: string;
}

export type ScheduleSlotRateRecreateForPriceVarT = {
    appId: string;
    payload: ScheduleSlotRateRecreateForPricePayloadT[];
}

export type ScheduleSlotRateRecreateForPriceResponseT = {
    status: string;
    reason: string;
}

export type ScheduleSlotRateRecreateForPriceT = {
    adminScheduleSlotRateRecreateForPrice: ScheduleSlotRateRecreateForPriceResponseT;
}

export const EMPTY_SCHEDULE_SLOT_RATE_RECREATE_FOR_PRICE_RESPONSE: ScheduleSlotRateRecreateForPriceResponseT = {
    status: 'error',
    reason: ERROR_SCHEDULE_RECREATE,
};

export const SCHEDULE_SLOT_RATE_RECREATE_FOR_PRICE = gql`
    mutation adminBulkOperationRecreateScheduleForPrice($appId: String!, $payload: json!) {
        adminBulkOperationRecreateScheduleForPrice(
            app_id: $appId,
            payload: $payload
        ) {
            reason
            status
        }
    }
`;
