import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    PAYMENT_DOCUMENTS_QUERY,
    PaymentDocumentsResponseT,
} from 'shared/graphql/query/paymentDocument/PaymentDocumentQuery';
import { FetchDataParams } from 'types/DataGrid';
import { EMPTY_PAYMENT_DOCUMENTS } from 'types/PaymentDocument';

import { PaymentDocumentsDTO } from '../DTO/PaymentDocumentsDTO';

import { PaymentDocumentsDataT } from './PaymentDocumentsData';

const usePaymentDocuments = (): PaymentDocumentsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<PaymentDocumentsResponseT>(
        PAYMENT_DOCUMENTS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedPaymentDocumentsData = decodeDTO(
        PaymentDocumentsDTO,
        EMPTY_PAYMENT_DOCUMENTS,
        (paymentDocuments) => paymentDocuments,
        {
            paymentDocuments: data?.paymentDocuments,
            paymentDocumentsCount: data?.paymentDocumentsCount,
        },
    );

    const fetchPaymentDocuments = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        fetchPaymentDocuments,
        loading,
        data: decodedPaymentDocumentsData.paymentDocuments,
        count: decodedPaymentDocumentsData.paymentDocumentsCount,
    };
};

export default usePaymentDocuments;
