import { FC, MouseEventHandler } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from 'store';

import ServiceCategoryUpdateModal from './components/ServiceCategoryUpdateModal';

type PropsT = {
    id: string;
};

const ServiceCategoryUpdate: FC<PropsT> = observer((props) => {
    const {
        id,
    } = props;

    const { onOpen } = useStore('updateServiceCategoryModal');

    const handleCreateClick: MouseEventHandler<HTMLButtonElement> = () => {
        onOpen(id);
    };

    return (
        <>
            <IconButton onClick={ handleCreateClick }>
                <EditIcon color="primary" />
            </IconButton>
            <ServiceCategoryUpdateModal
                id={ id || 'id' }
            />
        </>
    );
});

export default ServiceCategoryUpdate;
