import { PAGE_TITLE_SERVICE_CATEGORY } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react';
import ServiceCategoryCreateModal from 'page/ServiceCategory/components/ServiceCategoryCreateModal';
import ServiceCategoryList from 'page/ServiceCategory/components/ServiceCategoryList';
import { SERVICE_CATEGORY_HEADER_DATA } from 'page/ServiceCategory/domain/HeaderData';
import ControlPanel from 'shared/component/ControlPanel';
import useServiceCategories from 'shared/data-hook/serviceCategory/fetch/fetchAll/useServiceCategories';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const { onOpen } = useStore('createServiceCategoryModal');
    const {
        data: serviceCategoriesData, loading: serviceCategoriesLoading, count: rowCount, fetchServiceCategories,
    } = useServiceCategories();
    const { setServiceCategories } = useStore('serviceCategory');
    const { refetchTrigger } = useStore('refetchTrigger');

    usePageTitle(PAGE_TITLE_SERVICE_CATEGORY);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchServiceCategories, refetchTrigger, SERVICE_CATEGORY_HEADER_DATA);

    useEffect(() => {
        setServiceCategories(serviceCategoriesData, rowCount);
    }, [serviceCategoriesData, serviceCategoriesLoading]);

    const openCreateServiceCategory = () => {
        onOpen();
    };

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button variant="outlined" size="small" onClick={ openCreateServiceCategory } startIcon={ <AddIcon /> }>добавить</Button>
            </ControlPanel>
            <ServiceCategoryList
                loading={ serviceCategoriesLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <ServiceCategoryCreateModal />
        </StyledPageWrap>
    );
});

export default List;
