import { useEffect } from 'react';
import usePriceType from 'shared/data-hook/priceType/fetch/fetchOne/usePriceType';
import { useStore } from 'store';

const useFetchPriceType = () => {
    const { fetchPriceType, loading } = usePriceType();
    const { setPriceType } = useStore('priceTypes');

    const fetch = (id: string) => {
        useEffect(() => {
            fetchPriceType(id).then((priceType) => {
                setPriceType(priceType);
            });
        }, []);
    };

    return {
        fetch,
        loading,
    };
};

export default useFetchPriceType;
