import { MODAL_TITLE_CREATE_PRICE } from 'constants/modalTitles';

import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Box, Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCoachCategories from 'shared/data-hook/coachCategory/fetch/fetchAll/useCoachCategories';
import useGroupViews from 'shared/data-hook/groupView/fetch/fetchAll/useGroupViews';
import useCheckCreatePrice from 'shared/hook/price/createMultiple/check/useCheckCreatePrice';
import useCreatePrice from 'shared/hook/price/createMultiple/create/useCreatePrice';
import { useStore } from 'store';

import {
    getPricesValidationSchema,
    PRICES_DEFAULT_VALUES,
    PricesFormFields,
} from './formData';
import PriceForm from './PriceForm';

type PropsT = {
    priceTypeId: string;
    priceSeasonId: string;
};

const CreatePriceModal: FC<PropsT> = observer((props) => {
    const { priceTypeId, priceSeasonId } = props;
    const [openCheckModal, setOpenCheckModal] = useState<boolean>(false);
    const [newPricesCount, setNewPricesCount] = useState<number>(0);
    const {
        isOpen, onClose, setGroupViews, setCoachCategories, setPriceTypeId, setPriceSeasonId,
    } = useStore('createPriceModal');
    const { activePriceType } = useStore('price');

    const { fetchCoachCategories, data: coachCategoriesData, loading: coachCategoriesLoading } = useCoachCategories();
    const { fetchGroupViews, data: groupViewsData, loading: groupViewsLoading } = useGroupViews();
    const { checkCreateMultiplePrice } = useCheckCreatePrice();
    const { createPrice, loading } = useCreatePrice();
    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<PricesFormFields>({
        defaultValues: PRICES_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getPricesValidationSchema(activePriceType)),
    });

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        fetchCoachCategories().then();
        fetchGroupViews().then();
        setPriceTypeId(priceTypeId);
        setPriceSeasonId(priceSeasonId);
    }, [isOpen]);

    useEffect(() => {
        setCoachCategories(coachCategoriesData);
    }, [coachCategoriesData, coachCategoriesLoading]);

    useEffect(() => {
        setGroupViews(groupViewsData);
    }, [groupViewsData, groupViewsLoading]);

    const handleOpenCheckModal = () => {
        setOpenCheckModal(true);
    };

    const handleCloseCheckModal = () => {
        setOpenCheckModal(false);
    };

    const handleCloseModal = () => {
        onClose();
    };

    const handleCreatePrice = (data: PricesFormFields) => {
        createPrice(data).then(() => {
            handleCloseCheckModal();
            handleCloseModal();
        });
    };

    const handleConfirmCreatePrice = (data: PricesFormFields) => {
        checkCreateMultiplePrice(data).then((response: number) => {
            setNewPricesCount(response);
            handleOpenCheckModal();
        });
    };

    useEffect(() => {
        reset();
    }, [activePriceType?.id]);

    return (
        <>
            <Modal open={ isOpen } maxWidth="md">
                <ModalTitle onClose={ onClose }>
                    { MODAL_TITLE_CREATE_PRICE }
                </ModalTitle>
                <DialogContent dividers={ true }>
                    <PriceForm
                        control={ control }
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                    <Button variant="outlined" onClick={ onClose }>Отмена</Button>
                    <LoadingButton
                        variant="contained"
                        onClick={ handleSubmit(handleConfirmCreatePrice) }
                        autoFocus
                        disabled={ !isValid || !isDirty }
                        loading={ loading }
                    >
                        Создать
                    </LoadingButton>
                </DialogActions>
            </Modal>
            <Modal open={ openCheckModal } onClose={ handleCloseCheckModal } fullWidth={ true } maxWidth="sm">
                <ModalTitle onClose={ onClose }>
                    Заполнение режимов работы кортов для прайса
                </ModalTitle>
                <DialogContent dividers={ true }>
                    По выбранным параметрам будет создано новых строк: <Box sx={ { fontWeight: 'bold', display: 'initial' } }>{ newPricesCount }</Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={ handleCloseCheckModal }>Отмена</Button>
                    <LoadingButton
                        variant="contained"
                        onClick={ handleSubmit(handleCreatePrice) }
                        autoFocus
                        loading={ loading }
                    >
                        Создать
                    </LoadingButton>
                </DialogActions>
            </Modal>
        </>
    );
});

export default CreatePriceModal;
