import { ERROR_PRICE_DELETE, SUCCESS_PRICE_DELETE } from 'constants/notificationMessage';

import usePriceDelete from 'shared/data-hook/price/delete/single/usePriceDelete';
import { useStore } from 'store';
import { PriceT } from 'types/Price';

import { useNotification } from '../../useNotification';

const useDeletePrice = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deletePrice } = usePriceDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const priceDelete = async (priceId: PriceT['id']) => {
        const response = await deletePrice(priceId);

        if (error || !response) {
            showError(ERROR_PRICE_DELETE);

            return;
        }

        toggleRefetchTrigger();
        showSuccess(SUCCESS_PRICE_DELETE);
    };

    return {
        priceDelete,
    };
};

export default useDeletePrice;
