import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import Spinner from 'shared/component/Spinner/Spinner';
import useFetchPriceSalesRound from 'shared/hook/priceSalesRound/fetch/fetchOne/useFetchPriceSalesRound';
import useUpdatePriceSalesRound from 'shared/hook/priceSalesRound/update/useUpdatePriceSalesRound';
import { useStore } from 'store';

import {
    PRICE_SALES_ROUND_DEFAULT_VALUES, PRICE_SALES_ROUND_VALIDATION_SCHEMA, PriceSalesRoundFormFields,
} from './formData';
import PriceSalesRoundForm from './PriceSalesRoundForm';
import RangeDateErrorMessage from './RangeDateErrorMessage';

const PriceSalesRoundUpdateModal: FC = observer(() => {
    const {
        priceSalesRoundId, onClose, isOpen,
    } = useStore('updatePriceSalesRound');

    const { priceSalesRound } = useStore('priceSalesRound');
    const { fetch: fetchPriceSalesRound, loading: loadingFetch } = useFetchPriceSalesRound();
    const { updatePriceSalesRound, loading: loadingSave } = useUpdatePriceSalesRound();
    const [dateError, setDateError] = useState<boolean>(false);

    fetchPriceSalesRound();

    const {
        control, handleSubmit, reset, setValue, formState: { isDirty, isValid },
    } = useForm<PriceSalesRoundFormFields>({
        defaultValues: PRICE_SALES_ROUND_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(PRICE_SALES_ROUND_VALIDATION_SCHEMA),
    });

    const handleUpdatePriceSalesRound = (data: PriceSalesRoundFormFields) => {
        if (dateError) {
            return;
        }
        updatePriceSalesRound(data, priceSalesRoundId, priceSalesRound?.priceTypeId as string).then(() => {
            onClose();
            reset();
        });
    };

    useEffect(() => {
        Object.keys(PRICE_SALES_ROUND_DEFAULT_VALUES).forEach((key) => {
            if (priceSalesRound) {
                setValue(
                    key as keyof PriceSalesRoundFormFields,
                    priceSalesRound[key as keyof PriceSalesRoundFormFields] as never,
                );
            }
        });
    }, [priceSalesRound]);

    return (
        <Modal className="fullHeightModal modalWithTabs" open={ isOpen } fullWidth={ true } maxWidth="lg">
            { loadingFetch ? (
                <Spinner />
            ) : (
                <>
                    <ModalTitle onClose={ onClose }> Редактирование { priceSalesRound?.title }</ModalTitle>
                    <DialogContent dividers={ true }>
                        <PriceSalesRoundForm
                            control={ control }
                            loading={ loadingFetch }
                            setDateError={ setDateError }
                        />
                    </DialogContent>
                    <DialogActions>
                        <RangeDateErrorMessage error={ dateError } />
                        <Button onClick={ onClose }>Отмена</Button>
                        <LoadingButton
                            color="success"
                            variant="contained"
                            autoFocus
                            onClick={ handleSubmit(handleUpdatePriceSalesRound) }
                            loading={ loadingSave }
                            disabled={ !isDirty || !isValid || dateError }
                        >
                            Сохранить
                        </LoadingButton>
                    </DialogActions>
                </>
            ) }
        </Modal>
    );
});

export default PriceSalesRoundUpdateModal;
