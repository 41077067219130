import { gql } from '@apollo/client';

export type UpdatePriceVarT = {
    id: string;
    set: UpdatePriceMutationPayloadT;
};

export type UpdatePriceResponseT = {
    status: string;
    reason: null | string;
}

type UpdatePriceMutationPayloadT = {
    day: number;
    time_start: string;
    time_end: string;
    category_id: string;
    court_type_id: string;
}

export type UpdatePriceResponseDataT = {
    updatePrice: {
        id: string;
    };
} | null | undefined;

export const UPDATE_PRICE = gql`
    mutation UpdatePrice($set: price_set_input = {}, $id: String = "") {
        updatePrice: update_price_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
