import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceFormFieldsPayloadT } from 'shared/graphql/mutation/price/PriceMultipleCreate';
import { preparePriceMultipleCreateData } from 'shared/utils/preparePriceMultipleCreateData';

import Nullable from '../../../../utils/DTO/Nullable';

const DTO = t.array(t.type({
    category_id: t.string,
    court_type_id: t.string,
    price_type_id: t.string,
    price_season_id: t.string,
    day: t.number,
    time_start: t.string,
    time_end: t.string,
    coach_category_id: Nullable(t.string),
    group_view_id: Nullable(t.string),
}));

export type PriceMultipleCreateDTOT = t.TypeOf<typeof DTO>;

export const PriceMultipleCreateDTO = tPromise.extendType<PriceMultipleCreateDTOT, PriceFormFieldsPayloadT>(
    DTO,
    () => ({
        priceTypeId: '',
        priceSeasonId: '',
        days: [],
        time: [],
        categoryIds: [],
        courtTypeIds: [],
        coachCategoryIds: [],
        groupViewIds: [],
    }),
    ({
        time,
        days,
        priceSeasonId,
        priceTypeId,
        courtTypeIds,
        categoryIds,
        coachCategoryIds,
        groupViewIds,
    }) => {
        return preparePriceMultipleCreateData(
            {
                time,
                days,
                courtTypeIds,
                categoryIds,
                priceSeasonId,
                priceTypeId,
                coachCategoryIds,
                groupViewIds,
            },
        );
    },
) as t.Type<PriceFormFieldsPayloadT, PriceMultipleCreateDTOT>;
