import React, { FC, MouseEventHandler } from 'react';
import { Button, DialogActions, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal/index';
import { useStore } from 'store';

type PropsT = {
    id: string;
    title: string;
    onDuplicateButtonClick: () => void;
};

const DuplicateModal: FC<PropsT> = observer((props) => {
    const { id, title, onDuplicateButtonClick } = props;
    const { duplicatedItemId, onClose } = useStore('duplicateItemModal');

    const handleDeleteButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onDuplicateButtonClick();
        onClose();
    };

    return (
        <Modal open={ duplicatedItemId === id } onClose={ onClose }>
            <DialogTitle>Вы действительно хотите продублировать { title || 'выбранный элемент' }?</DialogTitle>
            <DialogActions>
                <Button variant="outlined" onClick={ onClose }>Отмена</Button>
                <Button onClick={ handleDeleteButtonClick } variant="contained" autoFocus>Дублировать</Button>
            </DialogActions>
        </Modal>
    );
});

export default DuplicateModal;
