import { EMPTY_CELL } from 'constants/dataGrid';

import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { PricesT } from 'types/Price';

const DTO = t.type({
    prices: t.array(
        t.type({
            id: t.string,
            day: t.number,
            time_start: t.string,
            time_end: t.string,
            court_type: t.type({
                name: t.string,
            }),
            service_category: t.type({
                name: t.string,
            }),
            coach_category: Nullable(t.type({
                name: t.string,
            })),
            group_view: Nullable(t.type({
                name: t.string,
            })),
            price_rates: t.array(t.type({
                price_sales_round_id: t.string,
                sale_rate: t.number,
                min_rate: t.number,
                forward_transfer_cost: t.number,
                back_transfer_cost: t.number,
                transferable: t.boolean,
                cancellable: t.boolean,
                return_rate: t.number,
                performer_return_income_rate: Nullable(t.number),
                performer_return_income_sum: Nullable(t.number),
                performer_sale_income_rate: Nullable(t.number),
                performer_sale_income_sum: Nullable(t.number),
                share_of_payment_fiat: Nullable(t.number),
                share_of_payment_bonus: Nullable(t.number),
                transfer_disabled_before_hours: t.number,
            })),
        }),
    ),
    pricesCount: AggregateDTO,
    currency: t.string,
});

export type PricesDTOT = t.TypeOf<typeof DTO>;

export const PricesDTO = tPromise.extendDecoder<PricesDTOT, PricesT>(
    DTO,
    ({
        prices,
        pricesCount,
        currency,
    }) => ({
        prices: prices.map((price) => {
            const {
                id,
                day,
                time_start, time_end,
                court_type: { name: courtName },
                service_category: { name: categoryName },
                coach_category,
                group_view,
                price_rates,
            } = price;

            const priceRates = price_rates.map((item) => ({
                priceSalesRoundId: item.price_sales_round_id,
                saleRate: item.sale_rate,
                minRate: item.min_rate,
                forwardTransferCost: item.forward_transfer_cost,
                backTransferCost: item.back_transfer_cost,
                transferable: item.transferable,
                cancellable: item.cancellable,
                returnRate: item.return_rate,
                performerReturnIncomeValue: (item.performer_return_income_rate || item.performer_return_income_sum) ?? EMPTY_CELL,
                performerSaleIncomeValue: (item.performer_sale_income_rate || item.performer_sale_income_sum) ?? EMPTY_CELL,
                shareOfPaymentFiat: (item.share_of_payment_fiat || item.share_of_payment_fiat) ?? EMPTY_CELL,
                shareOfPaymentBonus: (item.share_of_payment_bonus || item.share_of_payment_bonus) ?? EMPTY_CELL,
                performerReturnIncomeCurrency:
                    (item.performer_return_income_sum && currency)
                    || (item.performer_return_income_rate && '%') || '',
                performerSaleIncomeCurrency:
                    (item.performer_sale_income_sum && currency)
                    || (item.performer_sale_income_rate && '%') || '',
                transferDisabledBeforeHours: item.transfer_disabled_before_hours,
            }));

            const coachCategory = coach_category?.name ?? EMPTY_CELL;
            const groupView = group_view?.name ?? EMPTY_CELL;

            return {
                id,
                day,
                timeStart: time_start,
                timeEnd: time_end,
                courtType: courtName,
                category: categoryName,
                coachCategory,
                groupView,
                priceRates,
            };
        }),
        pricesCount,
    }),
) as t.Type<PricesT, PricesDTOT>;
