import { useMutation } from '@apollo/client';
import {
    DeletePriceTypeResponseDataT,
    DeletePriceTypeVarT,
    PRICE_TYPE_DELETE,
} from 'shared/graphql/mutation/priceType/PriceTypeDelete';
import { PriceTypeT } from 'types/PriceType';

import { PriceTypeDeleteDataT } from './PriceTypeDeleteData';

const usePriceTypeDelete = (): PriceTypeDeleteDataT => {
    const [PriceTypeDelete, { loading: deleteLoading, error: errorDelete }] = useMutation<
        DeletePriceTypeResponseDataT,
        DeletePriceTypeVarT
    >(PRICE_TYPE_DELETE);

    const deleteAction = async (priceTypeId: PriceTypeT['id']) => {
        try {
            const { data } = await PriceTypeDelete({
                variables: {
                    id: priceTypeId,
                },
            });

            return data;
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        loading: deleteLoading,
        deletePriceType: deleteAction,
        error: errorDelete,
    };
};

export default usePriceTypeDelete;
