import { ClientFullNameT } from './Client';

export type ScheduleSlotReserveT = {
    id: string;
    implementationDocumentNumber: string;
    priceSeasonTitle: string;
    datetimeStart: Date;
    datetimeEnd: Date;
    type: string | null;
    serviceCategory: string | null;
    group: string | null;
    client: ClientFullNameT | null;
    coachesIds: string[];
};

export type ScheduleSlotReservesT = {
    scheduleSlotReserves: ScheduleSlotReserveT[];
    scheduleSlotReservesCount: number;
};

export const EMPTY_SCHEDULE_SLOT_RESERVES_RESULT: ScheduleSlotReservesT = {
    scheduleSlotReserves: [],
    scheduleSlotReservesCount: 0,
};
