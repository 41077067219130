import { ERROR_COACH_CATEGORY_UPDATE, SUCCESS_COACH_CATEGORY_UPDATE } from 'constants/notificationMessage';

import { CoachCategoryFormFields } from 'page/CoachCategory/components/formData';
import useCoachCategoryUpdate from 'shared/data-hook/coachCategory/update/useCoachCategoryUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CoachCategoryT } from 'types/CoachCategory';

const useUpdateCoachCategory = () => {
    const { error, loading, coachCategoryUpdate } = useCoachCategoryUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updateCoachCategory = async (input: CoachCategoryFormFields, coachCategoryId: CoachCategoryT['id']) => {
        const response = await coachCategoryUpdate(input, coachCategoryId);

        if (error || !response || !response.updateCoachCategory) {
            showError(ERROR_COACH_CATEGORY_UPDATE);
        } else {
            showSuccess(SUCCESS_COACH_CATEGORY_UPDATE);
        }

        toggleRefetchTrigger();
    };

    return {
        updateCoachCategory,
        loading,
    };
};

export default useUpdateCoachCategory;
