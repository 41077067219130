import { FC } from 'react';
import {
    AppBar, AppBarProps, Menu, styled,
} from '@mui/material';
import { withTheme } from '@mui/styles';

export const HeaderWrap: FC<AppBarProps> = styled(withTheme(AppBar))((props) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'initial',
    padding: 0,
    zIndex: 10,
    position: 'fixed',
    width: `calc(100% - ${props.theme.sidebarWidth}px)`,
    marginLeft: `${props.theme.sidebarWidth}px`,
    boxShadow: 'none',
    background: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, .12)',
}));

export const HeaderMenu = styled(Menu)({
    marginTop: '45px',
});
