import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';

import List from './List';

const Price: FC = observer(() => {
    return (
        <Routes>
            <Route path="/" element={ <List /> } />
        </Routes>
    );
});

export default Price;
