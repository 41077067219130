import {
    ERROR_PRICE_RATE_UPDATE,
    SUCCESS_EMPTY_PRICE_RATE_UPDATE,
} from 'constants/notificationMessage';

import { UpdatePriceRateFormFields } from 'page/Price/components/UpdatePriceRate/formData';
import usePriceUpdateRate from 'shared/data-hook/price/updatePriceRate/update/usePriceUpdateRate';
import { useStore } from 'store';

import { useNotification } from '../../../useNotification';

const useUpdatePriceRate = () => {
    const { error, loading, priceUpdateRate } = usePriceUpdateRate();
    const { showError, showSuccess, showWarning } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updatePriceRate = async (input: UpdatePriceRateFormFields) => {
        const { status, pricesCount } = await priceUpdateRate(input);

        if (error || status === 'error') {
            showError(ERROR_PRICE_RATE_UPDATE);

            return;
        }

        if (pricesCount > 0) {
            showSuccess(`Прайсы успешно заполнены! Изменения применены к ${pricesCount} строкам`);
            toggleRefetchTrigger();

            return;
        }

        showWarning(SUCCESS_EMPTY_PRICE_RATE_UPDATE);
    };

    return {
        updatePriceRate,
        loading,
    };
};

export default useUpdatePriceRate;
