import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { ScheduleSlotReservesT } from 'types/ScheduleSlotReserve';

import { ScheduleSlotReserveDTO } from './ScheduleSlotReserveDTO';

const DTO = t.type({
    scheduleSlotReserves: t.array(ScheduleSlotReserveDTO),
    scheduleSlotReservesCount: AggregateDTO,
});

type ScheduleSlotReservesDTOT = t.TypeOf<typeof DTO>;

export const ScheduleSlotReservesDTO = tPromise.extendDecoder<ScheduleSlotReservesDTOT, ScheduleSlotReservesT>(
    DTO,
    ({
        scheduleSlotReserves,
        scheduleSlotReservesCount,
    }) => ({
        scheduleSlotReserves,
        scheduleSlotReservesCount,
    }),
) as t.Type<ScheduleSlotReservesT, ScheduleSlotReservesDTOT>;
