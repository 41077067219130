import {
    ERROR_COACH_CREATE,
    SUCCESS_COACH_CREATE,
} from 'constants/notificationMessage';

import { CoachFormFields } from 'page/Coach/components/formData';
import useCoachCreate from 'shared/data-hook/coach/create/useCoachCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateCoach = () => {
    const { showError, showSuccess } = useNotification();
    const { loading, error, coachCreate } = useCoachCreate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createCoach = async (input: CoachFormFields) => {
        const response = await coachCreate(input);

        if (error || !response || !response.createCoach) {
            showError(ERROR_COACH_CREATE);
        } else {
            showSuccess(SUCCESS_COACH_CREATE);
        }

        toggleRefetchTrigger();
    };

    return {
        createCoach,
        loading,
    };
};

export default useCreateCoach;
