import { gql } from '@apollo/client';
import { PriceTypeT } from 'types/PriceType';

export type DeleteMultiplePriceTypeResponseT = {
    deleteMultiplePriceType: DeleteMultiplePriceTypeResponseDataT;
};

export type DeleteMultiplePriceTypeResponseDataT = {
    affectedRows: number;
}

export type DeleteMultiplePriceTypeVarT = {
    ids: [PriceTypeT['id']];
};

export const DELETE_MULTIPLE_PRICE_TYPE = gql`
    mutation DeleteMultiplePriceType($ids: [String!] = []) {
        deleteMultiplePriceType: delete_price_type(where: {id: {_in: $ids}}) {
            affected_rows
        }
    }
`;
