import {
    ERROR_PRICE_SEASON_UPDATE,
    SUCCESS_PRICE_SEASON_UPDATE,
} from 'constants/notificationMessage';

import { SeasonFormFields } from 'page/PriceSeason/components/formData';
import usePriceSeasonUpdate from 'shared/data-hook/priceSeason/update/usePriceSeasonUpdate';
import { useStore } from 'store';
import { PriceSeasonT } from 'types/PriceSeason';

import { useNotification } from '../../useNotification';

const useUpdatePriceSeason = () => {
    const { error, loading, priceSeasonUpdate } = usePriceSeasonUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updatePriceSeason = async (
        input: SeasonFormFields,
        priceSeasonId: PriceSeasonT['id'],
    ) => {
        const response = await priceSeasonUpdate(input, priceSeasonId);

        if (error || !response || !response.updatePriceSeason) {
            showError(ERROR_PRICE_SEASON_UPDATE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PRICE_SEASON_UPDATE);
        }
    };

    return {
        updatePriceSeason,
        loading,
    };
};

export default useUpdatePriceSeason;
