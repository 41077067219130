import { MODAL_TITLE_CREATE_SCHEDULE } from 'constants/modalTitles';

import React, { FC, MouseEventHandler } from 'react';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal/index';
import { useStore } from 'store';

import ModalTitle from '../ModalTitle';

type PropsT = {
    id: string;
    title: string;
    onCreateScheduleButtonClick: () => void;
    loading: boolean;
};

const CreateScheduleModal: FC<PropsT> = observer((props) => {
    const {
        id, title, onCreateScheduleButtonClick, loading,
    } = props;
    const { priceSeasonId, onClose } = useStore('createScheduleModal');

    const handleCreateScheduleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onCreateScheduleButtonClick();
        onClose();
    };

    return (
        <Modal open={ priceSeasonId === id } maxWidth="xs">
            <ModalTitle onClose={ onClose }>
                { MODAL_TITLE_CREATE_SCHEDULE }
            </ModalTitle>
            <DialogContent dividers={ true }>
                Вы уверены, что хотите сгенерировать расписание для сезона <b>{ title }</b>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={ onClose }>Отменить</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleCreateScheduleButtonClick }
                    autoFocus
                    color="success"
                    loading={ loading }
                >
                    Сгенерировать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateScheduleModal;
