import { gql } from '@apollo/client';
import { PriceTimePeriodT } from 'types/Price';

export type PriceTimePeriodsResponseT = {
    priceTimePeriods: PriceTimePeriodT[];
};

export const PRICE_TIME_PERIODS_QUERY = gql`
    query PricesTimePeriodsQuery(
        $where: price_bool_exp! = {}
    ) {
        priceTimePeriods: price(
            where: $where,
            distinct_on: [time_start, time_end]
        ) {
            time_start
            time_end
        }
    }
`;
