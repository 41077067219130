import { useEffect } from 'react';
import useCoach from 'shared/data-hook/coach/fetch/fetchOne/useCoach';
import { useStore } from 'store';

const useFetchCoach = (id: string) => {
    const { fetchCoach, loading } = useCoach();
    const { setCoach } = useStore('updateCoachModal');

    useEffect(() => {
        fetchCoach(id).then((fetchedCoach) => {
            setCoach(fetchedCoach);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchCoach;
