import { gql } from '@apollo/client';
import { PriceT } from 'types/Price';

export type DeleteMultiplePriceResponseT = {
    deleteMultiplePrice: DeleteMultiplePriceResponseDataT;
};

export type DeleteMultiplePriceResponseDataT = {
    affectedRows: number;
}

export type DeleteMultiplePriceVarT = {
    ids: [PriceT['id']];
};

export const DELETE_MULTIPLE_PRICE = gql`
    mutation DeleteMultiplePrice($ids: [String!] = []) {
        deleteMultiplePrice: delete_price(where: {id: {_in: $ids}}) {
            affected_rows
        }
    }
`;
