import { gql } from '@apollo/client';

export type PaymentMethodCreatePayloadT = {
    title: string;
    position: number;
    is_active: boolean;
};

export type PaymentMethodCreateResponseDataT = {
    createPaymentMethod: {
        id: string;
    };
} | null | undefined;

export type PaymentMethodCreateVarT = {
    object: PaymentMethodCreatePayloadT;
};

export const PAYMENT_METHOD_CREATE = gql`
    mutation CreatePaymentMethod($object: payment_method_insert_input!) {
        createPaymentMethod: insert_payment_method_one(object: $object) {
            id
        }
    }
`;
