import { useMutation } from '@apollo/client';
import { GroupViewFormFields } from 'page/Group/components/formData';
import {
    GROUP_VIEW_UPDATE,
    GroupViewUpdateResponseDataT,
    GroupViewUpdateVarT,
} from 'shared/graphql/mutation/groupView/GroupViewUpdate';
import { GroupViewT } from 'types/GroupView';

import { UpdateGroupViewDataT } from './UpdateGroupViewData';

const useGroupViewUpdate = (): UpdateGroupViewDataT => {
    const [update, { error, loading }] = useMutation<GroupViewUpdateResponseDataT, GroupViewUpdateVarT>(
        GROUP_VIEW_UPDATE,
    );

    const updateAction = async (input: GroupViewFormFields, groupViewId: GroupViewT['id']) => {
        if (!groupViewId) {
            throw new Error('Не передан id группы');
        }

        try {
            const { data } = await update({
                variables: {
                    id: groupViewId,
                    set: {
                        name: input.name,
                        category_id: input.categoryId || null,
                        age_type_id: input.ageTypeId || null,
                        min_size: input.minSize,
                        avg_size: input.avgSize,
                        max_size: input.maxSize,
                        clients_for_main_coach: input.clientsForMainCoach,
                        ignore_busy_coaches: input.ignoreBusyCoaches,
                        group_constant_price: input.groupConstantPrice,
                        correction_factors: input.correctionFactors.map((cf, index) => ({
                            [index + 1]: parseFloat(cf.value),
                        })),
                    },
                },
            });

            return data;
            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        groupViewUpdate: updateAction,
        loading,
        error,
    };
};

export default useGroupViewUpdate;
