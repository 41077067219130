import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';

import Create from './Create';
import List from './List';
import Update from './Update';

const PriceType: FC = observer(() => {
    return (
        <Routes>
            <Route path="/" element={ <List /> } />
            <Route path="create" element={ <Create /> } />
            <Route path="edit/:id" element={ <Update /> } />
        </Routes>
    );
});

export default PriceType;
