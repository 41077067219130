import {
    ERROR_PRICE_MEMBER_CARD_TYPE_UPDATE,
    SUCCESS_PRICE_MEMBER_CARD_TYPE_UPDATE,
} from 'constants/notificationMessage';

import { PriceMemberCardFormFields } from 'page/PriceMemberCard/components/formData';
import useUpdatePriceMemberCardType from 'shared/data-hook/priceMemberCard/update/useUpdatePriceMemberCardType';
import { useStore } from 'store';

import { useNotification } from '../../useNotification';

const usePriceMemberCardTypeUpdate = () => {
    const {
        loading,
        updatePriceMemberCardType: updateAction,
    } = useUpdatePriceMemberCardType();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updatePriceMemberCardType = async (input: PriceMemberCardFormFields) => {
        const { status } = await updateAction(input);

        if (status === 'ok') {
            showSuccess(SUCCESS_PRICE_MEMBER_CARD_TYPE_UPDATE);
        } else {
            showError(ERROR_PRICE_MEMBER_CARD_TYPE_UPDATE);
        }

        toggleRefetchTrigger();
    };

    return {
        updatePriceMemberCardType,
        loading,
    };
};

export default usePriceMemberCardTypeUpdate;
