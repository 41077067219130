import { ERROR_PAYMENT_METHOD_DELETE, SUCCESS_PAYMENT_METHOD_DELETE } from 'constants/notificationMessage';

import usePaymentMethodDelete from 'shared/data-hook/paymentMethod/delete/single/usePaymentMethodDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { PaymentMethodT } from 'types/PaymentMethod';

const useDeletePaymentMethod = () => {
    const { error, deletePaymentMethod } = usePaymentMethodDelete();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const paymentMethodDelete = async (paymentMethodId: PaymentMethodT['id']) => {
        const response = await deletePaymentMethod(paymentMethodId);

        if (error || !response || !response.deletePaymentMethod) {
            showError(ERROR_PAYMENT_METHOD_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PAYMENT_METHOD_DELETE);
        }
    };

    return {
        paymentMethodDelete,
    };
};

export default useDeletePaymentMethod;
