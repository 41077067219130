import { PROPERTY_POSITION, PROPERTY_TITLE } from 'constants/propertyNames';

import { pick } from 'lodash';
import { PriceSalesRoundRolePermissionT, PriceSalesRoundT } from 'types/PriceSalesRound';
import { number, object, string } from 'yup';

export type PriceSalesRoundFormFields = Omit<PriceSalesRoundT, 'id' | 'priceTypeId'>

export type PriceSalesRoundRolePermissionFormFields = Omit<PriceSalesRoundRolePermissionT, 'id'>

export const GROUP_ROLE_PERMISSIONS = ['entryGroupAdd', 'entryGroupCancel', 'entryGroupCancelClient', 'entryGroupCreate', 'entryGroupUpdate'];

export const INDIVIDUAL_ROLE_PERMISSIONS = ['entryIndividualAddCoach', 'entryIndividualCancel', 'entryIndividualCreate', 'entryIndividualCreateLowerThanMinDuration', 'entryIndividualCreateOnlyRegularSchedule', 'entryIndividualTransfer'];

export const PRICE_SALES_ROUND_DEFAULT_VALUES: PriceSalesRoundFormFields = {
    title: '',
    position: 0,
    startHour: 0,
    startHourFromStart: false,
    startDay: 0,
    endDay: 0,
    endHour: 0,
    endMonth: 0,
    endWeek: 0,
    startMonth: 0,
    startWeek: 0,
    endDayFromStart: false,
    endHourFromStart: false,
    endMonthFromStart: false,
    endWeekFromStart: false,
    startDayFromStart: false,
    startMonthFromStart: false,
    startWeekFromStart: false,
    saleRateTitle: '',
    returnRateTitle: '',
    clubReturnRateTitle: '',
    minRateTitle: '',
    transferTitle: '',
    priceSaleRoundRolePermissions: [],
};

export const CREATE_PRICE_SALES_ROUND_DEFAULT_VALUES = { ...PRICE_SALES_ROUND_DEFAULT_VALUES };

export const PRICE_SALES_ROUND_HEAD_KEYS: string[] = Object.keys(pick(PRICE_SALES_ROUND_DEFAULT_VALUES, ['title', 'position']));

export const PRICE_SALES_ROUND_DESCRIPTION_KEYS: string[] = Object.keys(pick(PRICE_SALES_ROUND_DEFAULT_VALUES, ['saleRateTitle', 'returnRateTitle', 'clubReturnRateTitle', 'minRateTitle', 'transferTitle']));

export const PRICE_SALES_ROUND_START_KEYS = [
    {
        field: 'startMonth',
        switcher: 'startMonthFromStart',
    },
    {
        field: 'startWeek',
        switcher: 'startWeekFromStart',
    },
    {
        field: 'startDay',
        switcher: 'startDayFromStart',
    },
    {
        field: 'startHour',
        switcher: 'startHourFromStart',
    },
];

export const PRICE_SALES_ROUND_END_KEYS = [
    {
        field: 'endMonth',
        switcher: 'endMonthFromStart',
    },
    {
        field: 'endWeek',
        switcher: 'endWeekFromStart',
    },
    {
        field: 'endDay',
        switcher: 'endDayFromStart',
    },
    {
        field: 'endHour',
        switcher: 'endHourFromStart',
    },
];

type PriceSalesRoundLabelDataT = Record<keyof PriceSalesRoundFormFields, string>;

export const PRICE_SALES_ROUND_LABEL_DATA: PriceSalesRoundLabelDataT = {
    title: `${PROPERTY_TITLE} раунда продаж`,
    position: PROPERTY_POSITION,
    endDay: 'Смещение по дням',
    endHour: 'Смещение по часам',
    endMonth: 'Смещение по месяцам',
    endWeek: 'Смещение по неделям',
    startDay: 'Смещение по дням',
    startHour: 'Смещение по часам',
    startMonth: 'Смещение по месяцам',
    startWeek: 'Смещение по неделям',
    endDayFromStart: 'с начала дня',
    endHourFromStart: 'с начала часа',
    endMonthFromStart: 'с начала месяца',
    endWeekFromStart: 'с начала недели',
    startDayFromStart: 'с начала дня',
    startHourFromStart: 'с начала часа',
    startMonthFromStart: 'с начала месяца',
    startWeekFromStart: 'с начала недели',

    saleRateTitle: 'Название условия продаж при покупке',
    returnRateTitle: 'Название условия продаж при возврате по инициативе клиента',
    clubReturnRateTitle: 'Название условия продаж при возврате по инициативе клуба',
    minRateTitle: 'Название условия продаж при минимальной цене',
    transferTitle: 'Название условия продаж при переносе',
    priceSaleRoundRolePermissions: 'Настройка пользователя с ролью',
};

export const PRICE_SALE_ROUND_ROLE_PERMISSION_LABEL_DATA = {
    entryGroupAdd: 'Добавление групп',
    entryGroupCancel: 'Отмена групп',
    entryGroupCancelClient: 'Отмена участника группы',
    entryGroupCreate: 'Добавление участника группы',
    entryGroupUpdate: 'Изменение параметров занятия группы',
    entryIndividualAddCoach: 'Добавление тренера к персональному занятию',
    entryIndividualCancel: 'Отмена персонального занятия',
    entryIndividualCreate: 'Создание индивидуального занятия для клиента',
    entryIndividualCreateLowerThanMinDuration: 'Создание персонального занятия без проверки минимальной продолжительности',
    entryIndividualCreateOnlyRegularSchedule: 'Простановка персональных занятий только по шаблону расписания клиента',
    entryIndividualTransfer: 'Перенос персонального занятия',
    entryIndividualCreateAllowedCourtIds: 'Доступные корты для простановки занятий',
};

export const PRICE_SALES_ROUND_VALIDATION_SCHEMA = object().shape({
    title: string().required(),
    position: number().typeError(`${PRICE_SALES_ROUND_LABEL_DATA.position} должна числом`).integer(`${PRICE_SALES_ROUND_LABEL_DATA.position} должна быть целым числом`).required(),

    startDay: number().typeError(`${PRICE_SALES_ROUND_LABEL_DATA.startDay} должно быть числом`).integer(`${PRICE_SALES_ROUND_LABEL_DATA.startDay} должно быть целым числом`).required(),
    startHour: number().typeError(`${PRICE_SALES_ROUND_LABEL_DATA.startHour} должно быть числом`).integer(`${PRICE_SALES_ROUND_LABEL_DATA.startHour} должно быть целым числом`).required(),
    startMonth: number().typeError(`${PRICE_SALES_ROUND_LABEL_DATA.startMonth} должно быть числом`).integer(`${PRICE_SALES_ROUND_LABEL_DATA.startMonth} должно быть целым числом`).required(),
    startWeek: number().typeError(`${PRICE_SALES_ROUND_LABEL_DATA.startWeek} должно быть числом`).integer(`${PRICE_SALES_ROUND_LABEL_DATA.startWeek} должно быть целым числом`).required(),

    endDay: number().typeError(`${PRICE_SALES_ROUND_LABEL_DATA.endDay} должно быть числом`).integer(`${PRICE_SALES_ROUND_LABEL_DATA.endDay} должно быть целым числом`).required(),
    endHour: number().typeError(`${PRICE_SALES_ROUND_LABEL_DATA.endHour} должно быть числом`).integer(`${PRICE_SALES_ROUND_LABEL_DATA.endHour} должно быть целым числом`).required(),
    endMonth: number().typeError(`${PRICE_SALES_ROUND_LABEL_DATA.endMonth} должно быть числом`).integer(`${PRICE_SALES_ROUND_LABEL_DATA.endMonth} должно быть целым числом`).required(),
    endWeek: number().typeError(`${PRICE_SALES_ROUND_LABEL_DATA.endWeek} должно быть числом`).integer(`${PRICE_SALES_ROUND_LABEL_DATA.endWeek} должно быть целым числом`).required(),
}).required();
