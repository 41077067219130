import { useMutation } from '@apollo/client';
import {
    COACH_DELETE,
    CoachDeleteResponseDataT,
    CoachDeleteVarT,
} from 'shared/graphql/mutation/coach/CoachDelete';
import { CoachT } from 'types/Coach';

import { CoachDeleteDataT } from './CoachDeleteData';

const useCoachDelete = (): CoachDeleteDataT => {
    const [deleteCoach, { loading, error }] = useMutation<
        CoachDeleteResponseDataT,
        CoachDeleteVarT
    >(COACH_DELETE);

    const deleteAction = async (coachId: CoachT['id']) => {
        try {
            const { data } = await deleteCoach({
                variables: {
                    id: coachId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteCoach: deleteAction,
        loading,
        error,
    };
};

export default useCoachDelete;
