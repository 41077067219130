import { gql } from '@apollo/client';

export type PaymentTypeUpdateMutationPayloadT = {
    title: string;
    position: number;
    is_active: boolean;
    default_bonus_payment?: number;
    default_fiat_payment?: number;
    fiat_payment_divisible: number;
    bonus_payment_divisible: number;
    payment_method_id: string;
    should_confirm_by_sms: boolean;
    allow_negative_payments: boolean;
}

export type PaymentTypeUpdateResponseDataT = {
    updatePaymentType: {
        id: string;
    };
} | null | undefined;

export type PaymentTypeUpdateVarT = {
    id: string;
    set: PaymentTypeUpdateMutationPayloadT;
}

export const PAYMENT_TYPE_UPDATE = gql`
    mutation UpdatePaymentType($set: payment_type_set_input = {}, $id: String = "") {
        updatePaymentType: update_payment_type_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
