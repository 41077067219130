import { gql } from '@apollo/client';

import { PriceSeasonCreateFields } from './PriceSeasonCreate';

type PriceSeasonUpdateMutationPayloadT = PriceSeasonCreateFields;

export type PriceSeasonUpdateVar = {
    id: string;
    set: PriceSeasonUpdateMutationPayloadT;
};

export type PriceSeasonUpdateResponseDataT = {
    updatePriceSeason: {
        id: string;
    };
} | null | undefined;

export const PRICE_SEASON_UPDATE = gql`
    mutation UpdatePriceSeason($set: price_season_set_input = {}, $id: String = "") {
        updatePriceSeason: update_price_season_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
