import { PAGE_TITLE_PAYMENT_SETTING } from 'constants/pageTitles';

import { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import usePaymentMethods from 'shared/data-hook/paymentMethod/fetch/fetchAll/usePaymentMethods';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CreatePaymentMethodSettingModal from './components/CreatePaymentMethodSettingModal';
import PaymentSettingList from './components/PaymentSettingList';
import CreatePaymentTypeModal from './components/PaymentType/components/CreatePaymentTypeModal';
import UpdatePaymentTypeModal from './components/PaymentType/components/UpdatePaymentTypeModal';
import UpdatePaymentMethodSettingModal from './components/UpdatePaymentMethodSettingModal';
import { PAYMENT_METHOD_SETTING_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const { setPaymentMethods } = useStore('paymentMethod');
    const { onOpen } = useStore('createPaymentMethod');
    const { refetchTrigger } = useStore('refetchTrigger');
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: paymentMethodsData, loading, count: rowCount, fetchPaymentMethods,
    } = usePaymentMethods();

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchPaymentMethods, refetchTrigger, PAYMENT_METHOD_SETTING_HEADER_DATA);

    usePageTitle(PAGE_TITLE_PAYMENT_SETTING);

    useEffect(() => {
        setPaymentMethods(paymentMethodsData, rowCount);
    }, [paymentMethodsData, loading]);

    const openCreatePaymentMethod = () => {
        onOpen();
    };

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button variant="outlined" size="small" onClick={ openCreatePaymentMethod } startIcon={ <AddIcon /> }>добавить</Button>
            </ControlPanel>
            <PaymentSettingList
                loading={ loading }
                pageSize={ pageSize }
                page={ page }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <UpdatePaymentMethodSettingModal />
            <CreatePaymentMethodSettingModal />
            <UpdatePaymentTypeModal />
            <CreatePaymentTypeModal />
        </StyledPageWrap>
    );
});

export default List;
