import { DATA_GRID_PAGE_SIZE_SMALL, DATA_GRID_PAGE_SIZES } from 'constants/dataGrid';
import { MODAL_TITLE_UPDATE_PRICE_RATE_CHECK } from 'constants/modalTitles';

import React, { FC } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { ruRU } from '@mui/x-data-grid-premium';
import { observer } from 'mobx-react';
import { StyledDataGrid } from 'shared/component/DataGrid/domain/Styled';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { useStore } from 'store';

import { CHECK_UPDATE_PRICE_RATE_HEADER_DATA } from './domain/HeaderData';
import { UPDATE_PRICE_RATE_LABEL_DATA, UpdatePriceRateFormFields } from './formData';

type PropT = {
    openCheckModal: boolean;
    handleCloseCheckModal: () => void;
    handleSubmit: UseFormHandleSubmit<UpdatePriceRateFormFields>;
    handleUpdatePriceRate: (data: UpdatePriceRateFormFields) => void;
    updatePriceRateLoading: boolean;
};

const UpdatePriceRateCheckModal: FC<PropT> = observer((props) => {
    const {
        openCheckModal, handleCloseCheckModal, handleSubmit, handleUpdatePriceRate, updatePriceRateLoading,
    } = props;
    const { pricePreviewForMultipleRateUpdate } = useStore('price');
    const { mutableValues } = useStore('updatePriceRateModal');
    const pricePreviewForMultipleRateUpdateCount = pricePreviewForMultipleRateUpdate.length;
    const [pageSize, setPageSize] = React.useState<number>(DATA_GRID_PAGE_SIZE_SMALL);

    const prepareValue = (value: number | boolean | null) => {
        if (typeof value === 'boolean') {
            return value ? 'да' : 'нет';
        }

        if (value === null) {
            return 'null';
        }

        return value;
    };

    return (
        <Modal open={ openCheckModal } maxWidth="md">
            <ModalTitle onClose={ handleCloseCheckModal }>
                { MODAL_TITLE_UPDATE_PRICE_RATE_CHECK }
            </ModalTitle>
            <DialogContent dividers={ true }>
                {
                    pricePreviewForMultipleRateUpdateCount > 0 ?
                        (
                            <>
                                {
                                    mutableValues.length > 0 && (
                                        <Box sx={ { marginBottom: '18px' } }>
                                            <Typography variant="subtitle2" fontSize="18px">Измененные параметры:</Typography>
                                            <TableContainer component={ Paper }>
                                                <Table size="small" aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Поле</TableCell>
                                                            <TableCell align="right">Значение</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            mutableValues.map((item: any) => (
                                                                item.value !== null ? (
                                                                    <TableRow
                                                                        key={ item.label }
                                                                        sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            { UPDATE_PRICE_RATE_LABEL_DATA[item.label as keyof UpdatePriceRateFormFields] }
                                                                        </TableCell>
                                                                        <TableCell align="right">{ prepareValue(item.value) }</TableCell>
                                                                    </TableRow>
                                                                )
                                                                    : null
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    )
                                }
                                <Typography variant="subtitle2" fontSize="18px">Изменения будут применены к { pricePreviewForMultipleRateUpdateCount } строкам:</Typography>
                                <StyledDataGrid
                                    pagination
                                    autoHeight={ true }
                                    localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                                    rowsPerPageOptions={ DATA_GRID_PAGE_SIZES }
                                    rows={ pricePreviewForMultipleRateUpdate }
                                    columns={ CHECK_UPDATE_PRICE_RATE_HEADER_DATA }
                                    pageSize={ pageSize }
                                    onPageSizeChange={ (newPageSize) => setPageSize(newPageSize) }
                                />
                            </>
                        ) : (
                            <>
                                По выбранным параметрам строк не найдено
                            </>
                        )
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={ handleCloseCheckModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    disabled={ !pricePreviewForMultipleRateUpdateCount }
                    onClick={ handleSubmit(handleUpdatePriceRate) }
                    autoFocus
                    loading={ updatePriceRateLoading }
                >
                    применить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdatePriceRateCheckModal;
