import { useMutation } from '@apollo/client';
import {
    DUPLICATE_PRICE_SEASON, DuplicatePriceSeasonResponseT,
    DuplicatePriceSeasonVarT, EMPTY_DUPLICATE_RESULT_DATA,
} from 'shared/graphql/mutation/priceSeason/PriceSeasonDuplicate';
import { useStore } from 'store';
import { PriceSeasonT } from 'types/PriceSeason';

import { DuplicatePriceSeasonDataT } from './PriceSeasonDuplicateData';

const usePriceSeasonDuplicate = (): DuplicatePriceSeasonDataT => {
    const [duplicate, { loading }] = useMutation<DuplicatePriceSeasonResponseT, DuplicatePriceSeasonVarT>(DUPLICATE_PRICE_SEASON);
    const { serviceApp } = useStore('serviceApp');

    const duplicatePriceSeason = async (priceSeasonId: PriceSeasonT['id']) => {
        try {
            const { data } = await duplicate({
                variables: {
                    appId: serviceApp.id,
                    id: priceSeasonId,
                },
            });

            return data?.priceDuplicateSeason || EMPTY_DUPLICATE_RESULT_DATA;
        } catch {
            return EMPTY_DUPLICATE_RESULT_DATA;
        }
    };

    return {
        duplicatePriceSeason,
        loading,
    };
};

export default usePriceSeasonDuplicate;
