import { PROPERTY_ACTIONS, PROPERTY_POSITION, PROPERTY_TITLE } from 'constants/propertyNames';

import React, { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeletePriceSalesRound from 'shared/hook/priceSalesRound/delete/useDeletePriceSalesRound';
import { useStore } from 'store';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, title } } = props;
    const { onOpen } = useStore('updatePriceSalesRound');
    const { priceSalesRoundDelete } = useDeletePriceSalesRound();

    return (
        <>
            <IconButton onClick={ () => onOpen(id) }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ title } actionDelete={ priceSalesRoundDelete } />
        </>
    );
};

export const PRICE_SALES_ROUND_HEADER_DATA: GridColumns = [
    {
        field: 'title',
        headerName: PROPERTY_TITLE,
        type: 'string',
        flex: 1,
    },
    {
        field: 'position',
        headerName: PROPERTY_POSITION,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        flex: 1,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
];
