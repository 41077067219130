import { gql } from '@apollo/client';
import { PriceTypeT } from 'types/PriceType';

export type DeletePriceTypeResponseDataT = {
    deletePriceType: {
        id: PriceTypeT['id'];
    };
} | null | undefined;

export type DeletePriceTypeVarT = {
    id: PriceTypeT['id'];
};

export const PRICE_TYPE_DELETE = gql`
    mutation DeletePriceType($id: String = "") {
        deletePriceType: delete_price_type_by_pk(id: $id) {
            id
        }
    }
`;
