import { gql } from '@apollo/client';

export type PriceMultipleCreatePayloadT = {
    category_id: string;
    court_type_id: string;
    price_type_id: string;
    price_season_id: string;
    day: number;
    time_start: string;
    time_end: string;
    coach_category_id: string | null;
    group_view_id: string | null;
}

export type PriceFormFieldsPayloadT = {
    priceTypeId: string;
    priceSeasonId: string;
    days: number[];
    time: Array<{
        timeStart: string;
        timeEnd: string;
    }>;
    categoryIds: string[];
    courtTypeIds: string[];
    coachCategoryIds: string[];
    groupViewIds: string[];
};

export type PriceMultipleCreateVarT = {
    appId: string;
    payload: PriceMultipleCreatePayloadT[];
};

export type PriceMultipleCreateResponseT = Array<{
    status: string;
    reason: null | string;
}>

export type PriceMultipleCreateT = {
    priceMultipleCreate: PriceMultipleCreateResponseT;
}

export const EMPTY_PRICE_MULTIPLE_CREATE = [];

export const PRICE_MULTIPLE_CREATE = gql`
    mutation PriceMultipleCreate($appId: String!, $payload: json!) {
        priceMultipleCreate(app_id: $appId, payload: $payload) {
            status
            reason
        }
    }
`;
