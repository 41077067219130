import {
    ERROR_SERVICE_CATEGORY_CREATE,
    SUCCESS_SERVICE_CATEGORY_CREATE,
} from 'constants/notificationMessage';
import { SERVICE_CATEGORY } from 'constants/routes';

import { useNavigate } from 'react-router-dom';
import { ServiceCategoryFormFields } from 'page/ServiceCategory/components/formData';
import useCreateServiceCategory from 'shared/data-hook/serviceCategory/create/useCreateServiceCategory';
import { useNotification } from 'shared/hook/useNotification';

const useServiceCategoryCreate = () => {
    const { showError, showSuccess } = useNotification();
    const { serviceCategoryCreate, loading, error } = useCreateServiceCategory();
    const navigate = useNavigate();

    const createServiceCategory = async (input: ServiceCategoryFormFields) => {
        const response = await serviceCategoryCreate(input);

        if (error || !response || !response.createServiceCategoryType) {
            showError(ERROR_SERVICE_CATEGORY_CREATE);
        } else {
            showSuccess(SUCCESS_SERVICE_CATEGORY_CREATE);
        }

        return navigate(SERVICE_CATEGORY);
    };

    return {
        createServiceCategory,
        loading,
    };
};

export default useServiceCategoryCreate;
