export type ServiceCategoryT = {
    id: string;
    name: string;
    color: string | null;
    minDuration: number;
    minAdditionalDuration: number;
    position: number;
};

export type ServiceCategoriesT = {
    serviceCategories: ServiceCategoryT[];
    serviceCategoriesCount: number;
};

export const EMPTY_SERVICE_CATEGORY_RESULT: ServiceCategoryT = {
    id: '',
    name: '',
    color: null,
    minDuration: 0,
    minAdditionalDuration: 0,
    position: 0,
};

export const EMPTY_SERVICE_CATEGORIES_RESULT: ServiceCategoriesT = {
    serviceCategories: [],
    serviceCategoriesCount: 0,
};
