import { TIME_23_HOURS_30_MINUTE_15_STEP } from 'constants/hours';
import {
    PROPERTY_CATEGORY,
    PROPERTY_COURT_TYPE, PROPERTY_DAY, PROPERTY_END, PROPERTY_START,
} from 'constants/propertyNames';
import { WEEK_DAYS_VALUE_SHORT_OPTIONS } from 'constants/weekdays';

import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { getDayByNumber } from 'shared/utils/headerData/getDayByNumber';

export const CHECK_UPDATE_PRICE_RATE_HEADER_DATA: GridColumns = [
    {
        field: 'serviceCategory',
        headerName: PROPERTY_CATEGORY,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        flex: 1.3,
    },
    {
        field: 'courtType',
        headerName: PROPERTY_COURT_TYPE,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        flex: 1,
    },
    {
        field: 'day',
        headerName: PROPERTY_DAY,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params: GridValueFormatterParams) => getDayByNumber(params.value),
        type: 'singleSelect',
        valueOptions: WEEK_DAYS_VALUE_SHORT_OPTIONS,
        flex: 1,
    },
    {
        field: 'timeStart',
        headerName: PROPERTY_START,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        valueOptions: TIME_23_HOURS_30_MINUTE_15_STEP,
        flex: 1,
    },
    {
        field: 'timeEnd',
        headerName: PROPERTY_END,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        valueOptions: TIME_23_HOURS_30_MINUTE_15_STEP,
        flex: 1,
    },
];
