import { ERROR_PRICE_SEASON_CHECK } from 'constants/notificationMessage';

import usePriceSeasonCheckCrossing from 'shared/data-hook/priceSeason/checkCrossing/usePriceSeasonCheckCrossing';

import { useNotification } from '../../useNotification';
import useCreateSeason from '../create/useCreatePriceSeason';

const useCheckPriceSeasonCrossing = () => {
    const { showError, showWarning } = useNotification();
    const { createPriceSeason, loading: createPriceSeasonLoading } = useCreateSeason();
    const { checkSeasonCrossing, loading: checkSeasonLoading, error } = usePriceSeasonCheckCrossing();

    const checkSeason = async (input: any) => {
        const response = await checkSeasonCrossing(input);

        if (error || !response) {
            showError(ERROR_PRICE_SEASON_CHECK);

            return;
        }

        if (response.priceSeasons.length > 0) {
            showWarning(`Найдены пересечения c сезонами: <br/> ${response.priceSeasons.map((priceSeason: any) => `"${priceSeason.title}" - (${priceSeason.startAt} - ${priceSeason.finishAt})</br>`).join('')}`);

            return;
        }

        await createPriceSeason(input);
    };

    return {
        checkSeason,
        createPriceSeasonLoading,
        checkSeasonLoading,
    };
};

export default useCheckPriceSeasonCrossing;
