import {
    ERROR_PRICE_SEASON_CREATE, SUCCESS_PRICE_SEASON_CREATE,
} from 'constants/notificationMessage';
import { PRICE_SEASON } from 'constants/routes';

import { useNavigate } from 'react-router-dom';
import { SeasonFormFields } from 'page/PriceSeason/components/formData';
import usePriceSeasonCreate from 'shared/data-hook/priceSeason/create/usePriceSeasonCreate';

import { useNotification } from '../../useNotification';

const useCreatePriceSeason = () => {
    const { showError, showSuccess } = useNotification();
    const { priceSeasonCreate, loading, error } = usePriceSeasonCreate();
    const navigate = useNavigate();

    const createPriceSeason = async (input: SeasonFormFields) => {
        const response = await priceSeasonCreate(input);

        if (error || !response || !response.createPriceSeason) {
            showError(ERROR_PRICE_SEASON_CREATE);
        } else {
            showSuccess(SUCCESS_PRICE_SEASON_CREATE);
        }

        return navigate(PRICE_SEASON);
    };

    return {
        createPriceSeason,
        loading,
    };
};

export default useCreatePriceSeason;
