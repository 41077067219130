import { ERROR_SERVICE_CATEGORY_DELETE } from 'constants/notificationMessage';

import useServiceCategoryMultipleDelete
    from 'shared/data-hook/serviceCategory/delete/multiple/useServiceCategoryMultipleDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { ServiceCategoryT } from 'types/ServiceCategory';

const useDeleteMultipleServiceCategory = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteMultipleServiceCategory: actionDelete } = useServiceCategoryMultipleDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const deleteMultipleServiceCategory = async (serviceCategoryIds: [ServiceCategoryT['id']]) => {
        const { affectedRows } = await actionDelete(serviceCategoryIds);

        if (error || !affectedRows) {
            showError(ERROR_SERVICE_CATEGORY_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(`Строк удалено: ${affectedRows}`);
        }
    };

    return { deleteMultipleServiceCategory };
};

export default useDeleteMultipleServiceCategory;
