export const YES_AND_NO_BUTTONS = [
    {
        value: true,
        label: 'да',
    },
    {
        value: false,
        label: 'нет',
    },
];
