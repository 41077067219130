import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PaymentDocumentsT } from 'types/PaymentDocument';

import { PaymentDocumentDTO } from './PaymentDocumentDTO';

const DTO = t.type({
    paymentDocuments: t.array(PaymentDocumentDTO),
    paymentDocumentsCount: AggregateDTO,
});

type PaymentDocumentsDTOT = t.TypeOf<typeof DTO>;

export const PaymentDocumentsDTO = tPromise.extendDecoder<PaymentDocumentsDTOT, PaymentDocumentsT>(
    DTO,
    ({
        paymentDocuments,
        paymentDocumentsCount,
    }) => ({
        paymentDocuments,
        paymentDocumentsCount,
    }),
) as t.Type<PaymentDocumentsT, PaymentDocumentsDTOT>;
