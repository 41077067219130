import React, { FC, memo, ReactNode } from 'react';
import { observer } from 'mobx-react';

import { HeaderMenu as BaseHeaderMenu } from '../domain/Styled';

type PropsT = {
    anchor: HTMLElement | null;
    handleClose: (event: React.MouseEvent<HTMLElement>) => void;
    id: string;
    children: ReactNode;
}

const HeaderMenu: FC<PropsT> = observer(({
    anchor, handleClose, id, children,
}) => {
    return (
        <BaseHeaderMenu
            id={ id }
            anchorEl={ anchor }
            anchorOrigin={ {
                vertical: 'top',
                horizontal: 'right',
            } }
            keepMounted
            transformOrigin={ {
                vertical: 'top',
                horizontal: 'right',
            } }
            open={ Boolean(anchor) }
            onClose={ handleClose }
        >
            { children }
        </BaseHeaderMenu>
    );
});

export default memo(HeaderMenu);
