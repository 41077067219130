import { ERROR_PRICE_TYPE_UPDATE, SUCCESS_PRICE_TYPE_UPDATE } from 'constants/notificationMessage';
import { PRICE_TYPE } from 'constants/routes';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PriceTypeFormFields } from 'page/PriceType/components/formData';
import usePriceTypeUpdate from 'shared/data-hook/priceType/update/usePriceTypeUpdate';
import { useStore } from 'store';
import { PriceTypeT } from 'types/PriceType';

import { useNotification } from '../../useNotification';

const useUpdatePriceType = () => {
    const { error, loading, priceTypeUpdate } = usePriceTypeUpdate();
    const { showError, showSuccess } = useNotification();
    const { setPriceTypeLoading } = useStore('priceTypes');
    const navigate = useNavigate();

    useEffect(() => {
        setPriceTypeLoading(loading);
    }, [loading]);

    const updatePriceType = async (
        input: PriceTypeFormFields,
        priceType: PriceTypeT | undefined,
    ) => {
        const response = await priceTypeUpdate(input, priceType);

        if (error || !response || !response.updatePriceType) {
            showError(ERROR_PRICE_TYPE_UPDATE);
        } else {
            showSuccess(SUCCESS_PRICE_TYPE_UPDATE);
        }

        return navigate(PRICE_TYPE);
    };

    return {
        updatePriceType,
    };
};

export default useUpdatePriceType;
