import { gql } from '@apollo/client';
import { SeasonFormFields } from 'page/PriceSeason/components/formData';
import { PriceSeasonT } from 'types/PriceSeason';

export type CheckPriceSeasonCrossingVarT = {
    startAt: string;
    finishAt: string;
};

export type CheckPriceSeasonCrossingFields = Pick<SeasonFormFields, 'startAt' | 'finishAt'>

export type CheckPriceSeasonCrossingResponseDataT = {
    priceSeasons: Array<Pick<PriceSeasonT, 'title' | 'startAt' | 'finishAt'>>;
} | null | undefined;

export const CHECK_PRICE_SEASON_CROSSING_QUERY = gql`
    query CheckPriceSeasonCross($startAt: date!, $finishAt: date!) {
        priceSeasons: price_season(
            order_by: {start_at: asc}
            where: {
                _or: [
                    {_and: [
                        {start_at: {_lte: $startAt}},
                        {start_at: {_lte: $finishAt}},
                        {finish_at: {_gte: $startAt}},
                        {finish_at: {_lte: $finishAt}},
                    ]},
                    {_and: [
                        {start_at: {_lte: $startAt}},
                        {start_at: {_lte: $finishAt}},
                        {finish_at: {_gte: $startAt}},
                        {finish_at: {_gte: $finishAt}},
                    ]},
                    {_and: [
                        {start_at: {_gte: $startAt}},
                        {start_at: {_lte: $finishAt}},
                        {finish_at: {_gte: $startAt}},
                        {finish_at: {_gte: $finishAt}},
                    ]},
                    {_and: [
                        {start_at: {_gte: $startAt}},
                        {start_at: {_lte: $finishAt}},
                        {finish_at: {_gte: $startAt}},
                        {finish_at: {_lte: $finishAt}},
                    ]}
                ]}) {
            title
            start_at
            finish_at
        }
    }
`;
