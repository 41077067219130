import React, { FC, MouseEventHandler } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import { useStore } from 'store';

import DuplicateModal from '../../Modal/DuplicateModal';

type PropsT = {
    id: string;
    title: string;
    actionDuplicate: (id: string) => Promise<any>;
};

const DuplicateButton: FC<PropsT> = (props) => {
    const {
        id, title, actionDuplicate,
    } = props;
    const { onOpen } = useStore('duplicateItemModal');

    const handleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onOpen(id || 'id');
    };

    const onDuplicateButtonClick = () => {
        actionDuplicate(id).then();
    };

    return (
        <>
            <Tooltip title="Дублировать" placement="top">
                <IconButton onClick={ handleButtonClick }>
                    <ContentCopyIcon color="info" />
                </IconButton>
            </Tooltip>
            <DuplicateModal
                id={ id || 'id' }
                title={ title }
                onDuplicateButtonClick={ onDuplicateButtonClick }
            />
        </>
    );
};

export default DuplicateButton;
