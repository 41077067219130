import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    PRICE_TYPE_QUERY,
    PriceTypeResponseT,
} from 'shared/graphql/query/priceType/PriceTypeQuery';
import { EMPTY_PRICE_TYPE_RESULT, PriceTypeT } from 'types/PriceType';

import { PriceTypeDTO } from '../fetchAll/DTO/PriceTypeDTO';

import { PriceTypeDataT } from './PriceTypeData';

const usePriceType = (): PriceTypeDataT => {
    const [fetch, { loading }] = useLazyQuery<PriceTypeResponseT>(PRICE_TYPE_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchPriceType = async (id: string): Promise<PriceTypeT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                PriceTypeDTO,
                EMPTY_PRICE_TYPE_RESULT,
                (priceType) => priceType,
                data?.priceType,
            );
        } catch {
            return EMPTY_PRICE_TYPE_RESULT;
        }
    };

    return {
        fetchPriceType,
        loading,
    };
};

export default usePriceType;
