import { GridColumnGroupingModel } from '@mui/x-data-grid-premium';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

export const setColumnGroupingModel = (priceSalesRounds: PriceSalesRoundT[]) => {
    const columnGroupingModel: GridColumnGroupingModel = [];

    priceSalesRounds.forEach((priceSalesRound) => {
        columnGroupingModel.push({
            groupId: priceSalesRound.id,
            headerName: priceSalesRound.title,
            headerAlign: 'center',
            headerClassName: `data-grid-section-court-${priceSalesRound.position % 2 === 0 ? 'even' : 'odd'}`,
            children: [
                { field: `${priceSalesRound.id}-fiat-discount` },
                { field: `${priceSalesRound.id}-fiat-cashback` },
                { field: `${priceSalesRound.id}-bonus-discount` },
                { field: `${priceSalesRound.id}-bonus-cashback` },
                { field: `${priceSalesRound.id}-promo` },
                { field: `${priceSalesRound.id}-multiply` },
            ],
        });
    });

    return columnGroupingModel;
};
