import { useLazyQuery } from '@apollo/client';
import { PaymentMethodsDTO } from 'shared/data-hook/paymentMethod/fetch/DTO/PaymentMethodsDTO';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    PAYMENT_METHODS_QUERY,
    PaymentMethodsResponseT,
} from 'shared/graphql/query/paymentMethod/PaymentMethodQuery';
import { FetchDataParams } from 'types/DataGrid';
import { EMPTY_PAYMENT_METHODS } from 'types/PaymentMethod';

import { PaymentMethodsDataT } from './PaymentMethodsData';

const usePaymentMethods = (): PaymentMethodsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<PaymentMethodsResponseT>(
        PAYMENT_METHODS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedPaymentMethodsData = decodeDTO(
        PaymentMethodsDTO,
        EMPTY_PAYMENT_METHODS,
        (paymentMethods) => paymentMethods,
        {
            paymentMethods: data?.paymentMethods,
            paymentMethodsCount: data?.paymentMethodsCount,
        },
    );

    const fetchPaymentMethods = async ({
        limit,
        offset,
        orderBy = { position: 'asc' },
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        fetchPaymentMethods,
        loading,
        data: decodedPaymentMethodsData.paymentMethods,
        count: decodedPaymentMethodsData.paymentMethodsCount,
    };
};

export default usePaymentMethods;
