import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceT } from 'types/Price';

const DTO = t.type({
    id: t.string,
    day: t.number,
    time_start: t.string,
    time_end: t.string,
    court_type: t.string,
    category: t.string,
});

export type PriceDTOT = t.TypeOf<typeof DTO>;

export const PriceDTO = tPromise.extendDecoder<PriceDTOT, PriceT>(
    DTO,
    ({
        id,
        day,
        time_start,
        time_end,
        court_type,
        category,
    }) => ({
        id,
        day,
        timeStart: time_start,
        timeEnd: time_end,
        courtType: court_type,
        category,
    }),
) as t.Type<PriceT, PriceDTOT>;
