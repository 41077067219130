import { useMutation } from '@apollo/client';
import { PaymentMethodFormFields } from 'page/PaymentSetting/components/formData';
import {
    PAYMENT_METHOD_UPDATE,
    PaymentMethodUpdateResponseDataT,
    PaymentMethodUpdateVarT,
} from 'shared/graphql/mutation/paymentMethod/PaymentMethodUpdate';
import { PaymentMethodT } from 'types/PaymentMethod';

import { PaymentMethodUpdateDataT } from './PaymentMethodUpdateData';

const usePaymentMethodUpdate = (): PaymentMethodUpdateDataT => {
    const [update, { loading, error }] = useMutation<
            PaymentMethodUpdateResponseDataT,
            PaymentMethodUpdateVarT
        >(PAYMENT_METHOD_UPDATE);
    const updateAction = async (input: PaymentMethodFormFields, paymentMethodId: PaymentMethodT['id']) => {
        try {
            const { data } = await update({
                variables: {
                    id: paymentMethodId,
                    set: {
                        title: input.title,
                        position: input.position,
                        is_active: input.isActive,
                    },
                },
            });

            return data;
            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        paymentMethodUpdate: updateAction,
        loading,
        error,
    };
};

export default usePaymentMethodUpdate;
