import { useLazyQuery } from '@apollo/client';
import { PaymentMethodDTO } from 'shared/data-hook/paymentMethod/fetch/DTO/PaymentMethodDTO';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { PAYMENT_METHOD_QUERY, PaymentMethodResponseT } from 'shared/graphql/query/paymentMethod/PaymentMethodQuery';
import { EMPTY_PAYMENT_METHOD_RESULT } from 'types/PaymentMethod';

import { PaymentMethodDataT } from './PaymentMethodData';

const usePaymentMethod = (): PaymentMethodDataT => {
    const [fetch, { loading, error }] = useLazyQuery<PaymentMethodResponseT>(PAYMENT_METHOD_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchPaymentMethod = async (id: string) => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(PaymentMethodDTO, EMPTY_PAYMENT_METHOD_RESULT, (paymentMethod) => paymentMethod, data?.paymentMethod);
        } catch {
            return EMPTY_PAYMENT_METHOD_RESULT;
        }
    };

    return {
        fetchPaymentMethod,
        loading,
        error,
    };
};

export default usePaymentMethod;
