import { useLazyQuery } from '@apollo/client';
import {
    SERVICE_CATEGORIES_QUERY,
    ServiceCategoriesResponseT,
} from 'shared/graphql/query/serviceCategory/ServiceCategoryQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_SERVICE_CATEGORIES_RESULT } from 'types/ServiceCategory';

import decodeDTO from '../../../utils/DTO/decodeDTO';
import { filterData } from '../../../utils/filter/filterData';
import { ServiceCategoriesDTO } from '../DTO/ServiceCategoriesDTO';

import { ServiceCategoriesDataT } from './ServiceCategoriesData';

const useServiceCategories = (): ServiceCategoriesDataT => {
    const [fetch, { data: serviceCategoriesData, loading }] = useLazyQuery<ServiceCategoriesResponseT, QueryVarsT>(
        SERVICE_CATEGORIES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedServiceCategoriesData = decodeDTO(
        ServiceCategoriesDTO,
        EMPTY_SERVICE_CATEGORIES_RESULT,
        (data) => data,
        {
            serviceCategories: serviceCategoriesData?.serviceCategories,
            serviceCategoriesCount: serviceCategoriesData?.serviceCategoriesCount,
        },
    );

    const fetchServiceCategories = async ({
        limit, offset, orderBy = { position: 'asc' }, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchServiceCategories,
        loading,
        data: decodedServiceCategoriesData.serviceCategories,
        count: decodedServiceCategoriesData.serviceCategoriesCount,
    };
};

export default useServiceCategories;
