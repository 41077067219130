import { useMutation } from '@apollo/client';
import { camelizeKeys } from 'humps';
import {
    DELETE_MULTIPLE_PRICE, DeleteMultiplePriceResponseDataT, DeleteMultiplePriceResponseT, DeleteMultiplePriceVarT,
} from 'shared/graphql/mutation/price/PriceMultipleDelete';
import { PriceT } from 'types/Price';

import { PriceMultipleDeleteDataT } from './PriceMultipleDeleteData';

const usePriceMultipleDelete = (): PriceMultipleDeleteDataT => {
    const [deletePrices, { loading: deleteLoading, error: errorDelete }] = useMutation<
        DeleteMultiplePriceResponseT,
        DeleteMultiplePriceVarT
    >(DELETE_MULTIPLE_PRICE);

    const deleteAction = async (priceIds: [PriceT['id']]) => {
        try {
            const { data } = await deletePrices({
                variables: {
                    ids: priceIds,
                },
            });

            return camelizeKeys(data?.deleteMultiplePrice) as DeleteMultiplePriceResponseDataT;
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        loading: deleteLoading,
        deleteMultiplePrice: deleteAction,
        error: errorDelete,
    };
};

export default usePriceMultipleDelete;
