import { useMutation } from '@apollo/client';
import {
    PAYMENT_TYPE_DELETE,
    PaymentTypeDeleteResponseDataT,
    PaymentTypeDeleteVarT,
} from 'shared/graphql/mutation/paymentType/PaymentTypeDelete';
import { PaymentTypeT } from 'types/PaymentType';

import { PaymentTypeDeleteDataT } from './PaymentTypeDeleteData';

const usePaymentTypeDelete = (): PaymentTypeDeleteDataT => {
    const [deletePaymentType, { loading, error }] = useMutation<
        PaymentTypeDeleteResponseDataT,
        PaymentTypeDeleteVarT
    >(PAYMENT_TYPE_DELETE);

    const deleteAction = async (paymentTypeId: PaymentTypeT['id']) => {
        try {
            const { data } = await deletePaymentType({
                variables: {
                    id: paymentTypeId,
                },
            });

            return data;
        } catch (errorDelete) {
            // TODO: use Sentry
            console.error((errorDelete as Error).message); // eslint-disable-line
            throw errorDelete;
        }
    };

    return {
        deletePaymentType: deleteAction,
        loading,
        error,
    };
};

export default usePaymentTypeDelete;
