import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PriceT } from 'types/Price';

export type PricesResponseT = {
    prices: PriceT[];
    pricesCount: AggregateT;
};

export type PriceResponseT = {
    price: PriceT;
};

export const PRICES_QUERY = gql`
    query PricesQuery(
        $limit: Int,
        $offset: Int,
        $orderBy: price_order_by! = {},
        $where: price_bool_exp! = {},
        $priceTypeId: String,
        $priceSeasonId: String,
    ) {
        prices: price(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: {
                _and: [
                    $where,
                    { price_type_id: { _eq: $priceTypeId } },
                    { price_season_id: { _eq: $priceSeasonId } }
                ]
            }
        ) {
            id
            day
            time_end
            time_start
            court_type {
                name
            }
            service_category {
                name
            }
            group_view {
                name
            }
            coach_category {
                name
            }
            price_rates {
                price_sales_round_id
                sale_rate
                min_rate
                forward_transfer_cost
                back_transfer_cost
                transferable
                cancellable
                return_rate
                performer_return_income_rate
                performer_return_income_sum
                performer_sale_income_rate
                performer_sale_income_sum
                share_of_payment_fiat
                share_of_payment_bonus
                transfer_disabled_before_hours
            }
        }
        pricesCount: price_aggregate(where: {
            _and: [
                $where,
                { price_type_id: { _eq: $priceTypeId } },
                { price_season_id: { _eq: $priceSeasonId } }
            ]
        }) {
            aggregate {
                count
            }
        }
    }
`;

export const PRICE_QUERY = gql`
    query PriceQuery($id: String!) {
        price: price_by_pk(id: $id) {
            id
            day
            time_end
            time_start
            court_type: court_type_id
            category: category_id
        }
    }
`;
