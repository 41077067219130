import { ERROR_SCHEDULE_RECREATE, SUCCESS_SCHEDULE_RECREATE } from 'constants/notificationMessage';

import useScheduleSlotRateRecreateByPriceData
    from 'shared/data-hook/scheduleSlotRate/create/useScheduleSlotRateRecreateByPriceData';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useScheduleSlotRateRecreateForPrice = () => {
    const { error, loading, recreateScheduleSlotRate: recreate } = useScheduleSlotRateRecreateByPriceData();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const recreateScheduleSlotRateForPrice = async (priceSeasonId: string, priceIds: string[]) => {
        const response = await recreate(priceSeasonId, priceIds);

        if (!error || !response) {
            showSuccess(SUCCESS_SCHEDULE_RECREATE);
            toggleRefetchTrigger();
        } else {
            showError(ERROR_SCHEDULE_RECREATE);
        }
    };

    return {
        recreateScheduleSlotRateForPrice,
        loading,
    };
};

export default useScheduleSlotRateRecreateForPrice;
