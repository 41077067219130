import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Client from 'shared/data-hook/utils/DTO/Client';
import { decodeClientData } from 'shared/data-hook/utils/DTO/decodeClientData';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { ScheduleSlotReserveT } from 'types/ScheduleSlotReserve';

const DTO = t.type({
    id: t.string,
    implementation_document: t.type({
        number: t.string,
        type: Nullable(t.string),
        service_category: Nullable(t.type({
            name: t.string,
        })),
        group: Nullable(t.type({
            name: t.string,
        })),
        client_datum: Client(),
    }),
    price_season: t.type({
        title: t.string,
    }),
    datetime_start: t.string,
    datetime_end: t.string,
    coaches_ids: t.array(t.string),
});

export type ScheduleSlotReserveDTOT = t.TypeOf<typeof DTO>;

export const ScheduleSlotReserveDTO = tPromise.extendDecoder<ScheduleSlotReserveDTOT, ScheduleSlotReserveT>(
    DTO,
    ({
        id,
        implementation_document: {
            number, type, service_category, group, client_datum,
        },
        coaches_ids,
        price_season: { title },
        datetime_start, datetime_end,
    }) => ({
        id,
        implementationDocumentNumber: number,
        priceSeasonTitle: title,
        datetimeStart: new Date(datetime_start),
        datetimeEnd: new Date(datetime_end),
        type,
        serviceCategory: service_category?.name || null,
        group: group?.name || null,
        client: decodeClientData(client_datum),
        coachesIds: coaches_ids,
    }),
) as t.Type<ScheduleSlotReserveT, ScheduleSlotReserveDTOT>;
