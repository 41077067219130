import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { prepareScheduleSlotReserveHeaderData } from '../domain/prepareScheduleSlotReserveHeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const ScheduleSlotReserveList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;
    const {
        scheduleSlotReserves, count,
    } = useStore('scheduleSlotReserve');
    const { getFilterOption } = useStore('filterOptions');
    const serviceCategoryFilterOptions = getFilterOption('serviceCategory');
    const coachFilterOptions = getFilterOption('coach');
    const groupFilterOptions = getFilterOption('group');

    const columns = prepareScheduleSlotReserveHeaderData(serviceCategoryFilterOptions, coachFilterOptions, groupFilterOptions);

    return (
        <DataGrid
            rows={ scheduleSlotReserves }
            rowCount={ count }
            columns={ columns }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
            rowAutoHeight={ true }
        />
    );
});

export default ScheduleSlotReserveList;
