import React, { useState } from 'react';

type UseMenu = {
    anchorEl: HTMLElement | null;
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
    handleClose: () => void;
};

const useMenu = (): UseMenu => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return { anchorEl, handleOpen, handleClose };
};

export default useMenu;
