export type PaymentDocumentT = {
    id: string;
    number: string;
    datetime: Date;
    payments: Array<{
        id: string;
    }>;
    organization: {
        name: string;
    };
};

export type PaymentDocumentsT = {
    paymentDocuments: PaymentDocumentT[];
    paymentDocumentsCount: number;
};

export const EMPTY_PAYMENT_DOCUMENTS: PaymentDocumentsT = {
    paymentDocuments: [],
    paymentDocumentsCount: 0,
};
