import { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useHeaderNameServiceApp } from 'shared/hook/useHeaderNameServiceApp';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { PAYMENT_METHOD_SETTING_HEADER_DATA } from '../domain/HeaderData';

import { paymentTypeHeaderData } from './PaymentType/domain/HeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const PaymentSettingList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;
    const { paymentMethods, count } = useStore('paymentMethod');
    const { currencyHeaderName, divisibleHeaderName } = useHeaderNameServiceApp();

    return (
        <DataGrid
            rows={ paymentMethods }
            rowCount={ count }
            columns={ PAYMENT_METHOD_SETTING_HEADER_DATA }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
            detailPanelColumns={ paymentTypeHeaderData({ currencyHeaderName, divisibleHeaderName }) }
        />
    );
});

export default PaymentSettingList;
