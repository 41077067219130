import { TIMEZONE_SELECT_OPTIONS } from 'constants/timezone';

import {
    FC, useEffect, useMemo,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { LoadingButton } from '@mui/lab';
import {
    Box, Button, IconButton, Stack, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import useUpdateServiceApp from 'shared/hook/serviceApp/update/useUpdateServiceApp';
import { useStore } from 'store';

import {
    SERVICE_APP_DEFAULT_VALUES, SERVICE_APP_FIELDS, SERVICE_APP_FIELDS_KEYS, SERVICE_APP_LABEL_DATA,
    SERVICE_APP_VALIDATION_SCHEMA, ServiceAppFormFields,
} from '../formData';

const ServiceAppForm: FC = observer(() => {
    const {
        control, handleSubmit, setValue,
    } = useForm<ServiceAppFormFields>({
        defaultValues: SERVICE_APP_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(SERVICE_APP_VALIDATION_SCHEMA),
    });
    const { fields: idFields, append, remove } = useFieldArray<ServiceAppFormFields>({
        control,
        name: SERVICE_APP_FIELDS_KEYS.videoAnalyticsTelegramBotChatIds,
    });
    const { serviceApp } = useStore('serviceApp');
    const { updateServiceApp, loading: loadingUpdate } = useUpdateServiceApp();

    const serviceAppInfo: ServiceAppFormFields = useMemo(() => {
        const info = SERVICE_APP_DEFAULT_VALUES;

        if (serviceApp) {
            Object.keys(SERVICE_APP_DEFAULT_VALUES).forEach((key) => {
                if (key !== SERVICE_APP_FIELDS_KEYS.videoAnalyticsTelegramBotChatIds) {
                    info[key as keyof ServiceAppFormFields] = serviceApp[key as keyof ServiceAppFormFields] as never;
                }
            });
        }

        return info;
    }, [serviceApp]);

    useEffect(() => {
        remove();
        serviceApp[SERVICE_APP_FIELDS_KEYS.videoAnalyticsTelegramBotChatIds].forEach((id) => {
            append({ item: id });
        });
    }, [serviceApp]);

    useEffect(() => {
        Object.keys(SERVICE_APP_DEFAULT_VALUES).forEach((key) => {
            if (key !== SERVICE_APP_FIELDS_KEYS.videoAnalyticsTelegramBotChatIds) {
                setValue(key as keyof ServiceAppFormFields, serviceAppInfo[key as keyof ServiceAppFormFields]);
            }
        });
    }, [serviceApp]);

    const appendField = () => append({ item: '' });

    const handleUpdateServiceApp = (data: ServiceAppFormFields) => {
        updateServiceApp(data, serviceApp.id).then();
    };

    return (
        <form onSubmit={ handleSubmit(handleUpdateServiceApp) }>
            <Box my={ 2 } mx={ 3 }>
                <Grid2 container direction="column" spacing={ 1 }>
                    {
                        SERVICE_APP_FIELDS.map((field) => (
                            <Grid2 key={ field } sm={ 12 } md={ 8 }>
                                <TextField
                                    size="small"
                                    key={ field }
                                    name={ field as keyof ServiceAppFormFields }
                                    type={ typeof SERVICE_APP_DEFAULT_VALUES[field as keyof ServiceAppFormFields] }
                                    control={ control }
                                    label={ SERVICE_APP_LABEL_DATA[field as keyof ServiceAppFormFields] }
                                />
                            </Grid2>
                        ))
                    }
                    <Grid2>
                        <Switch
                            name={ SERVICE_APP_FIELDS_KEYS.smsSendingEnabled as keyof ServiceAppFormFields }
                            control={ control }
                            label={ SERVICE_APP_LABEL_DATA[SERVICE_APP_FIELDS_KEYS.smsSendingEnabled] }
                        />
                    </Grid2>
                    <Grid2 sm={ 12 } md={ 8 }>
                        <Select
                            options={ TIMEZONE_SELECT_OPTIONS }
                            control={ control }
                            name={ SERVICE_APP_FIELDS_KEYS.timezone as keyof ServiceAppFormFields }
                            label={ SERVICE_APP_LABEL_DATA[SERVICE_APP_FIELDS_KEYS.timezone] }
                        />
                    </Grid2>
                </Grid2>
                <Typography variant="body1" mt={ 2 }>
                    { SERVICE_APP_LABEL_DATA[SERVICE_APP_FIELDS_KEYS.videoAnalyticsTelegramBotChatIds as keyof ServiceAppFormFields] }
                </Typography>
                <Grid2 direction="column" container spacing={ 1 }>
                    {
                        (idFields.length === 0) ? (
                            <Grid2 sm={ 12 } md={ 2 } mt={ 1 }>
                                <Button
                                    variant="outlined"
                                    startIcon={ <AddIcon /> }
                                    onClick={ appendField }
                                >
                                    Добавить поле
                                </Button>
                            </Grid2>
                        ) : idFields.map((item, idx) => (
                            <Grid2 container key={ item.id } mt={ 1 }>
                                <Grid2 md={ 8 }>
                                    <TextField
                                        size="small"
                                        key={ SERVICE_APP_FIELDS_KEYS.videoAnalyticsTelegramBotChatIds }
                                        name={ `videoAnalyticsTelegramBotChatIds.${idx}.item` }
                                        type={ typeof SERVICE_APP_DEFAULT_VALUES[SERVICE_APP_FIELDS_KEYS.videoAnalyticsTelegramBotChatIds as keyof ServiceAppFormFields] }
                                        control={ control }
                                    />
                                </Grid2>
                                <Grid2>

                                    <IconButton
                                        color="info"
                                        onClick={ appendField }
                                    >
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton
                                        color="error"
                                        onClick={ () => remove(idx) }
                                    >
                                        <RemoveIcon />
                                    </IconButton>

                                </Grid2>
                            </Grid2>
                        ))
                    }
                </Grid2>
                <Stack direction="row" my={ 4 }>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={ loadingUpdate }
                    >
                        Сохранить
                    </LoadingButton>
                </Stack>
            </Box>
        </form>
    );
});

export default ServiceAppForm;
