import { gql } from '@apollo/client';

export type PaymentMethodUpdateResponseDataT = {
    updatePaymentMethod: {
        id: string;
    };
} | null | undefined;

type PaymentMethodUpdateMutationPayloadT = {
    title: string;
    position: number;
    is_active: boolean;
}

export type PaymentMethodUpdateVarT = {
    id: string;
    set: PaymentMethodUpdateMutationPayloadT;
};

export const PAYMENT_METHOD_UPDATE = gql`
    mutation updatePaymentMethod($set: payment_method_set_input = {}, $id: String = "") {
        updatePaymentMethod: update_payment_method_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
