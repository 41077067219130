export type PriceSalesRoundRolePermissionT = {
    id: string;
    entryIndividualCreate: boolean;
    entryIndividualCreateOnlyRegularSchedule: boolean;
    entryIndividualAddCoach: boolean;
    entryIndividualCreateAllowedCourtIds: string[];
    entryIndividualCancel: boolean;
    entryIndividualTransfer: boolean;
    entryGroupAdd: boolean;
    entryGroupCancel: boolean;
    entryGroupCreate: boolean;
    entryGroupUpdate: boolean;
    entryGroupCancelClient: boolean;
    role: string;
    entryIndividualCreateLowerThanMinDuration: boolean | null;
};

export type PriceSalesRoundT = {
    id: string;
    title: string;
    position: number;
    priceTypeId: string;
    endDay: number;
    endHour: number;
    endMonth: number;
    endWeek: number;
    startDay: number;
    startHour: number;
    startMonth: number;
    startWeek: number;
    endDayFromStart: boolean;
    endHourFromStart: boolean;
    endMonthFromStart: boolean;
    endWeekFromStart: boolean;
    startDayFromStart: boolean;
    startHourFromStart: boolean;
    startMonthFromStart: boolean;
    startWeekFromStart: boolean;
    saleRateTitle: string;
    returnRateTitle: string;
    clubReturnRateTitle: string;
    minRateTitle: string;
    transferTitle: string;
    priceSaleRoundRolePermissions: PriceSalesRoundRolePermissionT[];
};

export type PriceSalesRoundsT = {
    priceSalesRounds: PriceSalesRoundT[];
}

export const EMPTY_PRICE_SALES_ROUND_RESULT: PriceSalesRoundT = {
    id: '',
    title: '',
    position: 0,
    endDay: 0,
    endHour: 0,
    endMonth: 0,
    endWeek: 0,
    startDay: 0,
    startHour: 0,
    startMonth: 0,
    startWeek: 0,
    endDayFromStart: false,
    endHourFromStart: false,
    endMonthFromStart: false,
    endWeekFromStart: false,
    startDayFromStart: false,
    startHourFromStart: false,
    startMonthFromStart: false,
    startWeekFromStart: false,
    priceTypeId: '',
    saleRateTitle: '',
    returnRateTitle: '',
    clubReturnRateTitle: '',
    minRateTitle: '',
    transferTitle: '',
    priceSaleRoundRolePermissions: [],
};

export const EMPTY_PRICE_SALES_ROUNDS_RESULT: PriceSalesRoundsT = {
    priceSalesRounds: [],
};
