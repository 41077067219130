import { useMutation } from '@apollo/client';
import { PaymentTypeFormFields } from 'page/PaymentSetting/components/PaymentType/components/formData';
import {
    PAYMENT_TYPE_CREATE,
    PaymentTypeCreateResponseDataT,
    PaymentTypeCreateVarT,
} from 'shared/graphql/mutation/paymentType/PaymentTypeCreate';
import { PaymentMethodT } from 'types/PaymentMethod';

import { CreatePaymentTypeDataT } from './CreatePaymentTypeData';

const useCreatePaymentType = (): CreatePaymentTypeDataT => {
    const [create, { loading, error }] = useMutation<
        PaymentTypeCreateResponseDataT,
        PaymentTypeCreateVarT
    >(PAYMENT_TYPE_CREATE);

    const createAction = async (input: PaymentTypeFormFields, paymentTypeId: PaymentMethodT['id']) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        title: input.title,
                        position: input.position,
                        is_active: input.isActive,
                        default_bonus_payment: input.defaultBonusPayment ?? null,
                        default_fiat_payment: input.defaultFiatPayment ?? null,
                        fiat_payment_divisible: input.fiatPaymentDivisible,
                        bonus_payment_divisible: input.bonusPaymentDivisible,
                        payment_method_id: paymentTypeId,
                        should_confirm_by_sms: input.shouldConfirmBySms,
                        allow_negative_payments: input.allowNegativePayments,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        loading,
        error,
        paymentTypeCreate: createAction,
    };
};

export default useCreatePaymentType;
