import { PAGE_TITLE_PRICE_TYPE_CREATE } from 'constants/pageTitles';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Box, Button, Stack, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import useCreatePriceType from 'shared/hook/priceTypes/create/useCreatePriceType';
import { useStore } from 'store';

import {
    CREATE_PRICE_TYPE_DEFAULT_VALUES,
    PRICE_TYPE_VALIDATION_SCHEMA, PriceTypeFormFields,
} from './components/formData';
import PriceTypeForm from './components/PriceTypeForm';

const Create = observer(() => {
    const { createPriceType } = useCreatePriceType();
    // TODO: loading перенести в хук и удалить из стора
    const { isPriceTypeLoading: isLoading } = useStore('priceTypes');
    const { setPageTitle } = useStore('pageTitleStore');

    useEffect(() => {
        setPageTitle(PAGE_TITLE_PRICE_TYPE_CREATE);
    }, []);

    const { control, handleSubmit } = useForm<PriceTypeFormFields>({
        defaultValues: CREATE_PRICE_TYPE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(PRICE_TYPE_VALIDATION_SCHEMA),
    });

    const handleCreatePriceType = (data: PriceTypeFormFields) => {
        createPriceType(data).then();
    };

    return (
        <Box p={ 3 }>
            <Typography variant="h5">Создание типа прайса</Typography>
            <form onSubmit={ handleSubmit(handleCreatePriceType) }>
                <PriceTypeForm control={ control } />
                <Stack direction="row" spacing={ 1 }>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        type="submit"
                        loading={ isLoading }
                    >
                        Создать
                    </LoadingButton>
                    <Link to="/price-type">
                        <Button variant="outlined">Отменить</Button>
                    </Link>
                </Stack>
            </form>
        </Box>
    );
});

export default Create;
