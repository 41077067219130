import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceSalesRoundFormFields } from 'page/PriceType/components/PriceSalesRound/components/formData';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { PriceSaleRoundRolePermissionDbColumns } from 'shared/graphql/mutation/priceSalesRound/PriceSalesRoundUpdate';
import { PriceSalesRoundRolePermissionT, PriceSalesRoundT } from 'types/PriceSalesRound';

const PriceSalesRoundRolePermissionsDTO = t.array(
    t.type({
        id: t.string,
        entry_individual_create: t.boolean,
        entry_individual_create_only_regular_schedule: t.boolean,
        entry_individual_add_coach: t.boolean,
        entry_individual_create_allowed_court_ids: t.array(t.string),
        entry_individual_cancel: t.boolean,
        entry_individual_transfer: t.boolean,
        entry_group_add: t.boolean,
        entry_group_cancel: t.boolean,
        entry_group_create: t.boolean,
        entry_group_update: t.boolean,
        role: t.string,
        entry_individual_create_lower_than_min_duration: Nullable(t.boolean),
    }),
);

const DTO = t.type({
    id: t.string,
    title: t.string,
    position: t.number,
    price_type_id: t.string,
    end_day: t.number,
    end_hour: t.number,
    end_month: t.number,
    end_week: t.number,
    start_day: t.number,
    start_hour: t.number,
    start_month: t.number,
    start_week: t.number,
    end_day_from_start: t.boolean,
    end_hour_from_start: t.boolean,
    end_month_from_start: t.boolean,
    end_week_from_start: t.boolean,
    start_day_from_start: t.boolean,
    start_hour_from_start: t.boolean,
    start_month_from_start: t.boolean,
    start_week_from_start: t.boolean,
    sale_rate_title: Nullable(t.string),
    return_rate_title: Nullable(t.string),
    club_return_rate_title: Nullable(t.string),
    min_rate_title: Nullable(t.string),
    transfer_title: Nullable(t.string),
    price_sale_round_role_permissions: PriceSalesRoundRolePermissionsDTO,
});

export type PriceSalesRoundDTOT = t.TypeOf<typeof DTO>;

export const PriceSalesRoundDTO = tPromise.extendDecoder<PriceSalesRoundDTOT, PriceSalesRoundT>(
    DTO,
    ({
        id,
        title,
        position,
        price_type_id,
        end_day,
        end_hour,
        end_month,
        end_week,
        start_day,
        start_hour,
        start_month,
        start_week,
        end_day_from_start,
        end_hour_from_start,
        end_month_from_start,
        end_week_from_start,
        start_day_from_start,
        start_hour_from_start,
        start_month_from_start,
        start_week_from_start,
        sale_rate_title,
        return_rate_title,
        club_return_rate_title,
        min_rate_title,
        transfer_title,
        price_sale_round_role_permissions,
    }) => ({
        id,
        title,
        position,
        priceTypeId: price_type_id,
        endDay: end_day,
        endHour: end_hour,
        endMonth: end_month,
        endWeek: end_week,
        startDay: start_day,
        startHour: start_hour,
        startMonth: start_month,
        startWeek: start_week,
        endDayFromStart: end_day_from_start,
        endHourFromStart: end_hour_from_start,
        endMonthFromStart: end_month_from_start,
        endWeekFromStart: end_week_from_start,
        startDayFromStart: start_day_from_start,
        startHourFromStart: start_hour_from_start,
        startMonthFromStart: start_month_from_start,
        startWeekFromStart: start_week_from_start,
        saleRateTitle: sale_rate_title || '',
        returnRateTitle: return_rate_title || '',
        clubReturnRateTitle: club_return_rate_title || '',
        minRateTitle: min_rate_title || '',
        transferTitle: transfer_title || '',
        priceSaleRoundRolePermissions: price_sale_round_role_permissions.map((rolePermission) => ({
            id: rolePermission.id,
            entryIndividualCreate: rolePermission.entry_individual_create,
            entryIndividualCreateOnlyRegularSchedule: rolePermission.entry_individual_create_only_regular_schedule,
            entryIndividualAddCoach: rolePermission.entry_individual_add_coach,
            entryIndividualCreateAllowedCourtIds: rolePermission.entry_individual_create_allowed_court_ids,
            entryIndividualCancel: rolePermission.entry_individual_cancel,
            entryIndividualTransfer: rolePermission.entry_individual_transfer,
            entryGroupAdd: rolePermission.entry_group_add,
            entryGroupCancel: rolePermission.entry_group_cancel,
            entryGroupCreate: rolePermission.entry_group_create,
            entryGroupUpdate: rolePermission.entry_group_update,
            entryGroupCancelClient: rolePermission.entry_group_cancel,
            role: rolePermission.role,
            entryIndividualCreateLowerThanMinDuration: rolePermission.entry_individual_create_lower_than_min_duration,
        })),
    }),
) as t.Type<PriceSalesRoundT, PriceSalesRoundDTOT>;

export const PriceSalesRoundEncode = (input: PriceSalesRoundFormFields) => {
    return {
        title: input.title,
        position: input.position,
        end_day: input.endDay,
        end_hour: input.endHour,
        end_month: input.endMonth,
        end_week: input.endWeek,
        start_day: input.startDay,
        start_hour: input.startHour,
        start_month: input.startMonth,
        start_week: input.startWeek,
        end_day_from_start: input.endDayFromStart,
        end_hour_from_start: input.endHourFromStart,
        end_month_from_start: input.endMonthFromStart,
        end_week_from_start: input.endWeekFromStart,
        start_day_from_start: input.startDayFromStart,
        start_hour_from_start: input.startHourFromStart,
        start_month_from_start: input.startMonthFromStart,
        start_week_from_start: input.startWeekFromStart,
        sale_rate_title: input.saleRateTitle || '',
        return_rate_title: input.returnRateTitle || '',
        club_return_rate_title: input.clubReturnRateTitle || '',
        min_rate_title: input.minRateTitle || '',
        transfer_title: input.transferTitle || '',
        price_sale_round_role_permissions: {
            data: input.priceSaleRoundRolePermissions.map((rolePermission: PriceSalesRoundRolePermissionT) => ({
                id: rolePermission.id,
                entry_individual_create: rolePermission.entryIndividualCreate,
                entry_individual_create_only_regular_schedule: rolePermission.entryIndividualCreateOnlyRegularSchedule,
                entry_individual_add_coach: rolePermission.entryIndividualAddCoach,
                entry_individual_create_allowed_court_ids: rolePermission.entryIndividualCreateAllowedCourtIds,
                entry_individual_cancel: rolePermission.entryIndividualCancel,
                entry_individual_transfer: rolePermission.entryIndividualTransfer,
                entry_group_add: rolePermission.entryGroupAdd,
                entry_group_cancel: rolePermission.entryGroupCancel,
                entry_group_create: rolePermission.entryGroupCreate,
                entry_group_update: rolePermission.entryGroupUpdate,
                entry_group_cancel_client: rolePermission.entryGroupCancel,
                role: rolePermission.role,
                entry_individual_create_lower_than_min_duration: rolePermission.entryIndividualCreateLowerThanMinDuration,
            })),
            on_conflict: {
                constraint: 'price_sale_round_role_permission_pkey',
                update_columns: Object.values(PriceSaleRoundRolePermissionDbColumns),
            },
        },
    };
};
