import { useMutation } from '@apollo/client';
import { camelizeKeys } from 'humps';
import {
    DELETE_MULTIPLE_PRICE_TYPE,
    DeleteMultiplePriceTypeResponseDataT, DeleteMultiplePriceTypeResponseT,
    DeleteMultiplePriceTypeVarT,
} from 'shared/graphql/mutation/priceType/PriceTypeMultipleDelete';
import { PriceTypeT } from 'types/PriceType';

import { PriceTypeMultipleDeleteDataT } from './PriceTypeMultipleDeleteData';

const usePriceTypeMultipleDelete = (): PriceTypeMultipleDeleteDataT => {
    const [deletePriceTypes, { loading: deleteLoading, error: errorDelete }] = useMutation<
        DeleteMultiplePriceTypeResponseT,
        DeleteMultiplePriceTypeVarT
    >(DELETE_MULTIPLE_PRICE_TYPE);

    const deleteAction = async (priceTypeIds: [PriceTypeT['id']]) => {
        try {
            const { data } = await deletePriceTypes({
                variables: {
                    ids: priceTypeIds,
                },
            });

            return camelizeKeys(data?.deleteMultiplePriceType) as DeleteMultiplePriceTypeResponseDataT;
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        loading: deleteLoading,
        deleteMultiplePriceType: deleteAction,
        error: errorDelete,
    };
};

export default usePriceTypeMultipleDelete;
