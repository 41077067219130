import { gql } from '@apollo/client';

import { PriceTypeCreateFields } from './PriceTypeCreate';

export type PriceTypeUpdateQueryPayloadT = PriceTypeCreateFields;

export type PriceTypeUpdateVar = {
    id: string;
    set: PriceTypeUpdateQueryPayloadT;
};

export type PriceTypeUpdateResponseDataT = {
    updatePriceType: {
        id: string;
    };
} | null | undefined;

export const PRICE_TYPE_UPDATE = gql`
    mutation UpdatePriceType($set: price_type_set_input = {}, $id: String = "") {
        updatePriceType: update_price_type_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
