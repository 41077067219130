import {
    ApolloClient, from, HttpLink, InMemoryCache, split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

export const createApolloClient = () => {
    const httpLink = new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_HTTP_BASE_URI as string,
        credentials: 'include',
    });

    const wsLink = new WebSocketLink({
        uri: process.env.REACT_APP_GRAPHQL_WS_BASE_URI as string,
        options: {
            reconnect: true,
            lazy: true,
        },
    });

    const chain = from([
        split(
            ({ query }) => {
                const definition = getMainDefinition(query);

                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            httpLink,
        ),
    ]);

    return new ApolloClient({
        link: chain,
        cache: new InMemoryCache({
            addTypename: false,
            typePolicies: {
                Query: {
                    fields: {
                        member_card_type_by_pk: {
                            // eslint-disable-next-line default-param-last
                            merge(existing = {}, incoming) {
                                // Merge the existing and incoming data
                                return {
                                    ...existing,
                                    ...incoming,
                                    member_card_type_discount_rates: incoming.member_card_type_discount_rates,
                                    member_card_type_price_season_prices: incoming.member_card_type_price_season_prices,
                                };
                            },
                        },
                    },
                },
            },
        }),
    });
};
