import {
    DATA_GRID_FILTER_MODEL_INITIAL_STATE,
    DATA_GRID_INITIAL_PAGE,
    DATA_GRID_SORT_MODEL_INITIAL_STATE,
} from 'constants/dataGrid';
import { PAGE_TITLE_PRICE_MEMBER_CARDS } from 'constants/pageTitles';
import { PRICE_MEMBER_CARDS } from 'constants/routes';

import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Spinner } from 'shared/component/Spinner';
import usePriceMemberCards from 'shared/data-hook/priceMemberCard/fetch/fetchAll/usePriceMemberCards';
import usePriceSalesRounds from 'shared/data-hook/priceSalesRound/fetch/fetchAll/usePriceSalesRounds';
import usePriceSeasons from 'shared/data-hook/priceSeason/fetch/fetchAll/usePriceSeasons';
import usePriceTypes from 'shared/data-hook/priceType/fetch/fetchAll/usePriceTypes';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import { useStore } from 'store';
import { PRICE_SEASONS_IS_ACTIVE } from 'types/PriceSeason';
import { PRICE_TYPES_IS_ACTIVE, PRICE_TYPES_POSITION_ASC } from 'types/PriceType';

import TabContext from './components/TabContext';
import UpdatePriceMemberCardModal from './components/UpdatePriceMemberCardModal';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const { id: priceSeasonId, priceTypeId } = useParams();
    const navigate = useNavigate();
    const [tab, setTab] = useState(priceTypeId ?? '');
    const { setPageTitle } = useStore('pageTitleStore');
    const { setPriceSalesRounds } = useStore('priceSalesRound');
    const {
        setPriceMemberCards, setPriceTypes, setPriceSeasons, activePriceSeason, setActivePriceSeason,
    } = useStore('priceMemberCards');
    const {
        fetchPriceMemberCards, data: priceMemberCardsData, count: rowCount, loading: priceMemberCardsLoading,
    } = usePriceMemberCards();
    const {
        data: priceTypesData, loading: priceTypesLoading, fetchPriceTypes,
    } = usePriceTypes();

    const { loading: priceSalesRoundsLoading, data: priceSalesRoundsData, fetchSalesRounds } = usePriceSalesRounds();
    const { loading: priceSeasonsLoading, data: priceSeasonsData, fetchPriceSeasons } = usePriceSeasons();
    const loading = priceSeasonsLoading || priceTypesLoading;

    const handleTabChange = (newTab: string) => {
        if (tab) {
            setPage(DATA_GRID_INITIAL_PAGE);
            setSortModel(DATA_GRID_SORT_MODEL_INITIAL_STATE);
            setFilterModel(DATA_GRID_FILTER_MODEL_INITIAL_STATE);
        }
        setTab(newTab);
    };

    useEffect(() => {
        fetchPriceSeasons({ where: PRICE_SEASONS_IS_ACTIVE }).then(() => {
            fetchPriceTypes({ orderBy: PRICE_TYPES_POSITION_ASC, where: PRICE_TYPES_IS_ACTIVE }).then();
        });
    }, []);

    useEffect(() => {
        setPriceSeasons(priceSeasonsData);
        if (priceSeasonsData.length > 0 && activePriceSeason.id === '') {
            const priceSeason = priceSeasonsData.find((item) => item.id === priceSeasonId) ?? priceSeasonsData[0];

            setActivePriceSeason(priceSeason);
        }
    }, [priceSeasonsLoading, priceSeasonsData]);

    useEffect(() => {
        setPriceTypes(priceTypesData);
    }, [priceTypesLoading, priceTypesData]);

    useEffect(() => {
        setPriceMemberCards(priceMemberCardsData, rowCount);
    }, [priceMemberCardsLoading, priceMemberCardsData]);

    useEffect(() => {
        setPriceSalesRounds(priceSalesRoundsData);
    }, [priceSalesRoundsData, priceSalesRoundsLoading]);

    useEffect(() => {
        if (!activePriceSeason.id) {
            return;
        }

        setPageTitle(`${PAGE_TITLE_PRICE_MEMBER_CARDS} — "${activePriceSeason.title}"`);
    }, [activePriceSeason]);

    useEffect(() => {
        if (!activePriceSeason.id) {
            return;
        }

        navigate(`${PRICE_MEMBER_CARDS}/${activePriceSeason.id}/${tab}`);
    }, [activePriceSeason, tab]);

    return (
        loading ? <Spinner /> : (
            <>
                <Helmet>
                    <title>
                        { `Прайс для ЧК "${activePriceSeason?.title}"` }
                    </title>
                </Helmet>
                <TabContext
                    tab={ tab }
                    fetchPriceMemberCards={ fetchPriceMemberCards }
                    handleTabChange={ handleTabChange }
                    loading={ loading }
                    pageSize={ pageSize }
                    page={ page }
                    sortModel={ sortModel }
                    setPageSize={ setPageSize }
                    setPage={ setPage }
                    setSortModel={ setSortModel }
                    filterModel={ filterModel }
                    setFilterModel={ setFilterModel }
                    fetchSalesRounds={ fetchSalesRounds }
                />
                <UpdatePriceMemberCardModal priceSeasonId={ activePriceSeason.id } priceTypeId={ tab } />
            </>
        )
    );
});

export default List;
