import { ERROR_DELETE } from 'constants/notificationMessage';

import usePriceSeasonMultipleDelete from 'shared/data-hook/priceSeason/delete/multiple/usePriceSeasonMultipleDelete';
import { useStore } from 'store';
import { PriceSeasonT } from 'types/PriceSeason';

import { useNotification } from '../../useNotification';

const useDeleteMultiplePriceSeason = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteMultiplePriceSeason: actionDelete } = usePriceSeasonMultipleDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const deleteMultiplePriceSeason = async (priceSeasonIds: [PriceSeasonT['id']]) => {
        const { affectedRows } = await actionDelete(priceSeasonIds);

        if (error || !affectedRows) {
            showError(ERROR_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(`Строк удалено: ${affectedRows}`);
        }
    };

    return {
        deleteMultiplePriceSeason,
    };
};

export default useDeleteMultiplePriceSeason;
