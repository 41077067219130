import { useMutation } from '@apollo/client';
import { PriceSalesRoundFormFields } from 'page/PriceType/components/PriceSalesRound/components/formData';
import {
    PRICE_SALES_ROUND_UPDATE,
    PriceSalesRoundDbColumns,
    PriceSalesRoundUpdateResponseDataT,
    PriceSalesRoundUpdateVar,
} from 'shared/graphql/mutation/priceSalesRound/PriceSalesRoundUpdate';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

import { PriceSalesRoundEncode } from '../fetch/DTO/PriceSalesRoundDTO';

import { PriceSalesRoundUpdateDataT } from './PriceSalesRoundUpdateData';

const usePriceSalesRoundUpdate = (): PriceSalesRoundUpdateDataT => {
    const [update, { loading: loadingUpdate, error: errorUpdate }] = useMutation<
        PriceSalesRoundUpdateResponseDataT,
        PriceSalesRoundUpdateVar
    >(PRICE_SALES_ROUND_UPDATE);

    const updateAction = async (
        input: PriceSalesRoundFormFields,
        priceSalesRoundId: PriceSalesRoundT['id'],
        priceTypeId: PriceSalesRoundT['priceTypeId'],
    ) => {
        if (!priceSalesRoundId) {
            // TODO: use Sentry
            throw new Error('Sales round is not found');
        }

        const transformedInput = PriceSalesRoundEncode(input);

        try {
            const { data } = await update({
                variables: {
                    onConflict: {
                        constraint: 'sales_round_pkey',
                        update_columns: Object.values(PriceSalesRoundDbColumns),
                    },
                    priceSalesRoundPayload: [
                        {
                            id: priceSalesRoundId,
                            price_type_id: priceTypeId,
                            ...transformedInput,
                        },
                    ],
                },
            });

            return data;
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        loading: loadingUpdate,
        error: errorUpdate,
        priceSalesRoundUpdate: updateAction,
    };
};

export default usePriceSalesRoundUpdate;
