import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    COACH_CATEGORIES_QUERY,
    CoachCategoriesResponseT,
} from 'shared/graphql/query/coachCategory/CoachCategoryQuery';
import { EMPTY_COACH_CATEGORIES_RESULT } from 'types/CoachCategory';
import { FetchDataParams } from 'types/DataGrid';

import { CoachCategoriesDTO } from '../DTO/CoachCategoriesDTO';

import { CoachCategoriesDataT } from './CoachCategoriesData';

const useCoachCategories = (): CoachCategoriesDataT => {
    const [fetch, { data: coachCategoriesData, loading }] = useLazyQuery<CoachCategoriesResponseT>(
        COACH_CATEGORIES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedCoachCategoriesData = decodeDTO(
        CoachCategoriesDTO,
        EMPTY_COACH_CATEGORIES_RESULT,
        (coachCategories) => coachCategories,
        {
            coachCategories: coachCategoriesData?.coachCategories,
            coachCategoriesCount: coachCategoriesData?.coachCategoriesCount,
        },
    );

    const fetchCoachCategories = async ({
        limit,
        offset,
        orderBy = {},
        where = {
            items: [],
            linkOperator: 'and',
        },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedCoachCategoriesData?.coachCategories,
        count: decodedCoachCategoriesData?.coachCategoriesCount,
        fetchCoachCategories,
        loading,
    };
};

export default useCoachCategories;
