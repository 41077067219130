import {
    ERROR_PRICE_SALES_ROUND_DELETE,
    SUCCESS_PRICE_SALES_ROUND_DELETE,
} from 'constants/notificationMessage';

import usePriceSalesRoundDelete from 'shared/data-hook/priceSalesRound/delete/usePriceSalesRoundDelete';
import { useStore } from 'store';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

import { useNotification } from '../../useNotification';

const useDeletePriceSalesRound = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deletePriceSalesRound } = usePriceSalesRoundDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const priceSalesRoundDelete = async (priceSalesRoundId: PriceSalesRoundT['id']) => {
        await deletePriceSalesRound(priceSalesRoundId);
        if (error) {
            showError(ERROR_PRICE_SALES_ROUND_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PRICE_SALES_ROUND_DELETE);
        }
    };

    return {
        priceSalesRoundDelete,
    };
};

export default useDeletePriceSalesRound;
