import { ERROR_PRICE_UPDATE, SUCCESS_PRICE_UPDATE } from 'constants/notificationMessage';

import { PriceFormFields } from 'page/Price/components/UpdatePriceModal/formData';
import usePriceUpdate from 'shared/data-hook/price/update/usePriceUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { PriceT } from 'types/Price';

const useUpdatePrice = () => {
    const { error, loading, priceUpdate } = usePriceUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updatePrice = async (input: PriceFormFields, priceId: PriceT['id']) => {
        const response = await priceUpdate(input, priceId);

        if (error || !response || !response.updatePrice) {
            showError(ERROR_PRICE_UPDATE);
        } else {
            showSuccess(SUCCESS_PRICE_UPDATE);
        }

        toggleRefetchTrigger();
    };

    return {
        updatePrice,
        loading,
    };
};

export default useUpdatePrice;
