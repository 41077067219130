import { EMPTY_CELL } from 'constants/dataGrid';
import { DATE_TIME_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    IMPLEMENTATION_DOCUMENT_TYPE_DICTIONARY,
    IMPLEMENTATION_DOCUMENT_TYPE_OPTIONS,
} from 'constants/implementationDocumentTypes';
import {
    PROPERTY_CATEGORY,
    PROPERTY_CLIENT_FIRST_NAME,
    PROPERTY_CLIENT_LAST_NAME, PROPERTY_CLIENT_MIDDLE_NAME, PROPERTY_COACHES,
    PROPERTY_DOCUMENT_NUMBER, PROPERTY_END, PROPERTY_GROUP, PROPERTY_SEASON, PROPERTY_START, PROPERTY_TYPE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { Chip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FlexWrapBox } from 'shared/component/DataGrid/domain/Styled';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

const renderCoaches: FC<GridRenderCellParams> = (props) => {
    const { row } = props;
    const { getFilterOption } = useStore('filterOptions');
    const coachFilterOptions = getFilterOption('coach');

    if (row.coachesIds.length === 0) {
        return <span>{ EMPTY_CELL }</span>;
    }

    return (
        <FlexWrapBox>
            {
                row.coachesIds.map((coachId: string) => {
                    const coachName = coachFilterOptions.find((item) => item.value === coachId);

                    if (coachName) {
                        const { value, label } = coachName;

                        return <Chip label={ label } variant="outlined" color="primary" size="small" key={ `${row.id}-${value}` } />;
                    }

                    return null;
                })
            }
        </FlexWrapBox>
    );
};

export const SCHEDULE_SLOT_RESERVE_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_DOCUMENT_NUMBER,
        field: 'implementationDocumentNumber',
        minWidth: 150,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['implementation_document', 'number'],
        hasuraSortFields: ['implementation_document', 'number'],
    },
    {
        headerName: PROPERTY_SEASON,
        field: 'priceSeasonTitle',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        type: 'string',
        hasuraFilterFields: ['price_season', 'title'],
        hasuraSortFields: ['price_season', 'title'],
    },
    {
        headerName: PROPERTY_COACHES,
        field: 'coachesIds',
        minWidth: 300,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        renderCell: renderCoaches,
        hasuraFilterFields: ['coaches_ids'],
        hasuraSortFields: ['coaches_ids'],
    },
    {
        headerName: PROPERTY_START,
        minWidth: 200,
        field: 'datetimeStart',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        type: 'date',
        renderCell: (params) => renderDateToGrid(params.row?.datetimeStart, DATE_TIME_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_END,
        minWidth: 200,
        field: 'datetimeEnd',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        type: 'date',
        renderCell: (params) => renderDateToGrid(params.row?.datetimeEnd, DATE_TIME_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_CLIENT_LAST_NAME,
        field: 'clientLastName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.lastName),
        hasuraFilterFields: ['implementation_document', 'client_datum', 'client', 'last_name'],
        hasuraSortFields: ['implementation_document', 'client_datum', 'client', 'last_name'],
    },
    {
        headerName: PROPERTY_CLIENT_FIRST_NAME,
        field: 'clientFirstName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.firstName),
        hasuraFilterFields: ['implementation_document', 'client_datum', 'client', 'first_name'],
        hasuraSortFields: ['implementation_document', 'client_datum', 'client', 'first_name'],
    },
    {
        headerName: PROPERTY_CLIENT_MIDDLE_NAME,
        field: 'clientMiddleName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.middleName),
        hasuraFilterFields: ['implementation_document', 'client_datum', 'client', 'middle_name'],
        hasuraSortFields: ['implementation_document', 'client_datum', 'client', 'middle_name'],
    },
    {
        headerName: PROPERTY_TYPE,
        field: 'type',
        flex: 0.5,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        valueOptions: IMPLEMENTATION_DOCUMENT_TYPE_OPTIONS,
        hasuraFilterFields: ['implementation_document', 'type'],
        hasuraSortFields: ['implementation_document', 'type'],
        renderCell: (params) => <span>{ IMPLEMENTATION_DOCUMENT_TYPE_DICTIONARY[params.row?.type] }</span>,
    },
    {
        headerName: PROPERTY_CATEGORY,
        field: 'serviceCategory',
        flex: 1,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['implementation_document', 'service_category', 'id'],
        hasuraSortFields: ['implementation_document', 'service_category', 'name'],
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.serviceCategory),
    },
    {
        headerName: PROPERTY_GROUP,
        field: 'group',
        flex: 1,
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['implementation_document', 'group', 'id'],
        hasuraSortFields: ['implementation_document', 'group', 'name'],
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.group),
    },
];
