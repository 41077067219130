import { NOTIFICATION_TEXT_REQUIRED } from 'constants/notificationsText';

import { getValidationTimePeriod } from 'shared/validation/getValidationTimePeriod';
import { object, string } from 'yup';

export type PriceFormFields = {
    day: number;
    timeStart: string;
    timeEnd: string;
    category: string;
    courtType: string;
}

export const PRICE_DEFAULT_VALUES: PriceFormFields = {
    day: 1,
    timeStart: '',
    timeEnd: '',
    category: '',
    courtType: '',
};

export enum PRICE_FIELDS_KEYS {
    day = 'day',
    timeStart = 'timeStart',
    timeEnd = 'timeEnd',
    categoryId = 'category',
    courtTypeId = 'courtType',
}

export const getPriceValidationSchema = object().shape({
    category: string().required(NOTIFICATION_TEXT_REQUIRED),
    courtType: string().required(NOTIFICATION_TEXT_REQUIRED),
}).concat(getValidationTimePeriod);
