import { makeAutoObservable } from 'mobx';
import { CoachT } from 'types/Coach';

export default class CoachStore {
    public coachList: CoachT[] = [];

    public coach: CoachT | undefined = undefined;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get coaches(): CoachT[] {
        return this.coachList;
    }

    public setCoach(coach: CoachT) {
        this.coach = coach;
    }
}
