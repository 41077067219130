import { useMutation } from '@apollo/client';
import { camelizeKeys } from 'humps';
import { UpdatePriceRateFormFields } from 'page/Price/components/UpdatePriceRate/formData';
import {
    EMPTY_UPDATE_PRICE_RATE_RESPONSE,
    UPDATE_PRICE_RATE, UpdatePriceRateResponseT,
    UpdatePriceRateT,
    UpdatePriceRateVarT,
} from 'shared/graphql/mutation/price/UpdatePriceRateAction';
import { useStore } from 'store';

import { UpdatePriceRateDTO } from '../DTO/UpdatePriceRateDTO';

import { UpdatePriceRateDataT } from './PriceUpdateRateData';

const usePriceUpdateRate = (): UpdatePriceRateDataT => {
    const [update, { loading, error }] = useMutation<UpdatePriceRateT, UpdatePriceRateVarT>(
        UPDATE_PRICE_RATE,
    );

    const { priceSeason, activePriceType } = useStore('price');
    const { serviceApp } = useStore('serviceApp');

    const priceUpdateRate = async (rateData: UpdatePriceRateFormFields) => {
        const priceSeasonId = priceSeason?.id || '';
        const priceTypeId = activePriceType?.id || '';
        const serviceAppFiat = serviceApp.fiatCurrencyTitle;

        try {
            const { data } = await update({
                variables: {
                    appId: serviceApp.id,
                    payload: UpdatePriceRateDTO.encode({
                        ...rateData, priceSeasonId, priceTypeId, serviceAppFiat,
                    }),
                },
            });

            return camelizeKeys(data?.priceMultipleUpdateRate) as UpdatePriceRateResponseT;
        } catch {
            return EMPTY_UPDATE_PRICE_RATE_RESPONSE;
        }
    };

    return {
        priceUpdateRate,
        loading,
        error,
    };
};

export default usePriceUpdateRate;
