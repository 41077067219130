import { PAGE_TITLE_PRICE_SEASON_UPDATE } from 'constants/pageTitles';

import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Box, Button, Skeleton, Stack, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import NoResult from 'shared/component/NoResult';
import useFetchPriceSeason from 'shared/hook/priceSeason/fetch/fetchOne/useFetchPriceSeason';
import useUpdatePriceSeason from 'shared/hook/priceSeason/update/useUpdatePriceSeason';
import { useStore } from 'store';

import {
    PRICE_SEASON_DEFAULT_VALUES, PRICE_SEASON_VALIDATION_SCHEMA,
    SeasonFormFields,
} from './components/formData';
import PriceSeasonForm from './components/PriceSeasonForm';

const Update = observer(() => {
    const params = useParams();
    const { fetch: fetchSeason, loading: loadingFetch } = useFetchPriceSeason();
    const { setPageTitle } = useStore('pageTitleStore');

    useEffect(() => {
        setPageTitle(PAGE_TITLE_PRICE_SEASON_UPDATE);
    }, []);

    if (!params.id) {
        return <NoResult />;
    }

    fetchSeason(params.id);

    const { updatePriceSeason, loading: loadingSave } = useUpdatePriceSeason();
    const { priceSeason } = useStore('priceSeason');

    const {
        control, handleSubmit, setValue,
    } = useForm<SeasonFormFields>({
        defaultValues: PRICE_SEASON_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(PRICE_SEASON_VALIDATION_SCHEMA),
    });

    const priceSeasonValues: SeasonFormFields = useMemo(() => {
        const values = PRICE_SEASON_DEFAULT_VALUES;

        if (priceSeason) {
            Object.keys(PRICE_SEASON_DEFAULT_VALUES).forEach((key) => {
                values[key as keyof SeasonFormFields] = priceSeason[
                    key as keyof SeasonFormFields
                ] as never;
            });
        }

        return values;
    }, [priceSeason]);
    const {
        title, startAt, finishAt, isActive,
    } = priceSeasonValues;

    useEffect(() => {
        Object.keys(PRICE_SEASON_DEFAULT_VALUES).forEach((key) => {
            setValue(
                key as keyof SeasonFormFields,
                priceSeasonValues[key as keyof SeasonFormFields],
            );
        });
    }, [title, startAt, finishAt, isActive]);

    const handleUpdateSeason = (data: SeasonFormFields) => {
        updatePriceSeason(data, priceSeason?.id as string).then();
    };

    if (!priceSeason && !loadingFetch) {
        return <NoResult />;
    }

    return (
        <Box p={ 3 }>
            <Typography component="div" variant="h5">
                <Box sx={ { display: 'flex', alignItems: 'center' } }>
                    {
                        loadingFetch ? (<Skeleton width={ 300 } height={ 32 } />) : (<>Редактирование { title }</>)
                    }
                </Box>
            </Typography>
            <form onSubmit={ handleSubmit(handleUpdateSeason) }>
                <PriceSeasonForm control={ control } loading={ loadingFetch } setValue={ setValue } />
                <Stack direction="row" spacing={ 1 }>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        type="submit"
                        loading={ loadingSave }
                    >
                        Сохранить
                    </LoadingButton>
                    <Link to="/price-season">
                        <Button
                            variant="outlined"
                        >
                            Отменить
                        </Button>
                    </Link>
                </Stack>
            </form>
        </Box>
    );
});

export default Update;
