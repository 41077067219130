import { PaymentTypeT } from './PaymentType';

export type PaymentMethodT = {
    id: string;
    title: string;
    position: number;
    isActive: boolean;
    paymentTypes: PaymentTypeT[];
};

export type PaymentMethodsT = {
    paymentMethods: PaymentMethodT[];
    paymentMethodsCount: number;
}

export const EMPTY_PAYMENT_METHODS: PaymentMethodsT = {
    paymentMethods: [],
    paymentMethodsCount: 0,
};

export const EMPTY_PAYMENT_METHOD_RESULT = {
    id: '',
    title: '',
    position: 0,
    isActive: false,
    paymentTypes: [],
};
