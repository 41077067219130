import * as React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import {
    Box, Checkbox,
    InputLabel,
    Skeleton,
    Slider as BaseSlider,
} from '@mui/material';

type PropsT = {
    label?: string;
    disabled: boolean;
    setDisabled: (disabled: boolean) => void;
    labelSize?: number;
};

export const Slider = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control, name, label, loading, disabled, setDisabled, labelSize = 16,
    } = props;

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({ field }) => (
                <Box sx={ { marginTop: '-12px' } }>
                    { label && (
                        <InputLabel sx={ { position: 'relative', top: '6px', fontSize: `${labelSize}px` } }>{ label } <Checkbox
                            size="small"
                            value={ disabled }
                            checked={ disabled }
                            onChange={ () => setDisabled(!disabled) }
                        />
                        </InputLabel>
                    ) }
                    { loading ? <Skeleton variant="rounded" height={ 38 } />
                        : (
                            <BaseSlider
                                { ...field }
                                disabled={ !disabled }
                                valueLabelDisplay="auto"
                                defaultValue={ 50 }
                            />
                        ) }
                </Box>
            ) }
        />
    );
};
