import {
    DASHBOARD,
} from 'constants/routes';

import React, { FC, memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, List } from '@mui/material';
import { LogoIcon } from 'shared/styled/styledMenuGroup';
import { MenuItemT, PathT } from 'types/MenuItems';

import NestedList from './components/NestedList';
import { MENU_ITEMS } from './domain/MenuItems';
import { StyledDrawer, StyledDrawerToolbar } from './domain/Styled';

type PropsT = {
    path: string;
}

const Drawer: FC<PropsT> = (props) => {
    const { path } = props;
    const getPageId = (): number | null => {
        if (path === '/') {
            return null;
        }

        return MENU_ITEMS.filter(({ links, rootLink }) =>
            links?.find((link: PathT) =>
                link.path === path) || rootLink === path)[0]?.id;
    };

    const [openList, setOpenList] = useState<number | null>(getPageId);

    return (
        <StyledDrawer variant="permanent" anchor="left">
            <StyledDrawerToolbar>
                <Link to={ DASHBOARD }>
                    <LogoIcon />
                </Link>
            </StyledDrawerToolbar>
            <Divider />
            <List>
                {
                    MENU_ITEMS.map(({
                        links, header, rootLink, id,
                    }: MenuItemT) => (
                        <NestedList
                            openList={ openList }
                            setOpenList={ setOpenList }
                            listItems={ links || [] }
                            headerList={ header }
                            headerLink={ rootLink }
                            key={ id }
                            id={ id }
                        />
                    ))
                }
            </List>
        </StyledDrawer>
    );
};

export default memo(Drawer);
