import { useEffect } from 'react';
import usePaymentType from 'shared/data-hook/paymentType/fetch/fetchOne/usePaymentType';
import { useStore } from 'store';

const useFetchPaymentType = () => {
    const { fetchPaymentType, loading } = usePaymentType();
    const { updatedItemId, setPaymentType } = useStore('updatePaymentType');

    useEffect(() => {
        if (!updatedItemId) {
            return;
        }

        fetchPaymentType(updatedItemId).then((paymentType) => {
            setPaymentType(paymentType);
        });
    }, [updatedItemId]);

    return {
        loading,
    };
};

export default useFetchPaymentType;
