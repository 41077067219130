import { useMutation } from '@apollo/client';
import { CourtCategoryFormFields } from 'page/CourtCategory/components/formData';
import { CreateCourtCategoryDataT } from 'shared/data-hook/courtCategory/create/CreateCourtCategoryData';
import {
    COURT_CATEGORY_CREATE,
    CourtCategoryCreateResponseDataT, CourtCategoryCreateVarT,
} from 'shared/graphql/mutation/courtCategory/courtCategoryCreate';

const useCourtCategoryCreate = (): CreateCourtCategoryDataT => {
    const [create, { loading, error }] = useMutation<CourtCategoryCreateResponseDataT, CourtCategoryCreateVarT>(
        COURT_CATEGORY_CREATE,
    );

    const createAction = async (input: CourtCategoryFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        name: input.name,
                        position: input.position,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        loading,
        error,
        courtCategoryCreate: createAction,
    };
};

export default useCourtCategoryCreate;
