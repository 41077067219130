import { PROPERTY_ACTIONS, PROPERTY_TITLE } from 'constants/propertyNames';

import React, { FC, MouseEventHandler } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeleteCoachCategory from 'shared/hook/coachCategory/delete/useDeleteCoachCategory';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, title } } = props;
    const { onOpen } = useStore('updateCoachCategoryModal');
    const { coachCategoryDelete } = useDeleteCoachCategory();

    const handleOpenClick: MouseEventHandler<HTMLButtonElement> = () => {
        onOpen(id);
    };

    return (
        <>
            <IconButton onClick={ handleOpenClick }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ title } actionDelete={ coachCategoryDelete } />
        </>
    );
};

export const COACH_CATEGORY_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 0.1,
        align: 'center',
        headerAlign: 'center',
    },
];
