import { gql } from '@apollo/client';
import { ServiceCategoryCreatePayloadT } from 'shared/graphql/mutation/serviceCategory/ServiceCategoryCreate';

export type ServiceCategoryUpdateQueryPayloadT = Omit<ServiceCategoryCreatePayloadT, 'serviceType'>;

export type ServiceCategoryUpdateVar = {
    id: string;
    set: ServiceCategoryUpdateQueryPayloadT;
};

export type ServiceCategoryUpdateResponseDataT = {
    updateServiceCategory: {
        id: string;
    };
} | null | undefined;

export const SERVICE_CATEGORY_UPDATE = gql`
    mutation updateServiceCategory($set: service_category_set_input = {}, $id: String = "") {
        updateServiceCategory: update_service_category_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
