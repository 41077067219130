import { ERROR_PAYMENT_METHOD_UPDATE, SUCCESS_PAYMENT_METHOD_UPDATE } from 'constants/notificationMessage';

import { PaymentMethodFormFields } from 'page/PaymentSetting/components/formData';
import usePaymentMethodUpdate from 'shared/data-hook/paymentMethod/update/usePaymentMethodUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { PaymentMethodT } from 'types/PaymentMethod';

const useUpdatePaymentMethod = () => {
    const { showError, showSuccess } = useNotification();
    const { error, loading, paymentMethodUpdate } = usePaymentMethodUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updatePaymentMethod = async (data: PaymentMethodFormFields, paymentMethodId: PaymentMethodT['id']) => {
        const response = await paymentMethodUpdate(data, paymentMethodId);

        if (error || !response || !response.updatePaymentMethod) {
            showError(ERROR_PAYMENT_METHOD_UPDATE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PAYMENT_METHOD_UPDATE);
        }
    };

    return {
        updatePaymentMethod,
        loading,
    };
};

export default useUpdatePaymentMethod;
