import { useMemo } from 'react';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

import { PAYMENT_DOCUMENT_HEADER_DATA } from './HeaderData';

export const preparePaymentDocumentHeaderData = (organizations: FilterOptionT[]) =>
    useMemo(() => mapFields(PAYMENT_DOCUMENT_HEADER_DATA, {
        organization: organizations,
    }), [organizations]);
