import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { ServiceCategoriesT } from 'types/ServiceCategory';

import { ServiceCategoryDTO } from './ServiceCategoryDTO';

const DTO = t.type({
    serviceCategories: t.array(ServiceCategoryDTO),
    serviceCategoriesCount: AggregateDTO,
});

export type ServiceCategoriesDTOT = t.TypeOf<typeof DTO>;

export const ServiceCategoriesDTO = tPromise.extendDecoder<ServiceCategoriesDTOT, ServiceCategoriesT>(
    DTO,
    ({
        serviceCategories,
        serviceCategoriesCount,
    }) => ({
        serviceCategories,
        serviceCategoriesCount,
    }),
) as t.Type<ServiceCategoriesT, ServiceCategoriesDTOT>;
