import { PAGE_TITLE_PRICE_TYPE_UPDATE } from 'constants/pageTitles';

import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Box, Button, Skeleton, Stack, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import NoResult from 'shared/component/NoResult';
import useFetchPriceType from 'shared/hook/priceTypes/fetch/fetchOne/useFetchPriceType';
import useUpdatePriceType from 'shared/hook/priceTypes/update/useUpdatePriceType';
import { useStore } from 'store';

import {
    PRICE_TYPE_DEFAULT_VALUES,
    PRICE_TYPE_VALIDATION_SCHEMA, PriceTypeFormFields,
} from './components/formData';
import PriceTypeForm from './components/PriceTypeForm';

const Update = observer(() => {
    const params = useParams();
    const { fetch: fetchPriceType, loading: loadingFetch } = useFetchPriceType();
    const { setPageTitle } = useStore('pageTitleStore');

    useEffect(() => {
        setPageTitle(PAGE_TITLE_PRICE_TYPE_UPDATE);
    }, []);

    if (!params.id) {
        return <NoResult />;
    }

    fetchPriceType(params.id);

    const { updatePriceType } = useUpdatePriceType();
    const { priceType, isPriceTypeLoading: loadingSave } = useStore('priceTypes');

    const { control, handleSubmit, setValue } = useForm<PriceTypeFormFields>({
        defaultValues: PRICE_TYPE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(PRICE_TYPE_VALIDATION_SCHEMA),
    });

    const priceTypeInfo: PriceTypeFormFields = useMemo(() => {
        const info = PRICE_TYPE_DEFAULT_VALUES;

        if (priceType) {
            Object.keys(PRICE_TYPE_DEFAULT_VALUES).forEach((key) => {
                info[key as keyof PriceTypeFormFields] = priceType[
                    key as keyof PriceTypeFormFields
                ] as never;
            });
        }

        return info;
    }, [priceType]);
    const {
        title, position, isActive,
    } = priceTypeInfo;

    useEffect(() => {
        Object.keys(PRICE_TYPE_DEFAULT_VALUES).forEach((key) => {
            setValue(
                key as keyof PriceTypeFormFields,
                priceTypeInfo[key as keyof PriceTypeFormFields],
            );
        });
    }, [title, position, isActive]);

    const handleUpdatePriceType = (data: PriceTypeFormFields) => {
        updatePriceType(data, priceType).then();
    };

    if (!priceType && !loadingFetch) {
        return <NoResult />;
    }

    return (
        <Box p={ 3 }>
            <Typography component="div" variant="h5">
                <Box sx={ { display: 'flex', alignItems: 'center' } }>
                    {
                        loadingFetch ? (<Skeleton width={ 300 } height={ 32 } />) : (<>Редактирование { title }</>)
                    }
                </Box>
            </Typography>
            <form onSubmit={ handleSubmit(handleUpdatePriceType) }>
                <PriceTypeForm control={ control } loading={ loadingFetch } />
                <Stack direction="row" spacing={ 1 }>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={ loadingSave }
                    >
                        Сохранить
                    </LoadingButton>
                    <Link to="/price-type">
                        <Button variant="outlined">Отменить</Button>
                    </Link>
                </Stack>
            </form>
        </Box>
    );
});

export default Update;
