import {
    ERROR_PRICE_SEASON_DUPLICATE,
    SUCCESS_PRICE_SEASON_DUPLICATE,
} from 'constants/notificationMessage';

import usePriceSeasonDuplicate from 'shared/data-hook/priceSeason/duplicate/usePriceSeasonDuplicate';
import { useStore } from 'store';
import { PriceSeasonT } from 'types/PriceSeason';

import { useNotification } from '../../useNotification';

const useDuplicatePriceSeason = () => {
    const { showError, showSuccess } = useNotification();
    const { duplicatePriceSeason } = usePriceSeasonDuplicate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const priceSeasonDuplicate = async (priceSeasonId: PriceSeasonT['id']) => {
        const { status, reason } = await duplicatePriceSeason(priceSeasonId);
        const hasError = status === 'error';

        if (hasError) {
            showError(reason || ERROR_PRICE_SEASON_DUPLICATE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PRICE_SEASON_DUPLICATE);
        }
    };

    return {
        priceSeasonDuplicate,
    };
};

export default useDuplicatePriceSeason;
