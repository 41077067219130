import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { CoachCategoriesT } from 'types/CoachCategory';

import { CoachCategoryDTO } from './CoachCategory';

const DTO = t.type({
    coachCategories: t.array(CoachCategoryDTO),
    coachCategoriesCount: AggregateDTO,
});

type CoachCategoriesDTOT = t.TypeOf<typeof DTO>;

export const CoachCategoriesDTO = tPromise.extendDecoder<CoachCategoriesDTOT, CoachCategoriesT>(
    DTO,
    ({
        coachCategories,
        coachCategoriesCount,
    }) => ({
        coachCategories,
        coachCategoriesCount,
    }),
) as t.Type<CoachCategoriesT, CoachCategoriesDTOT>;
