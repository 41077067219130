import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PaymentMethodsT } from 'types/PaymentMethod';

import { PaymentMethodDTO } from './PaymentMethodDTO';

const DTO = t.type({
    paymentMethods: t.array(PaymentMethodDTO),
    paymentMethodsCount: AggregateDTO,
});

type PaymentMethodsDTOT = t.TypeOf<typeof DTO>;

export const PaymentMethodsDTO = tPromise.extendDecoder<PaymentMethodsDTOT, PaymentMethodsT>(
    DTO,
    ({
        paymentMethods,
        paymentMethodsCount,
    }) => ({
        paymentMethods,
        paymentMethodsCount,
    }),
) as t.Type<PaymentMethodsT, PaymentMethodsDTOT>;
