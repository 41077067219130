import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import Price from '../Price';

import Create from './Create';
import List from './List';
import Update from './Update';

const Season: FC = () => {
    return (
        <Routes>
            <Route path="/" element={ <List /> } />
            <Route path="create" element={ <Create /> } />
            <Route path="edit/:id" element={ <Update /> } />
            <Route path=":id/*" element={ <Price /> } />
            <Route path=":id/:priceTypeId/*" element={ <Price /> } />
        </Routes>
    );
};

export default Season;
