import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useServiceCategories from 'shared/data-hook/serviceCategory/fetch/fetchAll/useServiceCategories';
import useServiceCategoryCreate from 'shared/hook/serviceCategory/create/useServiceCategoryCreate';
import { useStore } from 'store';

import {
    getServiceCategoryValidationSchema,
    SERVICE_CATEGORY_DEFAULT_VALUES,
    SERVICE_CATEGORY_FIELDS_KEYS,
    ServiceCategoryFormFields,
} from './formData';

const ServiceCategoryCreateModal: FC = observer(() => {
    const { isOpen, onClose, setServiceCategories } = useStore('createServiceCategoryModal');
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const {
        fetchServiceCategories,
        data: serviceCategoriesData,
        loading: serviceCategoriesLoading,
    } = useServiceCategories();
    const { control, handleSubmit, reset } = useForm<ServiceCategoryFormFields>({
        defaultValues: SERVICE_CATEGORY_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getServiceCategoryValidationSchema()),
    });

    const { createServiceCategory, loading: loadingSave } = useServiceCategoryCreate();
    const loadingFetch = serviceCategoriesLoading;

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        fetchServiceCategories().then();
    }, [isOpen]);

    useEffect(() => {
        if (serviceCategoriesData) {
            setServiceCategories(serviceCategoriesData);
        }
    }, [serviceCategoriesData]);

    const handleCreateServiceCategory = (data: ServiceCategoryFormFields) => {
        createServiceCategory(data).then(() => {
            handleCloseModal();
            toggleRefetchTrigger();
        });
    };

    return (
        <Modal maxWidth="lg" open={ isOpen }>
            <ModalTitle onClose={ handleCloseModal }>Создание категории</ModalTitle>
            <DialogContent dividers={ true }>
                {
                    loadingFetch && <Spinner />
                }
                <Grid2 spacing={ 2 } container padding={ 2 }>
                    <Grid2 xs={ 12 }>
                        <TextField
                            control={ control }
                            name={ SERVICE_CATEGORY_FIELDS_KEYS.name as keyof ServiceCategoryFormFields }
                            label="Название"
                            type="text"
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <TextField
                            control={ control }
                            name={ SERVICE_CATEGORY_FIELDS_KEYS.color as keyof ServiceCategoryFormFields }
                            label="Цвет"
                            type="text"
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <TextField
                            control={ control }
                            name={ SERVICE_CATEGORY_FIELDS_KEYS.minDuration as keyof ServiceCategoryFormFields }
                            label="Мин. длительность"
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <TextField
                            control={ control }
                            name={ SERVICE_CATEGORY_FIELDS_KEYS.minAdditionalDuration as keyof ServiceCategoryFormFields }
                            label="Мин. доп. длительность"
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <TextField
                            control={ control }
                            name={ SERVICE_CATEGORY_FIELDS_KEYS.position as keyof ServiceCategoryFormFields }
                            label="Позиция"
                            type="number"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ loadingSave }
                    onClick={ handleSubmit(handleCreateServiceCategory) }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default ServiceCategoryCreateModal;
