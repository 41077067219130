import { useMutation } from '@apollo/client';
import { PriceFormFields } from 'page/Price/components/UpdatePriceModal/formData';
import { UPDATE_PRICE, UpdatePriceResponseDataT, UpdatePriceVarT } from 'shared/graphql/mutation/price/UpdatePrice';
import { PriceT } from 'types/Price';

import { PriceUpdateDataT } from './PriceUpdateData';

const usePriceUpdate = (): PriceUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        UpdatePriceResponseDataT,
        UpdatePriceVarT
    >(UPDATE_PRICE);

    const priceUpdate = async (
        input: PriceFormFields,
        priceId: PriceT['id'],
    ) => {
        if (!priceId) {
            throw new Error('Price id is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: priceId,
                    set: {
                        day: input.day,
                        time_start: input.timeStart,
                        time_end: input.timeEnd,
                        category_id: input.category,
                        court_type_id: input.courtType,
                    },
                },
            });

            return data;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error((err as Error).message); // TODO: use Sentry
            throw err;
        }
    };

    return {
        priceUpdate,
        loading,
        error,
    };
};

export default usePriceUpdate;
