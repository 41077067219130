export const TIME_STEPS = [
    {
        label: '15 минут',
        value: '15',
    },
    {
        label: '30 минут',
        value: '30',
    },
];
