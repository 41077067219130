import { gql } from '@apollo/client';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

export type PriceSalesRoundResponseT = {
    priceSalesRound: PriceSalesRoundT;
};

export type PriceSalesRoundsResponseT = {
    priceSalesRounds: PriceSalesRoundT[];
};

export type PriceSalesRoundsFilterT = {
    priceTypeId?: string;
};

export type PriceSalesRoundsQueryVarT = {
    where: any;
};

export const PRICE_SALES_ROUND_FILTER_OPTIONS_QUERY = gql`
    query PriceSalesRoundFilterOptions($filter: price_sales_round_bool_exp) {
        filterOptions: price_sales_round(
            where: $filter
            order_by: { title: asc }
        ) {
            value: id
            label: title
            additional: price_type {
                label: title
            }
        }
    }
`;

export const PRICE_SALES_ROUND_QUERY = gql`
    query PriceSalesRound($id: String!) {
        priceSalesRound: price_sales_round_by_pk(id: $id) {
            id
            position
            title
            price_type_id
            end_day
            end_hour
            end_month
            end_week
            start_day
            start_hour
            start_month
            start_week
            end_day_from_start
            end_hour_from_start
            end_month_from_start
            end_week_from_start
            start_day_from_start
            start_hour_from_start
            start_month_from_start
            start_week_from_start
            sale_rate_title
            return_rate_title
            club_return_rate_title
            min_rate_title
            transfer_title
            price_sale_round_role_permissions {
                id
                entry_individual_create
                entry_individual_create_only_regular_schedule
                entry_individual_add_coach
                entry_individual_create_allowed_court_ids
                entry_individual_cancel
                entry_individual_transfer
                entry_group_add
                entry_group_cancel
                entry_group_create
                entry_group_update
                entry_group_cancel_client
                role
                entry_individual_create_lower_than_min_duration
            }
        }
    }
`;

export const PRICE_SALES_ROUNDS_QUERY = gql`
    query PriceSalesRound($where: price_sales_round_bool_exp! = {}) {
        priceSalesRounds: price_sales_round(where: $where, order_by: {position: asc}) {
            id
            position
            title
            price_type_id
            end_day
            end_hour
            end_month
            end_week
            start_day
            start_hour
            start_month
            start_week
            end_day_from_start
            end_hour_from_start
            end_month_from_start
            end_week_from_start
            start_day_from_start
            start_hour_from_start
            start_month_from_start
            start_week_from_start
            sale_rate_title
            return_rate_title
            club_return_rate_title
            min_rate_title
            transfer_title
            price_sale_round_role_permissions {
                id
                entry_individual_create
                entry_individual_create_only_regular_schedule
                entry_individual_add_coach
                entry_individual_create_allowed_court_ids
                entry_individual_cancel
                entry_individual_transfer
                entry_group_add
                entry_group_cancel
                entry_group_create
                entry_group_update
                entry_group_cancel_client
                role
                entry_individual_create_lower_than_min_duration
            }
        }
    }
`;
