import { Box, styled } from '@mui/material';

export const Container = styled(Box)({
    display: 'flex',
});

export const RoutesWrap = styled(Box)({
    flexGrow: 1,
    background: 'white',
});
