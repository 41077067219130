import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { PRICE_SALES_ROUND_QUERY, PriceSalesRoundResponseT } from 'shared/graphql/query/priceSalesRound/PriceSalesRoundQuery';
import { EMPTY_PRICE_SALES_ROUND_RESULT, PriceSalesRoundT } from 'types/PriceSalesRound';

import { PriceSalesRoundDTO } from '../DTO/PriceSalesRoundDTO';

import { PriceSalesRoundDataT } from './PriceSalesRoundData';

const useSalesRound = (): PriceSalesRoundDataT => {
    const [fetch, { loading }] = useLazyQuery<PriceSalesRoundResponseT>(PRICE_SALES_ROUND_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchSalesRound = async (id: string): Promise<PriceSalesRoundT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                PriceSalesRoundDTO,
                EMPTY_PRICE_SALES_ROUND_RESULT,
                (priceSalesRound) => priceSalesRound,
                data?.priceSalesRound,
            );
        } catch {
            return EMPTY_PRICE_SALES_ROUND_RESULT;
        }
    };

    return {
        fetchSalesRound,
        loading,
    };
};

export default useSalesRound;
