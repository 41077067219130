import React, { FC, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    ListItem as BaseListItem, ListItemButton, ListItemText, SxProps,
} from '@mui/material';

type ListItemProps = {
    to: string;
    primary: string;
    disablePadding?: boolean;
    listItemSxProps?: SxProps;
};

const ListItem: FC<ListItemProps> = ({
    to,
    primary,
    disablePadding,
    listItemSxProps,
}) => {
    const location = useLocation();
    const path = `/${location.pathname.split('/')[1]}`;

    return (
        <BaseListItem disablePadding={ disablePadding }>
            <ListItemButton
                component={ Link }
                to={ to }
                selected={ to === path }
                sx={ listItemSxProps }
            >
                <ListItemText primary={ primary } />
            </ListItemButton>
        </BaseListItem>
    );
};

export default memo(ListItem);
