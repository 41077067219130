import { TIME_24_HOURS_15_STEP } from 'constants/hours';
import { MODAL_TITLE_CREATE_COACH } from 'constants/modalTitles';
import { PROPERTY_END, PROPERTY_START } from 'constants/propertyNames';
import { WEEK_DAYS_VALUE_OPTIONS } from 'constants/weekdays';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { EMPLOYEE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/employee/EmployeeQuery';
import { SERVICE_APP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceApp/ServiceAppQuery';
import useCreateCoach from 'shared/hook/coach/create/useCreateCoach';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import { useStore } from 'store';

import {
    COACH_DEFAULT_VALUES, COACH_FIELD_KEYS, COACH_LABEL_DATA, COACH_VALIDATION_SCHEMA, CoachFormFields,
} from './formData';

const CreateCoachModal: FC = observer(() => {
    const { isOpen, onClose, itemId: coachCategoryId } = useStore('createCoachModal');
    const { createCoach, loading: loadingCreation } = useCreateCoach();
    const { getFilterOption } = useStore('filterOptions');
    const employees = getFilterOption('employee');
    const serviceApp = getFilterOption('serviceApp');
    const { loading: loadingEmployee } = useFetchFilterOptions(EMPLOYEE_FILTER_OPTIONS_QUERY, 'employee');
    const { loading: loadingService } = useFetchFilterOptions(SERVICE_APP_FILTER_OPTIONS_QUERY, 'serviceApp');

    const {
        control, handleSubmit, reset, formState: { isValid, isDirty },
    } = useForm<CoachFormFields>({
        defaultValues: COACH_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(COACH_VALIDATION_SCHEMA),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleCreateCoach = async (data: CoachFormFields) => {
        const coachData = { ...data, categoryId: coachCategoryId };

        await createCoach(coachData);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } fullWidth={ true } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>{ MODAL_TITLE_CREATE_COACH }</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 spacing={ 2 } container padding={ 2 } alignItems="center">
                    <Grid2 xs={ 12 }>
                        <Select
                            control={ control }
                            name={ COACH_FIELD_KEYS.name }
                            label={ COACH_LABEL_DATA.name }
                            loading={ loadingEmployee }
                            options={ employees }
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <Select
                            control={ control }
                            name={ COACH_FIELD_KEYS.serviceAppId }
                            label={ COACH_LABEL_DATA.serviceAppId }
                            loading={ loadingService }
                            options={ serviceApp }
                        />
                    </Grid2>
                    <Grid2>
                        <TextField
                            size="small"
                            control={ control }
                            name={ COACH_FIELD_KEYS.color }
                            label={ COACH_LABEL_DATA.color }
                            type="text"
                        />
                    </Grid2>
                    <Grid2>
                        <Switch
                            label={ COACH_LABEL_DATA.active }
                            name={ COACH_FIELD_KEYS.active }
                            control={ control }
                            type="boolean"
                        />
                    </Grid2>
                    { WEEK_DAYS_VALUE_OPTIONS.map((day, index) => (
                        <Grid2 xs={ 12 } key={ day.value } container>
                            <Grid2 xs={ 2 } display="flex" alignItems="center" pt="30px">
                                <Typography>{ day.label }</Typography>
                            </Grid2>
                            <Grid2 xs={ 5 }>
                                <Select
                                    label={ PROPERTY_START }
                                    name={ `workDays[${index}].start` }
                                    options={ TIME_24_HOURS_15_STEP }
                                    control={ control }
                                    empty={ true }
                                />
                            </Grid2>
                            <Grid2 xs={ 5 }>
                                <Select
                                    label={ PROPERTY_END }
                                    name={ `workDays[${index}].end` }
                                    options={ TIME_24_HOURS_15_STEP }
                                    control={ control }
                                    empty={ true }
                                />
                            </Grid2>
                        </Grid2>
                    )) }
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ loadingCreation }
                    onClick={ handleSubmit(handleCreateCoach) }
                    disabled={ !isValid || !isDirty }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateCoachModal;
