import { MONTHS_LIST } from 'constants/MonthsList';
import {
    PROPERTY_ENABLED_AT_MONTH,
    PROPERTY_NUMBER_FULL_RETURNS,
    PROPERTY_ROUNDS_SALES,
} from 'constants/propertyNames';

import { FC, memo } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import MultipleSelect from 'shared/component/Form/MultipleSelect';
import TextField from 'shared/component/Form/TextField';
import { useStore } from 'store';

import { PriceMemberCardFormFields } from './formData';

interface PropsT {
    control: Control<PriceMemberCardFormFields>;
    loadingPriceSalesRounds: boolean;
}

const FullReturnForm: FC<PropsT> = observer((props) => {
    const { control } = props;
    const { getFilterOption } = useStore('filterOptions');
    const priceSalesRoundOptions = getFilterOption('priceSalesRound');
    const { priceMemberCard } = useStore('updatePriceMemberCardModal');

    const { fields, insert, remove } = useFieldArray<PriceMemberCardFormFields>({
        control,
        name: 'memberCardTypeDiscountFullReturns',
    });

    const handleAdd = (index: number) => {
        insert(index + 1, {
            fullReturnQuantityLimit: 0,
            enabledAtMonths: [],
            memberCardTypeDiscountFullReturnPriceSalesRounds: [],
        });
    };

    return (
        <Grid2 xs={ 12 } container display="flex" columnSpacing={ 2 } rowSpacing={ 2 }>
            {
                fields.length > 0 ? fields.map((field, index) => (
                    <Box
                        key={ field.id }
                        sx={ {
                            display: 'flex',
                            width: '100%',
                        } }
                    >
                        <Grid2 xs={ 3 }>
                            <TextField
                                control={ control }
                                label={ PROPERTY_NUMBER_FULL_RETURNS }
                                name={ `memberCardTypeDiscountFullReturns.${index}.fullReturnQuantityLimit` }
                                type="number"
                            />
                        </Grid2>
                        <Grid2 xs={ 4 }>
                            <MultipleSelect
                                options={ MONTHS_LIST }
                                control={ control }
                                label={ PROPERTY_ENABLED_AT_MONTH }
                                selectedValues={ priceMemberCard?.memberCardTypeDiscountFullReturns[index].enabledAtMonths }
                                name={ `memberCardTypeDiscountFullReturns.${index}.enabledAtMonths` }
                            />
                        </Grid2>
                        <Grid2 xs={ 5 }>
                            <MultipleSelect
                                options={ priceSalesRoundOptions }
                                control={ control }
                                label={ PROPERTY_ROUNDS_SALES }
                                selectedValues={
                                    priceMemberCard?.memberCardTypeDiscountFullReturns[index]
                                        .memberCardTypeDiscountFullReturnPriceSalesRounds
                                        .map((round) => round.priceSalesRound.id)
                                }
                                name={ `memberCardTypeDiscountFullReturns.${index}.memberCardTypeDiscountFullReturnPriceSalesRounds` }
                            />
                        </Grid2>
                        <Grid2 display="flex" alignItems="center" pt="30px">
                            <IconButton
                                color="info"
                                onClick={ () => handleAdd(index) }
                            >
                                <AddIcon />
                            </IconButton>
                            <IconButton
                                color="error"
                                onClick={ () => remove(index) }
                            >
                                <RemoveIcon />
                            </IconButton>
                        </Grid2>
                    </Box>
                )) : (
                    <Grid2>
                        <Button
                            variant="outlined"
                            startIcon={ <AddIcon /> }
                            onClick={ () => handleAdd(0) }
                        >
                            Добавить поле
                        </Button>
                    </Grid2>
                )
            }
        </Grid2>
    );
});

export default memo(FullReturnForm);
