import {
    PROPERTY_ACTIONS, PROPERTY_CARD_PURCHASE_PRICE, PROPERTY_TITLE,
} from 'constants/propertyNames';

import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

const RenderCardPriceCell = (props: GridRenderCellParams) => {
    const { row: { price } } = props;
    const { serviceApp: { fiatCurrencyTitle } } = useStore('serviceApp');

    return (
        <span>{ price } { fiatCurrencyTitle }</span>
    );
};

const RenderActionCell = (props: GridRenderCellParams) => {
    const { row: { id } } = props;
    const { onOpen } = useStore('updatePriceMemberCardModal');

    return (
        <IconButton onClick={ () => onOpen(id) }>
            <EditIcon color="primary" />
        </IconButton>
    );
};

export const PRICE_MEMBER_CARD_HEADER_DATA: CustomHeaderData[] = [
    {
        field: 'title',
        headerName: PROPERTY_TITLE,
        width: 150,
        type: 'singleSelect',
    },
    {
        field: 'price',
        headerName: PROPERTY_CARD_PURCHASE_PRICE,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        width: 200,
        hasuraSortFields: ['member_card_type_price_season_prices_aggregate', 'avg', 'price'],
        hasuraFilterFields: ['member_card_type_price_season_prices', 'price'],
        renderCell: RenderCardPriceCell,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
