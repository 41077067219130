import { gql } from '@apollo/client';

export type PaymentTypeCreatePayloadT = {
    title: string;
    position: number;
    is_active: boolean;
    default_bonus_payment: number | null;
    default_fiat_payment: number | null;
    fiat_payment_divisible: number;
    bonus_payment_divisible: number;
    payment_method_id: string;
    should_confirm_by_sms: boolean;
    allow_negative_payments: boolean;
};

export type PaymentTypeCreateResponseDataT = {
    createPaymentType: {
        id: string;
    };
} | null | undefined;

export type PaymentTypeCreateVarT = {
    object: PaymentTypeCreatePayloadT;
};

export const PAYMENT_TYPE_CREATE = gql`
    mutation CreatePaymentType($object: payment_type_insert_input!) {
        createPaymentType: insert_payment_type_one(object: $object) {
            id
        }
    }
`;
