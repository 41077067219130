import React, { FC, memo } from 'react';
import { Toolbar, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from 'store';

import NotificationMenu from './components/NotificationMenu';
import UserMenu from './components/UserMenu';
import { HeaderWrap } from './domain/Styled';

const Header: FC = observer(() => {
    const { pageTitle } = useStore('pageTitleStore');

    return (
        <HeaderWrap color="transparent">
            <Toolbar>
                <Typography variant="h6" component="div" sx={ { marginRight: '8px' } }>
                    { pageTitle ?? 'Admin TK Champion' }
                </Typography>
            </Toolbar>
            <Toolbar>
                <NotificationMenu />
                <UserMenu />
            </Toolbar>
        </HeaderWrap>
    );
});

export default memo(Header);
