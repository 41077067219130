import { useEffect } from 'react';
import useCoachCategory from 'shared/data-hook/coachCategory/fetch/fetchOne/useCoachCategory';
import { useStore } from 'store';

const useFetchCoachCategory = (id: string) => {
    const { fetchCoachCategory, loading } = useCoachCategory();
    const { setCoachCategory } = useStore('updateCoachCategoryModal');

    useEffect(() => {
        fetchCoachCategory(id).then((fetchedCoachCategory) => {
            setCoachCategory(fetchedCoachCategory);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchCoachCategory;
