import React, { FC } from 'react';
import { TextField as BaseTextField } from '@mui/material';
import { TextFieldPropsSizeOverrides } from '@mui/material/TextField/TextField';
import { OverridableStringUnion } from '@mui/types';
import { DateTimePicker as BaseDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dayjs } from 'dayjs';

import 'dayjs/locale/ru';

type PropsT = {
    setValue: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    value: Dayjs | null;
    size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
};

const DateTimePicker: FC<PropsT> = (props) => {
    const {
        setValue, value, size,
    } = props;

    return (
        <LocalizationProvider
            dateAdapter={ AdapterDayjs }
            adapterLocale="ru"
        >
            <BaseDateTimePicker
                value={ value }
                onChange={ (newValue: Dayjs | null) => {
                    setValue(newValue);
                } }
                renderInput={ (params) => (
                    <BaseTextField
                        size={ size || 'small' }
                        { ...params }
                    />
                ) }
            />
        </LocalizationProvider>
    );
};

export default DateTimePicker;
