import { gql } from '@apollo/client';

export type ServiceCategoryCreatePayloadT = {
    color: string | null;
    name: string;
    min_additional_duration: number;
    min_duration: number;
    position: number;
};

export type CreateServiceCategoryVarT = {
    object: ServiceCategoryCreatePayloadT;
};

export type ServiceCategoryCreateResponseDataT = {
    createServiceCategoryType: {
        id: string;
    };
} | null | undefined;

export const SERVICE_CATEGORY_CREATE = gql`
    mutation CreateServiceCategory($object: service_category_insert_input = {}) {
        createServiceCategoryType: insert_service_category_one(
            object: $object
        ) {
            id
        }
    }
`;
