import { action, makeObservable, observable } from 'mobx';
import { CoachT } from 'types/Coach';

import BaseModalStore from './BaseModalStore';

export default class UpdateCoachModal extends BaseModalStore {
    public coach: CoachT | undefined = undefined;

    public setCoach(coach: CoachT) {
        this.coach = coach;
    }

    constructor() {
        super();

        makeObservable(this, {
            coach: observable,
            setCoach: action.bound,
        });
    }
}
