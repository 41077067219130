import { API_DATE_FORMAT } from 'constants/date';

import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { SeasonFormFields } from 'page/PriceSeason/components/formData';
import { PRICE_SEASON_UPDATE, PriceSeasonUpdateResponseDataT, PriceSeasonUpdateVar } from 'shared/graphql/mutation/priceSeason/PriceSeasonUpdate';
import { PriceSeasonT } from 'types/PriceSeason';

import { SeasonUpdateDataT } from './PriceSeasonUpdateData';

const usePriceSeasonUpdate = (): SeasonUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        PriceSeasonUpdateResponseDataT,
        PriceSeasonUpdateVar
    >(PRICE_SEASON_UPDATE);

    const updateAction = async (
        input: SeasonFormFields,
        priceSeasonId: PriceSeasonT['id'],
    ) => {
        if (!priceSeasonId) {
            // TODO: use Sentry
            throw new Error('PriceSeason is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: priceSeasonId,
                    set: {
                        title: input.title,
                        start_at: dayjs(input.startAt).format(API_DATE_FORMAT),
                        finish_at: dayjs(input.finishAt).format(API_DATE_FORMAT),
                        is_active: input.isActive,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        priceSeasonUpdate: updateAction,
        loading,
        error,
    };
};

export default usePriceSeasonUpdate;
