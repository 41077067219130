import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceSalesRoundDTO } from 'shared/data-hook/priceSalesRound/fetch/DTO/PriceSalesRoundDTO';
import { PriceTypeT } from 'types/PriceType';

const DTO = t.type({
    id: t.string,
    title: t.string,
    position: t.number,
    is_active: t.boolean,
    is_group_available: t.boolean,
    is_coach_available: t.boolean,
    price_sales_rounds: t.union([t.array(PriceSalesRoundDTO), t.any]),
});

export type PriceTypeDTOT = t.TypeOf<typeof DTO>;

export const PriceTypeDTO = tPromise.extendDecoder<PriceTypeDTOT, PriceTypeT>(
    DTO,
    ({
        id, title, position, is_active, is_group_available, is_coach_available, price_sales_rounds,
    }) => {
        return {
            id,
            title,
            position,
            isActive: is_active,
            isGroupAvailable: is_group_available,
            isCoachAvailable: is_coach_available,
            priceSalesRounds: price_sales_rounds ?? [],
        };
    },
) as t.Type<PriceTypeT, PriceTypeDTOT>;
