import { gql } from '@apollo/client';

export type PriceSeasonCreateFields = {
    title: string;
    start_at: string;
    finish_at: string;
    is_active: boolean;
};

export type PriceSeasonCreateVar = {
    object: PriceSeasonCreateFields;
};

export type PriceSeasonCreateResponseDataT = {
    createPriceSeason: {
        id: string;
    };
} | null | undefined;

export const PRICE_SEASON_CREATE = gql`
    mutation CreatePriceSeason($object: price_season_insert_input = {}) {
        createPriceSeason: insert_price_season_one(
            object: $object
        ) {
            id
        }
    }
`;
