import { API_DATE_FORMAT } from 'constants/date';

import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { SeasonFormFields } from 'page/PriceSeason/components/formData';
import {
    PRICE_SEASON_CREATE,
    PriceSeasonCreateResponseDataT,
    PriceSeasonCreateVar,
} from 'shared/graphql/mutation/priceSeason/PriceSeasonCreate';

import { SeasonCreateDataT } from './PriceSeasonCreateData';

const usePriceSeasonCreate = (): SeasonCreateDataT => {
    const [create, { loading, error }] = useMutation<PriceSeasonCreateResponseDataT, PriceSeasonCreateVar>(
        PRICE_SEASON_CREATE,
    );

    const createAction = async (input: SeasonFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        title: input.title,
                        start_at: dayjs(input.startAt).format(API_DATE_FORMAT),
                        finish_at: dayjs(input.finishAt).format(API_DATE_FORMAT),
                        is_active: input.isActive,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        priceSeasonCreate: createAction,
        loading,
        error,
    };
};

export default usePriceSeasonCreate;
