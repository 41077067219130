import { PAGE_TITLE_COACH_SETTINGS } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import CreateCoachModal from 'page/Coach/components/CreateCoachModal';
import UpdateCoachModal from 'page/Coach/components/UpdateCoachModal';
import CoachCategoryList from 'page/CoachCategory/components/CoachCategoryList';
import CreateCoachCategoryModal from 'page/CoachCategory/components/CreateCoachCategory';
import UpdateCoachCategoryModal from 'page/CoachCategory/components/UpdateCoachCategoryModal';
import { COACH_CATEGORY_HEADER_DATA } from 'page/CoachCategory/domain/HeaderData';
import ControlPanel from 'shared/component/ControlPanel';
import useCoachCategories from 'shared/data-hook/coachCategory/fetch/fetchAll/useCoachCategories';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: coachCategoriesData, loading: coachCategoriesLoading, fetchCoachCategories, count: rowCount,
    } = useCoachCategories();

    const { setCoachCategories } = useStore('coachCategory');
    const { onOpen } = useStore('createCoachCategoryModal');
    const { refetchTrigger } = useStore('refetchTrigger');

    usePageTitle(PAGE_TITLE_COACH_SETTINGS);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchCoachCategories, refetchTrigger, COACH_CATEGORY_HEADER_DATA);

    useEffect(() => {
        setCoachCategories(coachCategoriesData, rowCount);
    }, [coachCategoriesData, coachCategoriesLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button variant="outlined" size="small" startIcon={ <AddIcon /> } onClick={ () => onOpen() }>добавить</Button>
            </ControlPanel>
            <CoachCategoryList
                loading={ coachCategoriesLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateCoachModal />
            <UpdateCoachModal />
            <UpdateCoachCategoryModal />
            <CreateCoachCategoryModal />
        </StyledPageWrap>
    );
});

export default List;
