import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { ScheduleSlotReserveT } from 'types/ScheduleSlotReserve';

export type ScheduleSlotReservesResponseT = {
    scheduleSlotReserves: ScheduleSlotReserveT[];
    scheduleSlotReservesCount: AggregateT;
};

export const SCHEDULE_SLOT_RESERVES_QUERY = gql`
    query ScheduleSlotReservesQuery(
        $limit: Int,
        $offset: Int,
        $orderBy: schedule_slot_reserve_order_by!,
        $where: schedule_slot_reserve_bool_exp!
    ) {
        scheduleSlotReserves: schedule_slot_reserve(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            coaches_ids
            implementation_document {
                number
                client_datum {
                    client {
                        first_name
                        last_name
                        middle_name
                    }
                }
                service_category {
                    name
                }
                type
                group {
                    name
                }
            }
            price_season {
                title
            }
            datetime_start
            datetime_end
        }
        scheduleSlotReservesCount: schedule_slot_reserve_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
