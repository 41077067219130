import React, {
    Dispatch, FC, memo, SetStateAction,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Collapse, List,
    ListItem as BaseListItem, ListItemButton, ListItemText,
} from '@mui/material';
import { PathT } from 'types/MenuItems';

import ListItem from './ListItem';

type PropsT = {
    listItems: PathT[];
    headerList: string;
    headerLink?: string;
    id: number;
    openList: number | null;
    setOpenList: Dispatch<SetStateAction<number | null>>;
};

const NestedList: FC<PropsT> = ({
    listItems,
    headerList,
    headerLink,
    id,
    openList,
    setOpenList,
}) => {
    const navigate = useNavigate();
    const handleOpenListClick = () => {
        if (headerLink) {
            navigate(headerLink);
        }
        setOpenList(id);
    };
    const isOpen = openList === id;

    return (
        <>
            <BaseListItem disablePadding onClick={ handleOpenListClick }>
                <ListItemButton
                    selected={ isOpen }
                >
                    <ListItemText secondary={ headerList } />
                    { listItems.length > 0 && (isOpen ? <ExpandLess /> : <ExpandMore />) }
                </ListItemButton>
            </BaseListItem>
            { listItems.length > 0 && (
                <Collapse in={ isOpen } timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            listItems.map((item: PathT) => (
                                <ListItem
                                    key={ item.path }
                                    to={ item.path }
                                    primary={ item.header }
                                    disablePadding={ true }
                                    listItemSxProps={ { pl: 4 } }
                                />
                            ))
                        }
                    </List>
                </Collapse>
            ) }
        </>
    );
};

export default memo(NestedList);
