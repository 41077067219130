import { ERROR_COACH_CATEGORY_CREATE, SUCCESS_COACH_CATEGORY_CREATE } from 'constants/notificationMessage';

import { CoachCategoryFormFields } from 'page/CoachCategory/components/formData';
import useCoachCategoryCreate from 'shared/data-hook/coachCategory/create/useCoachCategoryCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateCoachCategory = () => {
    const { showError, showSuccess } = useNotification();
    const { loading, error, coachCategoryCreate } = useCoachCategoryCreate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createCoachCategory = async (input: CoachCategoryFormFields) => {
        const response = await coachCategoryCreate(input);

        if (error || !response || !response.createCoachCategory) {
            showError(ERROR_COACH_CATEGORY_CREATE);
        } else {
            showSuccess(SUCCESS_COACH_CATEGORY_CREATE);
        }

        toggleRefetchTrigger();
    };

    return {
        createCoachCategory,
        loading,
    };
};

export default useCreateCoachCategory;
