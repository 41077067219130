import { useMutation } from '@apollo/client';
import {
    DeletePriceSalesRoundResponseData, DeletePriceSalesRoundVarT,
    PRICE_SALES_ROUND_DELETE,
} from 'shared/graphql/mutation/priceSalesRound/PriceSalesRoundDelete';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

import { PriceSalesRoundDeleteDataT } from './PriceSalesRoundDeleteData';

const usePriceSalesRoundDelete = (): PriceSalesRoundDeleteDataT => {
    const [priceSalesRoundDelete, { data, loading: deleteLoading, error: errorDelete }] = useMutation<
        DeletePriceSalesRoundResponseData,
        DeletePriceSalesRoundVarT
    >(PRICE_SALES_ROUND_DELETE);

    const deleteAction = async (priceSalesRoundId: PriceSalesRoundT['id']) => {
        try {
            return await priceSalesRoundDelete({
                variables: {
                    id: priceSalesRoundId,
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        loading: deleteLoading,
        deletePriceSalesRound: deleteAction,
        error: errorDelete,
        response: data?.deletePriceSalesRound,
    };
};

export default usePriceSalesRoundDelete;
