import {
    PROPERTY_ACTIONS, PROPERTY_STATUS, PROPERTY_TITLE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import EditButton from 'shared/component/Button/EditButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import useDeletePriceType from 'shared/hook/priceTypes/delete/useDeletePriceType';
import { CustomHeaderData } from 'types/DataGrid';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row } = props;
    const { priceTypeDelete } = useDeletePriceType();

    return (
        <>
            <EditButton id={ row.id } />
            <DeleteButton id={ row.id } title={ row.title } actionDelete={ priceTypeDelete } />
        </>
    );
};

export const PRICE_TYPE_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'title',
        type: 'string',
        flex: 1,
    },
    {
        headerName: '#',
        field: 'position',
        type: 'number',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        hide: true,
    },
    {
        headerName: 'Занятия с тренером',
        field: 'isCoachAvailable',
        renderCell: isActiveIcon,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
    },
    {
        headerName: 'Занятия в группе',
        field: 'isGroupAvailable',
        renderCell: isActiveIcon,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
    },
    {
        headerName: PROPERTY_STATUS,
        field: 'isActive',
        renderCell: isActiveIcon,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
    },
];
