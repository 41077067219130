import { useMutation } from '@apollo/client';
import { PriceTypeFormFields } from 'page/PriceType/components/formData';
import {
    PRICE_TYPE_UPDATE,
    PriceTypeUpdateResponseDataT,
    PriceTypeUpdateVar,
} from 'shared/graphql/mutation/priceType/PriceTypeUpdate';
import { PriceTypeT } from 'types/PriceType';

import { PriceTypeUpdateDataT } from './PriceTypeUpdateData';

const usePriceTypeUpdate = (): PriceTypeUpdateDataT => {
    const [update, { loading: loadingUpdate, error: errorUpdate }] = useMutation<
        PriceTypeUpdateResponseDataT,
        PriceTypeUpdateVar
    >(PRICE_TYPE_UPDATE);

    const updateAction = async (
        input: PriceTypeFormFields,
        priceType: PriceTypeT | undefined,
    ) => {
        if (!priceType) {
            // TODO: use Sentry
            throw new Error('Price type is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: priceType.id,
                    set: {
                        title: input.title,
                        position: input.position,
                        is_active: input.isActive,
                        is_group_available: input.isGroupAvailable,
                        is_coach_available: input.isCoachAvailable,
                    },
                },
            });

            return data;
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        loading: loadingUpdate,
        error: errorUpdate,
        priceTypeUpdate: updateAction,
    };
};

export default usePriceTypeUpdate;
