import { LOGIN } from 'constants/routes';

import {
    FC, memo, useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Login } from 'page/Login/index';
import { LoginGuard } from 'shared/component/Guard/index';
import useFetchServiceApp from 'shared/data-hook/serviceApp/fetch/fetchAll/useFetchServiceApp';
import { useStore } from 'store';

import ProtectedRoutes from './ProtectedRoutes';

const AppRoutes: FC = observer(() => {
    const { fetch: fetchServiceApp } = useFetchServiceApp();
    const { serviceApp, setServiceApp } = useStore('serviceApp');
    const { label, description } = serviceApp;

    useEffect(() => {
        fetchServiceApp().then((result) => {
            setServiceApp(result);
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>{ label }</title>
                <meta name="description" key="description" content={ description } />
            </Helmet>
            <Routes>
                <Route path="/*" element={ <ProtectedRoutes /> } />
                <Route
                    path={ `${LOGIN}/*` }
                    element={ (
                        <LoginGuard>
                            <Login />
                        </LoginGuard>
                    ) }
                />
            </Routes>
        </>
    );
});

export default memo(AppRoutes);
