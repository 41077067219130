import { useMutation } from '@apollo/client';
import { PaymentMethodFormFields } from 'page/PaymentSetting/components/formData';
import {
    PAYMENT_METHOD_CREATE,
    PaymentMethodCreateResponseDataT,
    PaymentMethodCreateVarT,
} from 'shared/graphql/mutation/paymentMethod/PaymentMethodCreate';

import { CreatePaymentMethodDataT } from './CreatePaymentMethodData';

const usePaymentMethodCreate = (): CreatePaymentMethodDataT => {
    const [create, { loading, error }] = useMutation<
        PaymentMethodCreateResponseDataT,
        PaymentMethodCreateVarT
    >(PAYMENT_METHOD_CREATE);

    const createAction = async (input: PaymentMethodFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        title: input.title,
                        position: input.position,
                        is_active: input.isActive,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        loading,
        error,
        paymentMethodCreate: createAction,
    };
};

export default usePaymentMethodCreate;
