import { gql } from '@apollo/client';
import { CoachCategoryT } from 'types/CoachCategory';

export type CoachCategoryDeleteResponseDataT = {
    deleteCoachCategory: {
        id: CoachCategoryT['id'];
    };
} | null | undefined;

export type CoachCategoryDeleteVarT = {
    id: CoachCategoryT['id'];
};

export const COACH_CATEGORY_DELETE = gql`
    mutation DeleteCoachCategory($id: String = "") {
        deleteCoachCategory: delete_coach_category_by_pk(id: $id) {
            id
        }
    }
`;
