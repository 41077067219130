import React from 'react';
import { IsMobileContext } from 'contexts/IsMobileContext';
import { UserSubscriptionContext } from 'contexts/UserSubscriptionContext';
import dayjs from 'dayjs';
import isMobile from 'is-mobile';
import { observer } from 'mobx-react';
import { SnackbarProvider } from 'notistack';
import AppRoutes from 'routes/AppRoutes';
import { useUserSubscription } from 'shared/data-hook/user';
import { Context as StoreContext, getStore } from 'store/index';

import { SnackbarCloseButton } from './shared/component/Button/SnackbarCloseButton';

const App = observer(() => {
    const userSubscription = useUserSubscription();

    dayjs.locale('ru');

    return (
        <StoreContext.Provider value={ getStore() }>
            <UserSubscriptionContext.Provider value={ userSubscription }>
                <IsMobileContext.Provider value={ isMobile() }>
                    <SnackbarProvider
                        action={ (snackbarKey) => <SnackbarCloseButton snackbarKey={ snackbarKey } /> }
                        dense
                        maxSnack={ 5 }
                    >
                        <AppRoutes />
                    </SnackbarProvider>
                </IsMobileContext.Provider>
            </UserSubscriptionContext.Provider>
        </StoreContext.Provider>
    );
});

export default App;
