import {
    ERROR_PRICE_SEASON_DELETE,
    SUCCESS_PRICE_SEASON_DELETE,
} from 'constants/notificationMessage';

import usePriceSeasonDelete from 'shared/data-hook/priceSeason/delete/single/usePriceSeasonDelete';
import { useStore } from 'store';
import { PriceSeasonT } from 'types/PriceSeason';

import { useNotification } from '../../useNotification';

const useDeletePriceSeason = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deletePriceSeason } = usePriceSeasonDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const priceSeasonDelete = async (priceSeasonId: PriceSeasonT['id']) => {
        const response = await deletePriceSeason(priceSeasonId);

        if (error || !response || !response.deletePriceSeason) {
            showError(ERROR_PRICE_SEASON_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PRICE_SEASON_DELETE);
        }
    };

    return {
        priceSeasonDelete,
    };
};

export default useDeletePriceSeason;
