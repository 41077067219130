import React, {
    FC, memo, useEffect, useMemo, useState,
} from 'react';
import { TabContext as BaseTabContext, TabList } from '@mui/lab';
import {
    Box, FormControl, InputLabel, MenuItem, Select, Tab,
} from '@mui/material';
import { GridColumnGroupingModel } from '@mui/x-data-grid-premium';
import { observer } from 'mobx-react';
import { StyledPricePageTabPanel, StyledPricePageWrap } from 'page/Price/domain/Styled';
import ControlPanel from 'shared/component/ControlPanel';
import DataGrid from 'shared/component/DataGrid';
import useFetchDataByPriceTypeIdAndPriceSeasonId from 'shared/hook/dataGrid/useFetchDataByPriceTypeIdAndPriceSeasonId';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { Wrapper } from '../../Main/styles';
import { PRICE_MEMBER_CARD_HEADER_DATA } from '../domain/HeaderData';
import { preparePriceMemberCardHeaderData } from '../domain/preparePriceMemberCardHeaderData';
import { setColumnGroupingModel } from '../domain/setColumnGroupingModel';
import { setHeaderData } from '../domain/setHeaderData';

type PropsT = {
    tab: string;
    handleTabChange: (newTab: string) => void;
    loading: boolean;
    fetchSalesRounds: (priceTypeId?: string | undefined) => Promise<void>;
    fetchPriceMemberCards: Function;
} & DataGridPropsT;

const TabContext: FC<PropsT> = observer(({
    tab,
    handleTabChange,
    loading,
    pageSize,
    setPageSize,
    sortModel,
    page,
    setPage,
    setSortModel,
    filterModel,
    setFilterModel,
    fetchSalesRounds,
    fetchPriceMemberCards,
}) => {
    const { priceSalesRounds } = useStore('priceSalesRound');
    const {
        priceMemberCardsList, count, priceTypes, setActivePriceType, priceSeasons, activePriceSeason, setActivePriceSeason,
    } = useStore('priceMemberCards');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { getFilterOption } = useStore('filterOptions');
    const memberCardTypeFilterOptions = getFilterOption('memberCardType');
    const [selectedPriceSeason, setSelectedPriceSeason] = useState(activePriceSeason.id || 'id');
    const priceSeasonOptions = priceSeasons.map((item) => (
        {
            label: item.title,
            value: item.id,
        }
    ));
    const headerData = [...PRICE_MEMBER_CARD_HEADER_DATA];
    const columnGroupingModel: GridColumnGroupingModel = useMemo(() => setColumnGroupingModel(priceSalesRounds), [priceSalesRounds]);

    useEffect(() => {
        if (!tab) {
            handleTabChange(priceTypes[0]?.id ?? '');
        }
    }, [priceTypes]);

    useEffect(() => {
        const priceType = priceTypes.find((item) => item.id === tab);

        if (priceType) {
            setActivePriceType(priceType);
        }
    }, [tab, priceTypes]);

    useEffect(() => {
        if (tab === '' || activePriceSeason.id === '') {
            return;
        }

        useFetchDataByPriceTypeIdAndPriceSeasonId(tab, activePriceSeason.id, sortModel, filterModel, page, pageSize, PRICE_MEMBER_CARD_HEADER_DATA, fetchPriceMemberCards).then();
        fetchSalesRounds(tab).then();
    }, [sortModel, filterModel, page, pageSize, tab, refetchTrigger, activePriceSeason]);

    return (
        <Wrapper>
            <BaseTabContext value={ tab }>
                <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
                    <TabList
                        scrollButtons={ true }
                        variant="scrollable"
                        onChange={ (event, newTab) => {
                            handleTabChange(newTab);
                        } }
                    >
                        {
                            tab && priceTypes.map((item) => (
                                <Tab label={ item.title } value={ item.id } key={ item.id } />
                            ))
                        }
                    </TabList>
                </Box>
                <StyledPricePageWrap>
                    <ControlPanel>
                        {
                            (priceSeasonOptions.length > 0 && activePriceSeason.id !== '') && (
                                <FormControl sx={ { minWidth: '220px' } }>
                                    <InputLabel id="season-label">Прайс</InputLabel>
                                    <Select
                                        size="small"
                                        label="Сезон"
                                        labelId="season-label"
                                        value={ selectedPriceSeason }
                                        onChange={ (e) => {
                                            const selectedItem = priceSeasons.find((item) => item.id === e.target.value);

                                            if (selectedItem) {
                                                setSelectedPriceSeason(selectedItem.id);
                                                setActivePriceSeason({ id: selectedItem.id, title: selectedItem.title });
                                            }
                                        } }
                                    >
                                        { priceSeasonOptions.map((item) => (
                                            <MenuItem
                                                key={ item.value }
                                                value={ item.value }
                                            >
                                                { item.label }
                                            </MenuItem>
                                        )) }
                                    </Select>
                                </FormControl>
                            )
                        }
                    </ControlPanel>
                    {
                        priceTypes.map((item) => {
                            priceSalesRounds.forEach((priceSalesRound) => {
                                if (!headerData.find((findItem) => findItem.field.includes(priceSalesRound.id))) {
                                    setHeaderData(headerData, priceSalesRound);
                                }
                            });

                            return (
                                <StyledPricePageTabPanel value={ item.id } key={ item.id } sx={ { padding: 0 } }>
                                    <DataGrid
                                        rows={ priceMemberCardsList }
                                        rowCount={ count }
                                        columns={ preparePriceMemberCardHeaderData(headerData, memberCardTypeFilterOptions) }
                                        columnGroupingModel={ columnGroupingModel }
                                        loading={ loading }
                                        pageSize={ pageSize }
                                        page={ page }
                                        setPageSize={ setPageSize }
                                        setPage={ setPage }
                                        setSortModel={ setSortModel }
                                        setFilterModel={ setFilterModel }
                                        sortModel={ sortModel }
                                        filterModel={ filterModel }
                                    />
                                </StyledPricePageTabPanel>
                            );
                        })
                    }
                </StyledPricePageWrap>
            </BaseTabContext>
        </Wrapper>
    );
});

export default memo(TabContext);
