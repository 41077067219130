import { useMutation } from '@apollo/client';
import { CoachCategoryFormFields } from 'page/CoachCategory/components/formData';
import {
    COACH_CATEGORY_CREATE,
    CoachCategoryCreateResponseDataT,
    CoachCategoryCreateVarT,
} from 'shared/graphql/mutation/coachCategory/CoachCategoryCreate';

import { CreateCoachCategoryDataT } from './CreateCoachCategoryData';

const useCoachCategoryCreate = (): CreateCoachCategoryDataT => {
    const [create, { loading, error }] = useMutation<CoachCategoryCreateResponseDataT, CoachCategoryCreateVarT>(
        COACH_CATEGORY_CREATE,
    );

    const createAction = async (input: CoachCategoryFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        name: input.name,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        loading,
        error,
        coachCategoryCreate: createAction,
    };
};

export default useCoachCategoryCreate;
