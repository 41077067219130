import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import Update from './Update';

const ServiceApp: FC = () => {
    return (
        <Routes>
            <Route path="/" element={ <Update /> } />
        </Routes>
    );
};

export default ServiceApp;
