import { useMutation } from '@apollo/client';
import { camelizeKeys } from 'humps';
import {
    DELETE_MULTIPLE_PRICE_SEASON, DeleteMultiplePriceSeasonResponseDataT,
    DeleteMultiplePriceSeasonResponseT,
    DeleteMultiplePriceSeasonVarT,
} from 'shared/graphql/mutation/priceSeason/PriceSeasonMultipleDelete';
import { PriceSeasonT } from 'types/PriceSeason';

import { PriceSeasonMultipleDeleteDataT } from './PriceSeasonMultipleDeleteData';

const usePriceSeasonMultipleDelete = (): PriceSeasonMultipleDeleteDataT => {
    const [deletePriceSeasons, { loading, error }] = useMutation<
        DeleteMultiplePriceSeasonResponseT,
        DeleteMultiplePriceSeasonVarT
    >(DELETE_MULTIPLE_PRICE_SEASON);

    const deleteAction = async (priceSeasonIds: [PriceSeasonT['id']]) => {
        try {
            const { data } = await deletePriceSeasons({
                variables: {
                    ids: priceSeasonIds,
                },
            });

            return camelizeKeys(data?.deleteMultiplePriceSeason) as DeleteMultiplePriceSeasonResponseDataT;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteMultiplePriceSeason: deleteAction,
        loading,
        error,
    };
};

export default usePriceSeasonMultipleDelete;
