import { useEffect } from 'react';
import useSalesRound from 'shared/data-hook/priceSalesRound/fetch/fetchOne/usePriceSalesRound';
import { useStore } from 'store';

const useFetchPriceSalesRound = () => {
    const { fetchSalesRound, loading } = useSalesRound();
    const { setPriceSalesRound } = useStore('priceSalesRound');
    const { priceSalesRoundId } = useStore('updatePriceSalesRound');

    const fetch = () => {
        useEffect(() => {
            if (!priceSalesRoundId) {
                return;
            }

            fetchSalesRound(priceSalesRoundId).then((priceSalesRound) => {
                setPriceSalesRound(priceSalesRound);
            });
        }, [priceSalesRoundId]);
    };

    return {
        fetch,
        loading,
    };
};

export default useFetchPriceSalesRound;
