import { useMutation } from '@apollo/client';
import { camelizeKeys } from 'humps';
import { UpdatePriceRateFormFields } from 'page/Price/components/UpdatePriceRate/formData';
import {
    CHECK_UPDATE_PRICE_RATE, CheckUpdatePriceRateResponseT,
    CheckUpdatePriceRateT,
    CheckUpdatePriceRateVarT, EMPTY_CHECK_UPDATE_PRICE_RATE_RESPONSE,
} from 'shared/graphql/mutation/price/CheckUpdatePriceRateAction';
import { useStore } from 'store';

import { UpdatePriceRateDTO } from '../DTO/UpdatePriceRateDTO';

import { CheckUpdatePriceRateDataT } from './CheckUpdatePriceRateData';

const useCheckUpdatePriceRateAction = (): CheckUpdatePriceRateDataT => {
    const [check, { loading, error }] = useMutation<CheckUpdatePriceRateT, CheckUpdatePriceRateVarT>(
        CHECK_UPDATE_PRICE_RATE,
    );

    const { priceSeason, activePriceType } = useStore('price');
    const { setMutableValues } = useStore('updatePriceRateModal');
    const { serviceApp } = useStore('serviceApp');

    const checkUpdatePriceRate = async (rateData: UpdatePriceRateFormFields) => {
        const priceSeasonId = priceSeason?.id || '';
        const priceTypeId = activePriceType?.id || '';
        const serviceAppFiat = serviceApp.fiatCurrencyTitle;

        const payload = UpdatePriceRateDTO.encode({
            ...rateData, priceSeasonId, priceTypeId, serviceAppFiat,
        });

        const mutableValues = Object.entries(camelizeKeys(payload)).reduce((fields: any, [key, value]) => {
            if (
                key === 'saleRate'
                || key === 'minRate'
                || key === 'transferDisabledBeforeHours'
                || key === 'returnRate'
                || key === 'backTransferCost'
                || key === 'forwardTransferCost'
                || key === 'performerReturnIncomeRate'
                || key === 'performerReturnIncomeSum'
                || key === 'performerSaleIncomeRate'
                || key === 'performerSaleIncomeSum'
                || key === 'transferable'
                || key === 'cancellable'
                || key === 'shareOfPaymentBonus'
                || key === 'shareOfPaymentFiat'
            ) {
                fields.push({
                    label: key,
                    value,
                });
            }

            return fields;
        }, []);

        setMutableValues(camelizeKeys(mutableValues));

        try {
            const { data } = await check({
                variables: {
                    appId: serviceApp.id,
                    payload,
                },
            });

            return camelizeKeys(data?.priceMultipleUpdateRateCheck) as CheckUpdatePriceRateResponseT;
        } catch {
            return EMPTY_CHECK_UPDATE_PRICE_RATE_RESPONSE;
        }
    };

    return {
        checkUpdatePriceRate,
        loading,
        error,
    };
};

export default useCheckUpdatePriceRateAction;
