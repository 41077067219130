import { makeAutoObservable } from 'mobx';
import { CoachCategoryT } from 'types/CoachCategory';

export default class CoachCategoryStore {
    public coachCategoryList: CoachCategoryT[] = [];

    public coachCategory: CoachCategoryT | undefined = undefined;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get coachCategories(): CoachCategoryT[] {
        return this.coachCategoryList;
    }

    public setCoachCategories(coachCategories: CoachCategoryT[], count?: number) {
        this.coachCategoryList = coachCategories;
        this.count = count ?? 0;
    }
}
