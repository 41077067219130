import { RUB_SYMBOL } from 'constants/currency';

import { FC } from 'react';
import { Control } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';

import {
    MEMBER_CARD_TYPE_DEFAULT_VALUES, MEMBER_CARD_TYPE_FIELDS_KEYS, MEMBER_CARD_TYPE_LABEL_DATA,
    MemberCardTypeFormFields,
} from './formData';

type PropsT = {
    control: Control<MemberCardTypeFormFields>;
    loading?: boolean;
};

const MemberCardTypeForm: FC<PropsT> = (props) => {
    const {
        control, loading,
    } = props;

    return (
        <Box my={ 1 }>
            <Grid2 container direction="row" columnSpacing={ 1.5 } sx={ { marginBottom: '12px' } }>
                <Grid2 xs={ 6 }>
                    <TextField
                        key={ MEMBER_CARD_TYPE_FIELDS_KEYS.title }
                        loading={ loading }
                        name={ MEMBER_CARD_TYPE_FIELDS_KEYS.title as keyof MemberCardTypeFormFields }
                        type={ typeof MEMBER_CARD_TYPE_DEFAULT_VALUES[MEMBER_CARD_TYPE_FIELDS_KEYS.title as keyof MemberCardTypeFormFields] }
                        control={ control }
                        label={ MEMBER_CARD_TYPE_LABEL_DATA[MEMBER_CARD_TYPE_FIELDS_KEYS.title as keyof MemberCardTypeFormFields] }
                    />
                </Grid2>
                <Grid2 xs={ 6 }>
                    <TextField
                        key={ MEMBER_CARD_TYPE_FIELDS_KEYS.expirationPeriod }
                        loading={ loading }
                        name={ MEMBER_CARD_TYPE_FIELDS_KEYS.expirationPeriod as keyof MemberCardTypeFormFields }
                        type={ typeof MEMBER_CARD_TYPE_DEFAULT_VALUES[MEMBER_CARD_TYPE_FIELDS_KEYS.expirationPeriod as keyof MemberCardTypeFormFields] }
                        control={ control }
                        label={ MEMBER_CARD_TYPE_LABEL_DATA[MEMBER_CARD_TYPE_FIELDS_KEYS.expirationPeriod as keyof MemberCardTypeFormFields] }
                        inputAdornment="мес."
                    />
                </Grid2>
            </Grid2>
            <Typography variant="h5">Настройки параметров кэшбэков</Typography>
            <Grid2 container direction="row" columnSpacing={ 1.5 } sx={ { marginBottom: '12px' } }>
                <Grid2 xs={ 6 }>
                    <TextField
                        key={ MEMBER_CARD_TYPE_FIELDS_KEYS.cashbackFiatOperationsLimit }
                        loading={ loading }
                        name={ MEMBER_CARD_TYPE_FIELDS_KEYS.cashbackFiatOperationsLimit as keyof MemberCardTypeFormFields }
                        type={ typeof MEMBER_CARD_TYPE_DEFAULT_VALUES[MEMBER_CARD_TYPE_FIELDS_KEYS.cashbackFiatOperationsLimit as keyof MemberCardTypeFormFields] }
                        control={ control }
                        label={ MEMBER_CARD_TYPE_LABEL_DATA[MEMBER_CARD_TYPE_FIELDS_KEYS.cashbackFiatOperationsLimit as keyof MemberCardTypeFormFields] }
                    />
                </Grid2>
                <Grid2 xs={ 6 }>
                    <TextField
                        key={ MEMBER_CARD_TYPE_FIELDS_KEYS.cashbackFiatSumLimit }
                        loading={ loading }
                        name={ MEMBER_CARD_TYPE_FIELDS_KEYS.cashbackFiatSumLimit as keyof MemberCardTypeFormFields }
                        type={ typeof MEMBER_CARD_TYPE_DEFAULT_VALUES[MEMBER_CARD_TYPE_FIELDS_KEYS.cashbackFiatSumLimit as keyof MemberCardTypeFormFields] }
                        control={ control }
                        label={ MEMBER_CARD_TYPE_LABEL_DATA[MEMBER_CARD_TYPE_FIELDS_KEYS.cashbackFiatSumLimit as keyof MemberCardTypeFormFields] }
                        inputAdornment={ RUB_SYMBOL }
                    />
                </Grid2>
            </Grid2>
            <Grid2 container>
                <Grid2 xs={ 3 }>
                    <Switch
                        loading={ loading }
                        key={ MEMBER_CARD_TYPE_FIELDS_KEYS.isActive }
                        name={ MEMBER_CARD_TYPE_FIELDS_KEYS.isActive as keyof MemberCardTypeFormFields }
                        control={ control }
                        label={ MEMBER_CARD_TYPE_LABEL_DATA[MEMBER_CARD_TYPE_FIELDS_KEYS.isActive as keyof MemberCardTypeFormFields] }
                    />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default MemberCardTypeForm;
