import { useMutation } from '@apollo/client';
import {
    CreatePriceSalesRoundResponseT,
    CreatePriceSalesRoundT,
    CreatePriceSalesRoundVarT, PRICE_SALES_ROUND_CREATE,
    PriceSalesRoundCreateFormFieldsPayloadT,
} from 'shared/graphql/mutation/priceSalesRound/PriceSalesRoundCreate';
import { useStore } from 'store';

import { PriceSalesRoundCreateDataT } from './PriceSalesRoundCreateData';

const usePriceSalesRoundCreate = (): PriceSalesRoundCreateDataT => {
    const [create, { loading, error }] = useMutation<CreatePriceSalesRoundT, CreatePriceSalesRoundVarT>(
        PRICE_SALES_ROUND_CREATE,
    );
    const { serviceApp } = useStore('serviceApp');

    const createAction = async (input: PriceSalesRoundCreateFormFieldsPayloadT) => {
        try {
            const { data } = await create({
                variables: {
                    appId: serviceApp.id,
                    payload: {
                        title: input.title,
                        position: input.position,
                        price_type_id: input.priceTypeId,
                        end_day: input.endDay,
                        end_hour: input.endHour,
                        end_month: input.endMonth,
                        end_week: input.endWeek,
                        start_day: input.startDay,
                        start_hour: input.startHour,
                        start_month: input.startMonth,
                        start_week: input.startWeek,
                        end_day_from_start: input.endDayFromStart,
                        end_hour_from_start: input.endHourFromStart,
                        end_month_from_start: input.endMonthFromStart,
                        end_week_from_start: input.endWeekFromStart,
                        start_day_from_start: input.startDayFromStart,
                        start_hour_from_start: input.startHourFromStart,
                        start_month_from_start: input.startMonthFromStart,
                        start_week_from_start: input.startWeekFromStart,
                        sale_rate_title: input.saleRateTitle,
                        return_rate_title: input.returnRateTitle,
                        club_return_rate_title: input.clubReturnRateTitle,
                        min_rate_title: input.minRateTitle,
                        transfer_title: input.transferTitle,
                    },
                },
            });

            return data?.priceCreateSalesRound as CreatePriceSalesRoundResponseT;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        loading,
        error,
        priceSalesRoundCreate: createAction,
    };
};

export default usePriceSalesRoundCreate;
