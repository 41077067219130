import { PAGE_TITLE_PRICE_TYPE } from 'constants/pageTitles';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import CreateButtonLink from 'shared/component/Button/CreateButtonLink';
import ControlPanel from 'shared/component/ControlPanel';
import usePriceTypes from 'shared/data-hook/priceType/fetch/fetchAll/usePriceTypes';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import PriceSalesRoundCreateModal from './components/PriceSalesRound/components/PriceSalesRoundCreateModal';
import PriceSalesRoundUpdateModal from './components/PriceSalesRound/components/PriceSalesRoundUpdateModal';
import PriceTypeList from './components/PriceTypeList';
import { PRICE_TYPE_HEADER_DATA } from './domain/HeaderData';

const List = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: priceTypesData, loading: priceTypesLoading, count: rowCount, fetchPriceTypes,
    } = usePriceTypes();
    const { setPriceTypes } = useStore('priceTypes');
    const { refetchTrigger } = useStore('refetchTrigger');

    usePageTitle(PAGE_TITLE_PRICE_TYPE);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchPriceTypes, refetchTrigger, PRICE_TYPE_HEADER_DATA);

    useEffect(() => {
        setPriceTypes(priceTypesData, rowCount);
    }, [priceTypesLoading, priceTypesData]);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <CreateButtonLink />
            </ControlPanel>
            <PriceTypeList
                loading={ priceTypesLoading }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
                page={ page }
                pageSize={ pageSize }
            />
            <PriceSalesRoundUpdateModal />
            <PriceSalesRoundCreateModal />
        </StyledPageWrap>
    );
});

export default List;
