import { action, makeObservable, observable } from 'mobx';

export default class BaseModalStore {
    public isOpen: boolean = false;

    public itemId: string = '';

    public onOpen(itemId?: string) {
        this.isOpen = true;

        if (itemId) {
            this.itemId = itemId;
        }
    }

    public onClose() {
        this.isOpen = false;
        this.itemId = '';
    }

    constructor() {
        makeObservable(this, {
            isOpen: observable,
            itemId: observable,
            onOpen: action.bound,
            onClose: action.bound,
        });
    }
}
