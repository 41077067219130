import { useMutation } from '@apollo/client';
import { CoachCategoryFormFields } from 'page/CoachCategory/components/formData';
import {
    COACH_CATEGORY_UPDATE, CoachCategoryUpdateResponseDataT,
    CoachCategoryUpdateVarT,
} from 'shared/graphql/mutation/coachCategory/CoachCategoryUpdate';
import { CoachCategoryT } from 'types/CoachCategory';

import { CoachCategoryUpdateDataT } from './CoachCategoryUpdateData';

const useCoachCategoryUpdate = (): CoachCategoryUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        CoachCategoryUpdateResponseDataT,
        CoachCategoryUpdateVarT
    >(COACH_CATEGORY_UPDATE);

    const updateAction = async (input: CoachCategoryFormFields, coachCategoryId: CoachCategoryT['id']) => {
        if (!coachCategoryId) {
            // TODO: use Sentry
            throw new Error('Court category is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: coachCategoryId,
                    set: {
                        name: input.name,
                    },
                },
            });

            return data;
            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        coachCategoryUpdate: updateAction,
        loading,
        error,
    };
};

export default useCoachCategoryUpdate;
