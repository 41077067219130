import { MODAL_TITLE_CREATE_PRICE_SALES_ROUND } from 'constants/modalTitles';

import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreatePriceSalesRound from 'shared/hook/priceSalesRound/create/useCreatePriceSalesRound';
import { useStore } from 'store';

import {
    CREATE_PRICE_SALES_ROUND_DEFAULT_VALUES, PRICE_SALES_ROUND_VALIDATION_SCHEMA, PriceSalesRoundFormFields,
} from './formData';
import PriceSalesRoundForm from './PriceSalesRoundForm';
import RangeDateErrorMessage from './RangeDateErrorMessage';

const PriceSalesRoundCreateModal: FC = observer(() => {
    const { onClose, isOpen } = useStore('createPriceSalesRound');

    const { createPriceSalesRound, loading } = useCreatePriceSalesRound();
    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<PriceSalesRoundFormFields>({
        defaultValues: CREATE_PRICE_SALES_ROUND_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(PRICE_SALES_ROUND_VALIDATION_SCHEMA),
    });
    const [dateError, setDateError] = useState<boolean>(false);

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreatePriceSalesRound = (data: PriceSalesRoundFormFields) => {
        if (dateError) {
            return;
        }
        createPriceSalesRound(data).then(() => {
            handleCloseModal();
        });
    };

    return (
        <Modal className="fullHeightModal modalWithTabs" open={ isOpen } fullWidth={ true } maxWidth="lg">
            <ModalTitle onClose={ onClose }>
                { MODAL_TITLE_CREATE_PRICE_SALES_ROUND }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <PriceSalesRoundForm
                    control={ control }
                    setDateError={ setDateError }
                />
            </DialogContent>
            <DialogActions>
                <RangeDateErrorMessage error={ dateError } />
                <Button variant="outlined" onClick={ onClose }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    type="submit"
                    autoFocus
                    onClick={ handleSubmit(handleCreatePriceSalesRound) }
                    loading={ loading }
                    disabled={ !isDirty || !isValid || dateError }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default PriceSalesRoundCreateModal;
