import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { PRICES_QUERY, PricesResponseT } from 'shared/graphql/query/price/PriceQuery';
import { useStore } from 'store';
import { FetchDataParams } from 'types/DataGrid';
import { FetchDataByPriceTypeIdAndPriceSeasonIdQueryVarsT } from 'types/Graphql';
import { EMPTY_PRICES_RESULT } from 'types/Price';

import { filterData } from '../../../utils/filter/filterData';
import { PricesDTO } from '../DTO/PricesDTO';

import { PricesDataT } from './PricesData';

const usePrices = (): PricesDataT => {
    const [fetch, { data, loading }] = useLazyQuery<PricesResponseT, FetchDataByPriceTypeIdAndPriceSeasonIdQueryVarsT>(PRICES_QUERY, {
        fetchPolicy: 'network-only',
    });
    const { serviceApp } = useStore('serviceApp');

    const decodedPricesData = decodeDTO(
        PricesDTO,
        EMPTY_PRICES_RESULT,
        (pricesData) => pricesData,
        {
            prices: data?.prices,
            pricesCount: data?.pricesCount,
            currency: serviceApp.fiatCurrencyTitle,
        },
    );

    const fetchPrices = async (priceTypeId: string, priceSeasonId: string, {
        limit, offset, orderBy = {}, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<any> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                    priceSeasonId,
                    priceTypeId,
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchPrices,
        data: decodedPricesData.prices,
        count: decodedPricesData.pricesCount,
        loading,
    };
};

export default usePrices;
