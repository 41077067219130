import { ERROR_PRICE_MULTIPLE_CREATE } from 'constants/notificationMessage';

import { PricesFormFields } from 'page/Price/components/CreatePriceModal/formData';
import usePriceMultipleCreate from 'shared/data-hook/price/create/createMultiple/usePriceMultipleCreate';
import { PriceFormFieldsPayloadT } from 'shared/graphql/mutation/price/PriceMultipleCreate';
import { useStore } from 'store';

import { useNotification } from '../../../useNotification';

const useCreatePrice = () => {
    const { error, loading, priceMultipleCreate } = usePriceMultipleCreate();
    const { priceTypeId, priceSeasonId } = useStore('createPriceModal');
    const { showError, showSuccess, showWarning } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createPrice = async (input: PricesFormFields) => {
        const response = await priceMultipleCreate({
            ...(input as PriceFormFieldsPayloadT),
            priceTypeId,
            priceSeasonId,
        });

        if (error || !response.length) {
            showError(ERROR_PRICE_MULTIPLE_CREATE);

            return;
        }

        const statusOk = response.filter((item) => item.status === 'ok').length;
        const statusError = response.filter((item) => item.status === 'error').length;

        toggleRefetchTrigger();
        if (statusOk > 0) {
            showSuccess(`Создано новых строк: ${statusOk}`);
        }
        if (statusOk === 0) {
            showWarning(`Создано новых строк: ${statusOk}`);
        }
        if (statusError > 0) {
            showWarning(`Пересечения с существующими строками: ${statusError}`);
        }
    };

    return {
        createPrice,
        loading,
    };
};

export default useCreatePrice;
