import { makeAutoObservable } from 'mobx';
import { PriceT } from 'types/Price';

export default class UpdatePriceModal {
    public updatePriceId: string = '';

    public isOpen = false;

    public price: PriceT | undefined = undefined;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(priceId: string) {
        this.isOpen = true;
        this.updatePriceId = priceId;
    }

    public onClose() {
        this.isOpen = false;
        this.updatePriceId = '';
    }

    public setPrice(price: PriceT) {
        this.price = price;
    }
}
