import { TabPanel } from '@mui/lab';
import { Box, styled } from '@mui/material';

export const StyledPricePageWrap = styled(Box)`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 113px);
    padding: 24px;
`;

export const StyledPricePageTabPanel = styled(TabPanel)({
    height: '100%',

    '& .data-grid-wrap': {
        height: '100%',
    },
});
