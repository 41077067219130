import { FC } from 'react';
import { Control, useController, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DateRangePicker as BaseDateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import TextField from '../TextField';

import 'dayjs/locale/ru';

type PropsT = {
    setValue: UseFormSetValue<any>;
    nameStart: string;
    nameFinish: string;
    control: Control<any>;
    loading?: boolean;
};

const DateRangePicker: FC<PropsT> = (props) => {
    const {
        setValue, nameStart, nameFinish, control, loading,
    } = props;

    const { field: startField } = useController({ control, name: nameStart });
    const { field: finishField } = useController({ control, name: nameFinish });

    return (
        <LocalizationProvider
            dateAdapter={ AdapterDayjs }
            adapterLocale="ru"
            localeText={ { start: 'Начало', end: 'Конец' } }
        >
            <BaseDateRangePicker
                value={ [startField.value, finishField.value] }
                onChange={ (newValue) => {
                    setValue(nameStart, newValue[0]);
                    setValue(nameFinish, newValue[1]);
                } }
                renderInput={ (startProps, endProps) => {
                    return (
                        (
                            <Grid2 container direction="row" columnSpacing={ 2 } xs={ 12 }>
                                <Grid2 xs={ 6 }>
                                    <TextField
                                        label={ startProps.label as string }
                                        dateRangeProps={ {
                                            ...startProps, label: null,
                                        } }
                                        control={ control }
                                        name={ nameStart }
                                        loading={ loading }
                                    />
                                </Grid2>
                                <Grid2 xs={ 6 }>
                                    <TextField
                                        dateRangeProps={ {
                                            ...endProps, label: null,
                                        } }
                                        label={ endProps.label as string }
                                        control={ control }
                                        name={ nameFinish }
                                        loading={ loading }
                                    />
                                </Grid2>
                            </Grid2>
                        )
                    );
                } }
            />
        </LocalizationProvider>
    );
};

export default DateRangePicker;
