import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PriceTypesT } from 'types/PriceType';

import { PriceTypeDTO } from './PriceTypeDTO';

const DTO = t.type({
    priceTypes: t.array(PriceTypeDTO),
    priceTypesCount: AggregateDTO,
});

type PriceTypeDTOT = t.TypeOf<typeof DTO>;

export const PriceTypesDataDTO = tPromise.extendDecoder<PriceTypeDTOT, PriceTypesT>(
    DTO,
    ({
        priceTypes,
        priceTypesCount,
    }) => ({
        priceTypes,
        priceTypesCount,
    }),
) as t.Type<PriceTypesT, PriceTypeDTOT>;
