import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    SCHEDULE_SLOT_RESERVES_QUERY,
    ScheduleSlotReservesResponseT,
} from 'shared/graphql/query/scheduleSlotReserve/ScheduleSlotReserveQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_SCHEDULE_SLOT_RESERVES_RESULT } from 'types/ScheduleSlotReserve';

import { filterData } from '../../../utils/filter/filterData';
import { ScheduleSlotReservesDTO } from '../DTO/ScheduleSlotReservesDTO';

import { ScheduleSlotReservesDataT } from './ScheduleSlotReservesData';

const useScheduleSlotReserves = (): ScheduleSlotReservesDataT => {
    const [fetch, { data, loading }] = useLazyQuery<ScheduleSlotReservesResponseT, QueryVarsT>(
        SCHEDULE_SLOT_RESERVES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedScheduleSlotReservesData = decodeDTO(
        ScheduleSlotReservesDTO,
        EMPTY_SCHEDULE_SLOT_RESERVES_RESULT,
        (ScheduleSlotReserves) => ScheduleSlotReserves,
        {
            scheduleSlotReserves: data?.scheduleSlotReserves,
            scheduleSlotReservesCount: data?.scheduleSlotReservesCount,
        },
    );

    const fetchScheduleSlotReserves = async ({
        limit, offset, orderBy = {}, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        const updatedItems = where.items.map((item) => {
            if (item.columnField === 'coachesIds' && item.operatorValue === 'not') {
                return {
                    ...item,
                    operatorValue: 'jsonNot',
                };
            }

            if (item.columnField === 'coachesIds' && item.operatorValue === 'is') {
                return {
                    ...item,
                    operatorValue: 'jsonIs',
                };
            }

            if (item.columnField === 'coachesIds' && item.operatorValue === 'isAnyOf') {
                return {
                    ...item,
                    operatorValue: 'jsonIsAnyOf',
                };
            }

            return item;
        });

        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData({ items: updatedItems, linkOperator: where.linkOperator }),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedScheduleSlotReservesData.scheduleSlotReserves,
        count: decodedScheduleSlotReservesData.scheduleSlotReservesCount,
        fetchScheduleSlotReserves,
        loading,
    };
};

export default useScheduleSlotReserves;
