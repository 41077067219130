import { ERROR_PRICE_RATE_UPDATE } from 'constants/notificationMessage';

import { UpdatePriceRateFormFields } from 'page/Price/components/UpdatePriceRate/formData';
import useCheckUpdatePriceRateAction
    from 'shared/data-hook/price/updatePriceRate/check/useCheckUpdatePriceRateAction';
import { useStore } from 'store';

import { useNotification } from '../../../useNotification';

const useCheckUpdatePriceRate = () => {
    const { error, loading, checkUpdatePriceRate: check } = useCheckUpdatePriceRateAction();
    const { showError } = useNotification();
    const { setPricePreviewForMultipleRateUpdate } = useStore('price');

    const checkUpdatePriceRate = async (input: UpdatePriceRateFormFields): Promise<void> => {
        const { prices, status } = await check(input);

        if (error || status === 'error') {
            showError(ERROR_PRICE_RATE_UPDATE);
        } else {
            setPricePreviewForMultipleRateUpdate(prices);
        }
    };

    return {
        checkUpdatePriceRate,
        loading,
    };
};

export default useCheckUpdatePriceRate;
