import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    PRICE_MEMBER_CARD_QUERY,
    PriceMemberCardResponseT,
} from 'shared/graphql/query/priceMemberCards/PriceMemberCardsQuery';
import {
    EMPTY_PRICE_MEMBER_CARD_RESULT,
} from 'types/PriceMemberCard';

import { PriceMemberCardDTO } from '../DTO/PriceMemberCardDTO';

import { PriceMemberCardDataT } from './PriceMemberCardData';

const usePriceMemberCard = (): PriceMemberCardDataT => {
    const [fetch, { loading }] = useLazyQuery<PriceMemberCardResponseT>(PRICE_MEMBER_CARD_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchPriceMemberCard = async (id: string, priceSeasonId: string, priceTypeId: string): Promise<any> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                    priceSeasonId,
                    priceTypeId,
                },
            });

            return decodeDTO(
                PriceMemberCardDTO,
                EMPTY_PRICE_MEMBER_CARD_RESULT,
                (priceMemberCardData) => priceMemberCardData,
                data?.priceMemberCard,
            );
        } catch {
            return (EMPTY_PRICE_MEMBER_CARD_RESULT);
        }
    };

    return {
        fetchPriceMemberCard,
        loading,
    };
};

export default usePriceMemberCard;
