import { gql } from '@apollo/client';
import { ServiceCategoryT } from 'types/ServiceCategory';

export type DeleteServiceCategoryResponseDataT = {
    deleteServiceCategory: {
        id: ServiceCategoryT['id'];
    };
} | null | undefined;

export type DeleteServiceCategoryVarT = {
    id: ServiceCategoryT['id'];
};

export const SERVICE_CATEGORY_DELETE = gql`
    mutation DeleteServiceCategory($id: String = "") {
        deleteServiceCategory: delete_service_category_by_pk(id: $id) {
            id
        }
    }
`;
