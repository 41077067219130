import { useLazyQuery } from '@apollo/client';
import { camelizeKeys } from 'humps';
import {
    PRICE_TIME_PERIODS_QUERY,
    PriceTimePeriodsResponseT,
} from 'shared/graphql/query/price/PriceTimePeriodsQuery';
import { PriceTimePeriodT } from 'types/Price';

import { PriceTimePeriodsDataT } from './PriceTimePeriodsData';
import { PriceTimePeriodsDTO } from './PriceTimePeriodsDTO';

const usePriceTimePeriods = (): PriceTimePeriodsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<PriceTimePeriodsResponseT>(PRICE_TIME_PERIODS_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchPriceTimePeriods = async (
        priceTypeId: string,
        priceSeasonId: string,
        days: number[],
        serviceCategoryIds: string[],
        courtTypeIds: string[],
        groupViewIds: string[],
        coachCategoryIds: string[],
    ): Promise<any> => {
        try {
            await fetch({
                variables: {
                    where: PriceTimePeriodsDTO.encode({
                        priceTypeId, priceSeasonId, days, serviceCategoryIds, courtTypeIds, groupViewIds, coachCategoryIds,
                    }),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchPriceTimePeriods,
        data: camelizeKeys(data?.priceTimePeriods) as PriceTimePeriodT[] || [],
        loading,
    };
};

export default usePriceTimePeriods;
