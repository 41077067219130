import {
    TIME_24_HOURS_15_STEP,
    TIME_24_HOURS_30_STEP,
} from 'constants/hours';
import { MODAL_TITLE_UPDATE_PRICE } from 'constants/modalTitles';
import { WEEK_DAYS_VALUE_OPTIONS } from 'constants/weekdays';

import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Box, Button, DialogActions, DialogContent, Skeleton, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import { TIME_STEPS } from 'page/Price/domain/timeSteps';
import Select from 'shared/component/Form/Select';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import ToggleButton from 'shared/component/ToggleButton';
import useFetchPrice from 'shared/hook/price/fetch/fetchOne/useFetchPrice';
import useUpdatePrice from 'shared/hook/price/update/useUpdatePrice';
import { useStore } from 'store';

import { PRICE_LABEL_DATA } from '../CreatePriceModal/formData';

import {
    getPriceValidationSchema,
    PRICE_DEFAULT_VALUES,
    PRICE_FIELDS_KEYS,
    PriceFormFields,
} from './formData';

const UpdatePriceModal: FC = observer(() => {
    const {
        onClose, isOpen, updatePriceId, price,
    } = useStore('updatePrice');
    const { updatePrice } = useUpdatePrice();
    const { getFilterOption } = useStore('filterOptions');
    const categories = getFilterOption('serviceCategory');
    const courts = getFilterOption('courtType');
    const [timeStep, setTimeStep] = useState<'15' | '30'>('30');
    const { loading: loadingFetch } = useFetchPrice(updatePriceId);

    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<PriceFormFields>({
        defaultValues: PRICE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getPriceValidationSchema),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdatePrice = (data: any) => {
        updatePrice(data, updatePriceId).then(() => handleCloseModal());
    };

    useEffect(() => {
        if (!price) {
            return;
        }

        Object.keys(price).forEach((key) =>
            setValue(key as keyof PriceFormFields, price[key as keyof PriceFormFields as never]));
    }, [price, isOpen]);

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { loadingFetch ?
                    <Skeleton height={ 32 } />
                    :
                    MODAL_TITLE_UPDATE_PRICE }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch ? <Spinner /> : (
                    <Grid2 container xs={ 12 } rowSpacing={ 1 }>
                        <Grid2 xs={ 12 }>
                            <Select
                                options={ categories }
                                name={ PRICE_FIELDS_KEYS.categoryId }
                                control={ control }
                                size="small"
                                label={ PRICE_LABEL_DATA.categoryIds }
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <Select
                                options={ courts }
                                name={ PRICE_FIELDS_KEYS.courtTypeId }
                                control={ control }
                                size="small"
                                label={ PRICE_LABEL_DATA.courtTypeIds }
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <Select
                                options={ WEEK_DAYS_VALUE_OPTIONS }
                                name={ PRICE_FIELDS_KEYS.day }
                                control={ control }
                                label={ PRICE_LABEL_DATA.days }
                                size="small"
                            />
                        </Grid2>
                        <Grid2 container spacing={ 1 } xs={ 12 }>
                            <Grid2 xs={ 12 }>
                                <Box sx={ { display: 'flex', alignItems: 'center' } }>
                                    <Typography variant="h6">
                                        Временные промежутки начала/окончания занятия с шагом
                                    </Typography>
                                    <Box sx={ { display: 'flex', alignItems: 'center', margin: '0 12px' } }>
                                        <ToggleButton
                                            formAlignment={ timeStep }
                                            setFormAlignment={ setTimeStep }
                                            buttons={ TIME_STEPS }
                                        />
                                    </Box>
                                </Box>
                            </Grid2>
                        </Grid2>
                        <Grid2 container spacing={ 2 } xs={ 12 } alignItems="flex-start">
                            <Grid2 xs={ 6 }>
                                <Select
                                    options={ timeStep === '30' ? TIME_24_HOURS_30_STEP : TIME_24_HOURS_15_STEP }
                                    name={ PRICE_FIELDS_KEYS.timeStart }
                                    control={ control }
                                    label={ PRICE_LABEL_DATA.timeStart }
                                    customError
                                />
                            </Grid2>
                            <Grid2 xs={ 6 }>
                                <Select
                                    options={ timeStep === '30' ? TIME_24_HOURS_30_STEP : TIME_24_HOURS_15_STEP }
                                    name={ PRICE_FIELDS_KEYS.timeEnd }
                                    control={ control }
                                    label={ PRICE_LABEL_DATA.timeEnd }
                                    customError
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                ) }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={ onClose }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    onClick={ handleSubmit(handleUpdatePrice) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdatePriceModal;
