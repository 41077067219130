import { ERROR_PRICE_TYPE_DELETE, SUCCESS_PRICE_TYPE_DELETE } from 'constants/notificationMessage';

import usePriceTypeDelete from 'shared/data-hook/priceType/delete/single/usePriceTypeDelete';
import { useStore } from 'store';
import { PriceTypeT } from 'types/PriceType';

import { useNotification } from '../../useNotification';

const useDeletePriceType = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deletePriceType } = usePriceTypeDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const priceTypeDelete = async (priceTypeId: PriceTypeT['id']) => {
        const response = await deletePriceType(priceTypeId);

        if (error || !response || !response.deletePriceType) {
            showError(ERROR_PRICE_TYPE_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PRICE_TYPE_DELETE);
        }
    };

    return {
        priceTypeDelete,
    };
};

export default useDeletePriceType;
