import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { COACH_HEADER_DATA } from 'page/Coach/domain/HeaderData';
import { COACH_CATEGORY_HEADER_DATA } from 'page/CoachCategory/domain/HeaderData';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const CoachCategoryList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;

    const { coachCategories, count } = useStore('coachCategory');

    return (
        <DataGrid
            rows={ coachCategories }
            rowCount={ count }
            columns={ COACH_CATEGORY_HEADER_DATA }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
            detailPanelColumns={ COACH_HEADER_DATA }
        />
    );
});

export default CoachCategoryList;
