import { PROPERTY_PARAMS_FULL_RETURNS, PROPERTY_PARAMS_SALES_AND_CASHBACK } from 'constants/propertyNames';

import React, { FC, useState } from 'react';
import { Control } from 'react-hook-form';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { observer } from 'mobx-react';
import {
    PRICE_SALES_ROUND_FILTER_OPTIONS_QUERY,
} from 'shared/graphql/query/priceSalesRound/PriceSalesRoundQuery';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';

import { DiscountAndCashbackParams } from './DiscountAndCashbackParams';
import { PriceMemberCardFormFields } from './formData';
import FullReturnForm from './FullReturnForm';

type PropsT = {
    control: Control<PriceMemberCardFormFields>;
};

const PriceMemberCardForm: FC<PropsT> = observer((props) => {
    const { control } = props;
    const [tab, setTab] = useState('sale');
    const { loading: loadingPriceSalesRounds } = useFetchFilterOptions(PRICE_SALES_ROUND_FILTER_OPTIONS_QUERY, 'priceSalesRound');

    return (
        <TabContext value={ tab }>
            <TabList>
                <Tab label={ PROPERTY_PARAMS_SALES_AND_CASHBACK } value="sale" onClick={ () => setTab('sale') } />
                <Tab label={ PROPERTY_PARAMS_FULL_RETURNS } value="return" onClick={ () => setTab('return') } />
            </TabList>
            <TabPanel value="sale">
                <DiscountAndCashbackParams control={ control } />
            </TabPanel>
            <TabPanel value="return">
                <FullReturnForm control={ control } loadingPriceSalesRounds={ loadingPriceSalesRounds } />
            </TabPanel>
        </TabContext>
    );
});

export default PriceMemberCardForm;
