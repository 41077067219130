import { PAGE_TITLE_PAYMENT_DOCUMENT } from 'constants/pageTitles';

import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import usePaymentDocuments from 'shared/data-hook/paymentDocument/fetch/fetchAll/usePaymentDocuments';
import { ORGANIZATION_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/organization/OrganizationQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import PaymentDocumentList from './components/PaymentDocumentList';
import { PAYMENT_DOCUMENT_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: paymentDocumentsData, loading: paymentDocumentsLoading, count: rowCount, fetchPaymentDocuments,
    } = usePaymentDocuments();
    const { loading: organizationFilterOptionsLoading } = useFetchFilterOptions(ORGANIZATION_FILTER_OPTIONS_QUERY, 'organization');
    const { setPaymentDocuments } = useStore('paymentDocument');
    const { refetchTrigger } = useStore('refetchTrigger');
    const loading = paymentDocumentsLoading || organizationFilterOptionsLoading;

    usePageTitle(PAGE_TITLE_PAYMENT_DOCUMENT);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchPaymentDocuments, refetchTrigger, PAYMENT_DOCUMENT_HEADER_DATA);

    useEffect(() => {
        setPaymentDocuments(paymentDocumentsData, rowCount);
    }, [paymentDocumentsData, paymentDocumentsLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel />
            <PaymentDocumentList
                loading={ loading }
                pageSize={ pageSize }
                page={ page }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
        </StyledPageWrap>
    );
});

export default List;
