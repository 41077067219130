import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PaymentDocumentT } from 'types/PaymentDocument';

const DTO = t.type({
    id: t.string,
    number: t.string,
    datetime: t.string,
    payments: t.array(t.type({
        id: t.string,
    })),
    organization: t.type({
        name: t.string,
    }),
});

export type PaymentDocumentDTOT = t.TypeOf<typeof DTO>;

export const PaymentDocumentDTO = tPromise.extendDecoder<PaymentDocumentDTOT, PaymentDocumentT>(
    DTO,
    ({
        id,
        number,
        datetime,
        payments,
        organization,
    }) => ({
        id,
        number,
        datetime: new Date(datetime),
        payments,
        organization,
    }),
) as t.Type<PaymentDocumentT, PaymentDocumentDTOT>;
