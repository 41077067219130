import * as React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    InputLabel, Skeleton, TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';

import { getErrorMessage } from '../../utils/getErrorMessage';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type OptionsType = {
    value: string | number;
    label: string;
}

type PropsT = {
    label: string;
    loading?: boolean;
    options: OptionsType[];
    size?: 'small';
    min?: number;
    selectedValues?: string[] | number[];
    labelSize?: number;
};

const MultipleSelect = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control, name, label, size, min = 1, loading, options, selectedValues, labelSize = 16,
    } = props;

    const preparedSelectedValues = selectedValues?.map((item: string | number) => ({
        value: item,
        label: options.find((option) => option.value === item)?.label ?? '',
    }));

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({ field, fieldState }) => (
                <Autocomplete
                    multiple
                    options={ options }
                    value={ preparedSelectedValues || [] }
                    disableCloseOnSelect
                    isOptionEqualToValue={ (option, value) => option.value === value.value }
                    getOptionLabel={ (option) => option.label }
                    renderOption={ (prop, option, { selected }) => (
                        <li { ...prop } key={ option.value }>
                            <Checkbox
                                icon={ icon }
                                checkedIcon={ checkedIcon }
                                style={ { marginRight: 8 } }
                                checked={ selected }
                            />
                            { option.label }
                        </li>
                    ) }
                    onChange={ (_, data) => {
                        const values = data.map((item) => item.value);

                        field.onChange(values);
                    } }
                    renderInput={ (params) => (
                        <>
                            { label && <InputLabel sx={ { fontSize: `${labelSize}px` } }>{ label }</InputLabel> }
                            { loading ? <Skeleton variant="rounded" height={ size ? 40 : 56 } />
                                : (
                                    <TextField
                                        { ...params }
                                        size={ size || 'medium' }
                                        error={ !!fieldState.error }
                                        helperText={
                                            getErrorMessage(fieldState, { min })
                                        }
                                    />
                                ) }
                        </>
                    ) }
                />
            ) }
        />
    );
};

export default MultipleSelect;
