import { gql } from '@apollo/client';

import { UpdatePriceRatePayloadT } from './UpdatePriceRateAction';

export type CheckUpdatePriceRatePayloadT = UpdatePriceRatePayloadT;

export type CheckUpdatePriceRateItems = Array<{
    id: string;
    serviceCategory: string;
    courtType: string;
    day: number;
    timeStart: string;
    timeEnd: string;
    priceSeasonId: string;
    priceTypeId: string;
}>

export type CheckUpdatePriceRateResponseT = {
    prices: CheckUpdatePriceRateItems;
    status: string;
}

export type CheckUpdatePriceRateVarT = {
    appId: string;
    payload: CheckUpdatePriceRatePayloadT;
};

export type CheckUpdatePriceRateT = {
    priceMultipleUpdateRateCheck: CheckUpdatePriceRateResponseT;
}

export const EMPTY_CHECK_UPDATE_PRICE_RATE_RESPONSE: CheckUpdatePriceRateResponseT = {
    prices: [],
    status: '',
};

export const CHECK_UPDATE_PRICE_RATE = gql`
    mutation CheckUpdatePriceRate($appId: String!, $payload: json!) {
        priceMultipleUpdateRateCheck(app_id: $appId, payload: $payload) {
            prices
            status
            reason
        }
    }
`;
