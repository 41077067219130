import { MODAL_TITLE_UPDATE_PRICE_RATE } from 'constants/modalTitles';

import React, { FC, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import Spinner from 'shared/component/Spinner/Spinner';
import useCoachCategories from 'shared/data-hook/coachCategory/fetch/fetchAll/useCoachCategories';
import useGroupViews from 'shared/data-hook/groupView/fetch/fetchAll/useGroupViews';
import usePriceTimePeriods from 'shared/data-hook/price/fetchPriceTimePeriods/usePriceTimePeriods';
import usePriceSalesRounds from 'shared/data-hook/priceSalesRound/fetch/fetchAll/usePriceSalesRounds';
import useCheckUpdatePriceRate
    from 'shared/hook/price/updatePriceRate/check/useCheckUpdatePriceRate';
import useUpdatePriceRate from 'shared/hook/price/updatePriceRate/update/useUpdatePriceRate';
import { useStore } from 'store';

import {
    UPDATE_PRICE_RATE_DEFAULT_VALUES,
    UPDATE_PRICE_RATE_FIELDS_KEYS,
    UPDATE_PRICE_RATE_VALIDATION_SCHEMA,
    UpdatePriceRateFormFields,
} from './formData';
import UpdatePriceRateCheckModal from './UpdatePriceRateCheckModal';
import UpdatePriceRateForm from './UpdatePriceRateForm';

type PropT = {
    priceType: string;
};

const UpdatePriceRate: FC<PropT> = observer((props) => {
    const { priceType } = props;
    const {
        isOpen, onClose, setPriceSalesRounds, setCoachCategories, setGroupViews, setTimePeriods,
    } = useStore('updatePriceRateModal');
    const { activePriceType, priceSeason } = useStore('price');
    const { fetchSalesRounds, data: priceSalesRoundsData, loading: priceSalesRoundsLoading } = usePriceSalesRounds();
    const { updatePriceRate, loading: updatePriceRateLoading } = useUpdatePriceRate();
    const { checkUpdatePriceRate, loading: checkUpdatePriceRateLoading } = useCheckUpdatePriceRate();
    const [resetTrigger, setResetTrigger] = useState<boolean>(false);
    const [openCheckModal, setOpenCheckModal] = useState<boolean>(false);
    const { fetchCoachCategories, data: coachCategoriesData, loading: coachCategoriesLoading } = useCoachCategories();
    const { fetchGroupViews, data: groupViewsData, loading: groupViewsLoading } = useGroupViews();
    const { fetchPriceTimePeriods, data: timePeriodsData, loading: timePeriodsLoading } = usePriceTimePeriods();
    const optionsDataLoading = priceSalesRoundsLoading;
    const { serviceApp } = useStore('serviceApp');
    const {
        control, setValue, handleSubmit, reset, resetField,
    } = useForm<UpdatePriceRateFormFields>({
        defaultValues: { ...UPDATE_PRICE_RATE_DEFAULT_VALUES, returnCurrency: serviceApp.fiatCurrencyTitle, saleCurrency: serviceApp.fiatCurrencyTitle },
        resolver: yupResolver(UPDATE_PRICE_RATE_VALIDATION_SCHEMA),
        mode: 'all',
    });
    const { field: coachCategoryIds } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.coachCategoryIds });
    const { field: groupViewIds } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.groupViewIds });
    const { field: courtTypeIds } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.courtTypeIds });
    const { field: categoryIds } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.categoryIds });
    const { field: days } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.days });

    const handleCloseModal = () => {
        onClose();
    };

    const handleResetForm = () => {
        reset();
        setResetTrigger(!resetTrigger);
    };

    const handleOpenCheckModal = () => {
        setOpenCheckModal(true);
    };

    const handleCloseCheckModal = () => {
        setOpenCheckModal(false);
    };

    const handleUpdatePriceRate = (data: UpdatePriceRateFormFields) => {
        updatePriceRate(data).then(() => {
            handleCloseCheckModal();
            handleCloseModal();
        });
    };

    const handleCheckUpdatePriceRate = (data: UpdatePriceRateFormFields) => {
        checkUpdatePriceRate(data).then(() => {
            handleOpenCheckModal();
        });
    };

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        fetchCoachCategories().then();
        fetchGroupViews().then();
        fetchSalesRounds(priceType).then();
    }, [isOpen]);

    useEffect(() => {
        resetField(UPDATE_PRICE_RATE_FIELDS_KEYS.timePeriods);
        fetchPriceTimePeriods(activePriceType?.id as string, priceSeason?.id as string, days.value, categoryIds.value, courtTypeIds.value, groupViewIds.value, coachCategoryIds.value).then();
    }, [days.value, categoryIds.value, courtTypeIds.value, groupViewIds.value, coachCategoryIds.value]);

    useEffect(() => {
        setCoachCategories(coachCategoriesData);
    }, [coachCategoriesData, coachCategoriesLoading]);

    useEffect(() => {
        setGroupViews(groupViewsData);
    }, [groupViewsData, groupViewsLoading]);

    useEffect(() => {
        setPriceSalesRounds(priceSalesRoundsData);
    }, [priceSalesRoundsData, priceSalesRoundsLoading]);

    useEffect(() => {
        setTimePeriods(timePeriodsData);
    }, [timePeriodsData, timePeriodsLoading]);

    useEffect(() => {
        handleResetForm();
    }, [activePriceType?.id]);

    return (
        <>
            <Modal open={ isOpen } maxWidth="md">
                <ModalTitle onClose={ onClose }>
                    { `${MODAL_TITLE_UPDATE_PRICE_RATE} "${activePriceType?.title}"` }
                </ModalTitle>
                <DialogContent dividers={ true }>
                    {
                        optionsDataLoading && <Spinner />
                    }
                    <UpdatePriceRateForm
                        timePeriodsLoading={ timePeriodsLoading }
                        control={ control }
                        setValue={ setValue }
                        resetTrigger={ resetTrigger }
                        resetField={ resetField }
                        coachCategoryIds={ coachCategoryIds }
                        groupViewIds={ groupViewIds }
                        courtTypeIds={ courtTypeIds }
                        categoryIds={ categoryIds }
                        days={ days }
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={ handleResetForm }>Сбросить</Button>
                    <Button variant="outlined" onClick={ onClose }>отмена</Button>
                    <LoadingButton
                        variant="contained"
                        onClick={ handleSubmit(handleCheckUpdatePriceRate) }
                        autoFocus
                        loading={ checkUpdatePriceRateLoading }
                    >
                        проверить
                    </LoadingButton>
                </DialogActions>
            </Modal>
            <UpdatePriceRateCheckModal
                openCheckModal={ openCheckModal }
                handleCloseCheckModal={ handleCloseCheckModal }
                handleSubmit={ handleSubmit }
                handleUpdatePriceRate={ handleUpdatePriceRate }
                updatePriceRateLoading={ updatePriceRateLoading }
            />
        </>
    );
});

export default UpdatePriceRate;
