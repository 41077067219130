import { OptionT } from 'types/Option';

const generateHours = (
    endHour: number,
    endMinute: 0 | 30,
    step: 15 | 30,
): OptionT[] => {
    const hours = [];

    for (let i = 0; i <= endHour; i++) {
        for (let j = 0; j < 60; j += step) {
            if (i === endHour && j > endMinute) break;

            const time = `${i.toString().padStart(2, '0')}:${j.toString().padStart(2, '0')}`;

            hours.push({ value: time, label: time });
        }
    }

    return hours;
};

export const TIME_24_HOURS_15_STEP = generateHours(24, 0, 15);
export const TIME_24_HOURS_30_STEP = generateHours(24, 0, 30);
export const TIME_23_HOURS_30_MINUTE_15_STEP = generateHours(23, 30, 15);
export const TIME_23_HOURS_30_MINUTE_30_STEP = generateHours(23, 30, 30);
