import { ERROR_COURT_CREATE, SUCCESS_COURT_CREATE } from 'constants/notificationMessage';

import { CourtFormFields } from 'page/Court/components/CourtSetting/components/formData';
import useCourtCreate from 'shared/data-hook/court/create/useCourtCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateCourt = () => {
    const { showError, showSuccess } = useNotification();
    const { loading, error, courtCreate } = useCourtCreate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createCourt = async (input: CourtFormFields) => {
        const response = await courtCreate(input);

        if (error || !response || !response.createCourt) {
            showError(ERROR_COURT_CREATE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_COURT_CREATE);
        }
    };

    return {
        createCourt,
        loading,
    };
};

export default useCreateCourt;
