import { API_DATE_FORMAT } from 'constants/date';

import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { camelizeKeys } from 'humps';
import {
    CHECK_PRICE_SEASON_CROSSING_QUERY, CheckPriceSeasonCrossingFields,
    CheckPriceSeasonCrossingResponseDataT, CheckPriceSeasonCrossingVarT,
} from 'shared/graphql/query/priceSeason/CheckPriceSeasonCrossingQuery';

import { PriceSeasonCreateDataT } from './PriceSeasonCheckCrossingData';

const usePriceSeasonCheckCrossing = (): PriceSeasonCreateDataT => {
    const [check, { loading, error }] = useLazyQuery<CheckPriceSeasonCrossingResponseDataT, CheckPriceSeasonCrossingVarT>(
        CHECK_PRICE_SEASON_CROSSING_QUERY,
    );

    const checkSeasonCrossing = async (input: CheckPriceSeasonCrossingFields) => {
        try {
            const { data } = await check({
                variables: {
                    startAt: dayjs(input.startAt).format(API_DATE_FORMAT),
                    finishAt: dayjs(input.finishAt).format(API_DATE_FORMAT),
                },
            });

            return camelizeKeys(data) as CheckPriceSeasonCrossingResponseDataT;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        checkSeasonCrossing,
        loading,
        error,
    };
};

export default usePriceSeasonCheckCrossing;
