import { useMutation } from '@apollo/client';
import { PriceTypeFormFields } from 'page/PriceType/components/formData';
import {
    PRICE_TYPE_CREATE,
    PriceTypeCreateResponseDataT,
    PriceTypeCreateVar,
} from 'shared/graphql/mutation/priceType/PriceTypeCreate';

import { PriceTypeCreateDataT } from './PriceTypeCreateData';

const usePriceTypeCreate = (): PriceTypeCreateDataT => {
    const [create, { loading, error }] = useMutation<PriceTypeCreateResponseDataT, PriceTypeCreateVar>(
        PRICE_TYPE_CREATE,
    );

    const createAction = async (input: PriceTypeFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        title: input.title,
                        position: input.position,
                        is_active: input.isActive,
                        is_group_available: input.isGroupAvailable,
                        is_coach_available: input.isCoachAvailable,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        loading,
        error,
        priceTypeCreate: createAction,
    };
};

export default usePriceTypeCreate;
