import { useStore } from 'store';

type ReturnT = {
    readonly currencyHeaderName: (headerName?: string) => string;
    readonly divisibleHeaderName: (headerName?: string) => string;
};

export const useHeaderNameServiceApp = (): ReturnT => {
    const { fiatCurrencyTitle } = useStore('serviceApp').serviceApp;

    const currencyHeaderName = (headerName?: string) => `Платеж в ${headerName || fiatCurrencyTitle} по умолчанию`;

    const divisibleHeaderName = (headerName?: string) => `Минимальный платеж в ${headerName || fiatCurrencyTitle}`;

    return { currencyHeaderName, divisibleHeaderName };
};
