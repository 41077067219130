import { useEffect } from 'react';
import usePriceSeason from 'shared/data-hook/priceSeason/fetch/fetchOne/usePriceSeason';
import { useStore } from 'store';

const useFetchPriceSeason = () => {
    const { fetchPriceSeason, loading } = usePriceSeason();
    const { setPriceSeason } = useStore('priceSeason');

    const fetch = (id: string) => {
        useEffect(() => {
            if (!id) {
                return;
            }

            fetchPriceSeason(id).then((priceSeason) => {
                setPriceSeason(priceSeason);
            });
        }, [id]);
    };

    return {
        fetch,
        loading,
    };
};

export default useFetchPriceSeason;
