import { DATA_GRID_PAGE_SIZE_SMALL } from 'constants/dataGrid';
import { DATE_TIME_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    PROPERTY_ACTIONS,
    PROPERTY_DATETIME_CREATION,
    PROPERTY_DOCUMENT_NUMBER,
    PROPERTY_ORGANIZATION,
} from 'constants/propertyNames';

import { Link } from 'react-router-dom';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { IconButton, Tooltip } from '@mui/material';
import { GridLinkOperator, GridRenderCellParams } from '@mui/x-data-grid-premium';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import { CustomHeaderData } from 'types/DataGrid';

const renderActionCell = (props: GridRenderCellParams) => {
    const { row } = props;
    const iconLabel = 'Посмотреть платеж';

    return (
        <Link
            to={ `/payment?filter=paymentDocument%3Bcontains%3B${row.number}&linkOperator=${GridLinkOperator.And}&page=1&pageSize=${DATA_GRID_PAGE_SIZE_SMALL}` }
            target="_blank"
        >
            <Tooltip title={ iconLabel } placement="left">
                <IconButton>
                    <ReceiptLongIcon color="info" />
                </IconButton>
            </Tooltip>
        </Link>
    );
};

export const PAYMENT_DOCUMENT_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_DOCUMENT_NUMBER,
        field: 'number',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['number'],
        hasuraSortFields: ['number'],
    },
    {
        headerName: PROPERTY_DATETIME_CREATION,
        field: 'datetime',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        hasuraFilterFields: ['datetime'],
        hasuraSortFields: ['datetime'],
        renderCell: (params) => renderDateToGrid(params.row?.datetime, DATE_TIME_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_ORGANIZATION,
        field: 'organization',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['organization', 'id'],
        hasuraSortFields: ['organization', 'name'],
        renderCell: (params) => params.row?.organization.name,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        renderCell: renderActionCell,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
    },
];
