import { useLazyQuery } from '@apollo/client';
import { CoachDTO } from 'shared/data-hook/coach/fetch/DTO/CoachDTO';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { COACH_QUERY, CoachResponseT } from 'shared/graphql/query/coach/CoachQuery';
import { CoachT, EMPTY_COACH_RESULT } from 'types/Coach';

import { CoachDataT } from './CoachData';

const useCoach = (): CoachDataT => {
    const [fetch, { loading, error }] = useLazyQuery<CoachResponseT>(COACH_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchCoach = async (id: string): Promise<CoachT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(CoachDTO, EMPTY_COACH_RESULT, (coach) => coach, data?.coach);
        } catch {
            return EMPTY_COACH_RESULT;
        }
    };

    return {
        fetchCoach,
        loading,
        error,
    };
};

export default useCoach;
