import { useMutation } from '@apollo/client';
import {
    PRICE_SEASON_DELETE,
    PriceDeleteSeasonResponseDataT,
    PriceDeleteSeasonVarT,
} from 'shared/graphql/mutation/priceSeason/PriceSeasonDelete';
import { PriceSeasonT } from 'types/PriceSeason';

import { SeasonDeleteDataT } from './PriceSeasonDeleteData';

const usePriceSeasonDelete = (): SeasonDeleteDataT => {
    const [priceSeasonDelete, { loading, error }] = useMutation<
        PriceDeleteSeasonResponseDataT,
        PriceDeleteSeasonVarT
    >(PRICE_SEASON_DELETE);

    const deleteAction = async (priceSeasonId: PriceSeasonT['id']) => {
        try {
            const { data } = await priceSeasonDelete({
                variables: {
                    id: priceSeasonId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deletePriceSeason: deleteAction,
        loading,
        error,
    };
};

export default usePriceSeasonDelete;
