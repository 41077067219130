import { useEffect } from 'react';
import usePaymentMethod from 'shared/data-hook/paymentMethod/fetch/fetchOne/usePaymentMethod';
import { useStore } from 'store';

const useFetchPaymentMethod = () => {
    const { fetchPaymentMethod, loading } = usePaymentMethod();
    const { updatedItemId, setPaymentMethod } = useStore('updatePaymentMethod');

    const fetch = () => {
        useEffect(() => {
            if (!updatedItemId) {
                return;
            }

            fetchPaymentMethod(updatedItemId).then((paymentMethod) => {
                setPaymentMethod(paymentMethod);
            });
        }, [updatedItemId]);
    };

    return {
        fetch,
        loading,
    };
};

export default useFetchPaymentMethod;
