import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PriceSeasonsT } from 'types/PriceSeason';

import { PriceSeasonDTO } from './PriceSeasonDTO';

const DTO = t.type({
    priceSeasons: t.array(PriceSeasonDTO),
    priceSeasonsCount: AggregateDTO,
});

type SeasonsDTOT = t.TypeOf<typeof DTO>;

export const PriceSeasonsDTO = tPromise.extendDecoder<SeasonsDTOT, PriceSeasonsT>(
    DTO,
    ({
        priceSeasons,
        priceSeasonsCount,
    }) => ({
        priceSeasons,
        priceSeasonsCount,
    }),
) as t.Type<PriceSeasonsT, SeasonsDTOT>;
