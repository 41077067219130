import { PROPERTY_IS_ACTIVE, PROPERTY_POSITION, PROPERTY_TITLE } from 'constants/propertyNames';

import {
    number, object, string,
} from 'yup';

export type PriceTypeFormFields = {
    title: string;
    position: number;
    isActive: boolean;
    isGroupAvailable: boolean;
    isCoachAvailable: boolean;
};

export const PRICE_TYPE_DEFAULT_VALUES: PriceTypeFormFields = {
    title: '',
    position: 0,
    isActive: false,
    isGroupAvailable: false,
    isCoachAvailable: false,
};

export const CREATE_PRICE_TYPE_DEFAULT_VALUES = { ...PRICE_TYPE_DEFAULT_VALUES };

export enum PRICE_TYPE_FIELDS_KEYS {
    title = 'title',
    position = 'position',
    isActive = 'isActive',
    isGroupAvailable = 'isGroupAvailable',
    isCoachAvailable = 'isCoachAvailable',
}

type PriceTypeLabelDataT = Record<keyof PriceTypeFormFields, string>;

export const PRICE_TYPE_LABEL_DATA: PriceTypeLabelDataT = {
    title: PROPERTY_TITLE,
    isActive: PROPERTY_IS_ACTIVE,
    position: PROPERTY_POSITION,
    isGroupAvailable: 'Доступен параметр категория группы',
    isCoachAvailable: 'Доступен параметр категория тренера',
};

export const PRICE_TYPE_VALIDATION_SCHEMA = object().shape({
    title: string().required(),
    position: number().integer(`Поле "${PRICE_TYPE_LABEL_DATA.position}" должно целым числом`).required(),
}).required();
