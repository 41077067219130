import { withBreakpoint } from 'shared/utils/WithBreakpoint';
import styled, { css } from 'styled-components';

export const Container = styled.div`
    padding: 40px 0 0;

    ${withBreakpoint('lg')(css`
        padding: 40px 30px 50px;
    `)}

    ${withBreakpoint('sm')(css`
        padding: 30px 20px 50px;
    `)}
`;

export const Wrapper = styled.div`
    max-width: calc(100vw - 240px);
`;
