import { FC } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import DateRangePicker from 'shared/component/Form/DateRangePicker';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';

import {
    PRICE_SEASON_DEFAULT_VALUES, PRICE_SEASON_FIELDS_KEYS, PRICE_SEASON_LABEL_DATA, SeasonFormFields,
} from './formData';

type PropsT = {
    control: Control<SeasonFormFields>;
    loading?: boolean;
    setValue: UseFormSetValue<SeasonFormFields>;
};

const PriceSeasonForm: FC<PropsT> = (props) => {
    const {
        control, loading, setValue,
    } = props;

    return (
        <Box my={ 1 }>
            <Grid2 container direction="row" columnSpacing={ 1.5 } sx={ { marginBottom: '12px' } }>
                <Grid2 xs={ 4 }>
                    <TextField
                        key={ PRICE_SEASON_FIELDS_KEYS.title }
                        loading={ loading }
                        name={ PRICE_SEASON_FIELDS_KEYS.title as keyof SeasonFormFields }
                        type={ typeof PRICE_SEASON_DEFAULT_VALUES[PRICE_SEASON_FIELDS_KEYS.title as keyof SeasonFormFields] }
                        control={ control }
                        label={ PRICE_SEASON_LABEL_DATA[PRICE_SEASON_FIELDS_KEYS.title as keyof SeasonFormFields] }
                    />
                </Grid2>
                <Grid2 xs={ 8 }>
                    <DateRangePicker
                        setValue={ setValue }
                        nameStart={ PRICE_SEASON_FIELDS_KEYS.startAt }
                        nameFinish={ PRICE_SEASON_FIELDS_KEYS.finishAt }
                        control={ control }
                        loading={ loading }
                    />
                </Grid2>
            </Grid2>
            <Grid2 container>
                <Grid2 xs={ 3 }>
                    <Switch
                        loading={ loading }
                        key={ PRICE_SEASON_FIELDS_KEYS.isActive }
                        name={ PRICE_SEASON_FIELDS_KEYS.isActive as keyof SeasonFormFields }
                        control={ control }
                        label={ PRICE_SEASON_LABEL_DATA[PRICE_SEASON_FIELDS_KEYS.isActive as keyof SeasonFormFields] }
                    />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default PriceSeasonForm;
