import { createContext, useContext } from 'react';

import ClientViewStore from './client/ClientViewStore';
import CoachesInGroupStore from './coach/CoachesInGroupStore';
import CoachHoursStore from './coach/CoachHoursStore';
import CoachStore from './coach/CoachStore';
import CoachCategoryStore from './coachCategory/CoachCategoryStore';
import CourtCategoryStore from './court/CourtCategoryStore';
import CourtStore from './court/CourtStore';
import CourtTypeStore from './court/CourtTypeStore';
import CourtVideoAnalyticsStore from './court/CourtVideoAnalyticsStore';
import GroupViewStore from './groupView/GroupViewStore';
import ImplementationDocumentStore from './implementation/ImplementationDocumentStore';
import ImplementationStore from './implementation/ImplementationStore';
import MemberCardStore from './memberCard/MemberCardStore';
import MemberCardTypeStore from './memberCard/MemberCardTypeStore';
import CreateCoachCategoryModal from './modal/CreateCoachCategoryModal';
import CreateCoachModal from './modal/CreateCoachModal';
import CreateCourtCategoryModal from './modal/CreateCourtCategoryModal';
import CreateCourtModal from './modal/CreateCourtModal';
import CreateCourtTypeModal from './modal/CreateCourtTypeModal';
import CreateGroupViewModal from './modal/CreateGroupViewModal';
import CreateItemModal from './modal/CreateItemModal';
import CreateMemberCardModal from './modal/CreateMemberCardModal';
import CreateOrganizationModal from './modal/CreateOrganization';
import CreateOrganizationLocationModal from './modal/CreateOrganizationLocation';
import CreatePaymentMethodModal from './modal/CreatePaymentMethodModal';
import CreatePaymentTypeModal from './modal/CreatePaymentTypeModal';
import CreatePriceModal from './modal/CreatePriceModal';
import { CreatePriceSalesRoundModal } from './modal/CreatePriceSalesRoundModal';
import CreateScheduleModal from './modal/CreateScheduleModal';
import CreateServiceCategoryModal from './modal/CreateServiceCategory';
import DeleteItemModal from './modal/DeleteItemModal';
import DuplicateItemModal from './modal/DuplicateItemModal';
import UpdateCoachCategoryModal from './modal/UpdateCoachCategoryModal';
import UpdateCoachModal from './modal/UpdateCoachModal';
import UpdateCourtCategoryModal from './modal/UpdateCourtCategoryModal';
import UpdateCourtModal from './modal/UpdateCourtModal';
import UpdateCourtTypeModal from './modal/UpdateCourtTypeModal';
import UpdateGroupViewModal from './modal/UpdateGroupViewModal';
import UpdateItemModal from './modal/UpdateItemModal';
import UpdateMemberCardModal from './modal/UpdateMemberCardModal';
import UpdateOrganizationModal from './modal/UpdateOrganization';
import UpdateOrganizationLocationModal from './modal/UpdateOrganizationLocation';
import UpdatePaymentMethodModal from './modal/UpdatePaymentMethodModal';
import UpdatePaymentTypeModal from './modal/UpdatePaymentTypeModal';
import UpdatePriceMemberCardModal from './modal/UpdatePriceMemberCardModal';
import UpdatePriceModal from './modal/UpdatePriceModal';
import UpdatePriceRateModal from './modal/UpdatePriceRateModal';
import { UpdatePriceSalesRoundModal } from './modal/UpdatePriceSalesRoundModal';
import UpdateServiceCategoryModal from './modal/UpdateServiceCategoryModal';
import OrganizationLocationStore from './organization/OrganizationLocationStore';
import OrganizationStore from './organization/OrganizationStore';
import PaymentDocumentStore from './payment/PaymentDocumentStore';
import PaymentMethodStore from './payment/PaymentMethodStore';
import PaymentStore from './payment/PaymentStore';
import PaymentTypeStore from './payment/PaymentTypeStore';
import PriceMemberCardsStore from './price/PriceMemberCardsStore';
import PriceSalesRoundStore from './price/PriceSalesRoundStore';
import PriceSeasonStore from './price/PriceSeasonStore';
import PriceStore from './price/PriceStore';
import PriceTypeStore from './price/PriceTypeStore';
import ScheduleSlotReserveStore from './schedule/ScheduleSlotReserveStore';
import ServiceAppStore from './serviceApp/ServiceAppStore';
import UserStore from './user/UserStore';
import BulkOperationStore from './BulkOperationStore';
import FilterOptionsStore from './FilterOptionsStore';
import PageTitleStore from './PageTitleStore';
import RefetchTriggerStore from './RefetchTriggerStore';
import ServiceCategoryStore from './ServiceCategoryStore';

type StoreInstanceT = {
    user: UserStore;
    price: PriceStore;
    priceSeason: PriceSeasonStore;
    priceTypes: PriceTypeStore;
    priceSalesRound: PriceSalesRoundStore;
    createPriceSalesRound: CreatePriceSalesRoundModal;
    updatePriceSalesRound: UpdatePriceSalesRoundModal;
    pageTitleStore: PageTitleStore;
    deleteItemModal: DeleteItemModal;
    duplicateItemModal: DuplicateItemModal;
    createItemModal: CreateItemModal;
    updateItemModal: UpdateItemModal;
    updatePriceRateModal: UpdatePriceRateModal;
    createPriceModal: CreatePriceModal;
    createScheduleModal: CreateScheduleModal;
    updatePriceMemberCardModal: UpdatePriceMemberCardModal;
    createMemberCardModal: CreateMemberCardModal;
    updateMemberCardModal: UpdateMemberCardModal;
    memberCard: MemberCardStore;
    memberCardType: MemberCardTypeStore;
    serviceCategory: ServiceCategoryStore;
    updateServiceCategoryModal: UpdateServiceCategoryModal;
    createServiceCategoryModal: CreateServiceCategoryModal;
    priceMemberCards: PriceMemberCardsStore;
    bulkOperation: BulkOperationStore;
    serviceApp: ServiceAppStore;
    scheduleSlotReserve: ScheduleSlotReserveStore;
    implementationDocument: ImplementationDocumentStore;
    implementation: ImplementationStore;
    courtType: CourtTypeStore;
    updateCourtTypeModal: UpdateCourtTypeModal;
    createCourtTypeModal: CreateCourtTypeModal;
    court: CourtStore;
    coach: CoachStore;
    updateCoachModal: UpdateCoachModal;
    createCoachModal: CreateCoachModal;
    coachCategory: CoachCategoryStore;
    updateCoachCategoryModal: UpdateCoachCategoryModal;
    createCoachCategoryModal: CreateCoachCategoryModal;
    updateCourtModal: UpdateCourtModal;
    createCourtModal: CreateCourtModal;
    courtCategory: CourtCategoryStore;
    updateCourtCategoryModal: UpdateCourtCategoryModal;
    createCourtCategoryModal: CreateCourtCategoryModal;
    courtVideoAnalytics: CourtVideoAnalyticsStore;
    organization: OrganizationStore;
    createOrganizationModal: CreateOrganizationModal;
    updateOrganizationModal: UpdateOrganizationModal;
    organizationLocation: OrganizationLocationStore;
    createOrganizationLocationModal: CreateOrganizationLocationModal;
    updateOrganizationLocationModal: UpdateOrganizationLocationModal;
    filterOptions: FilterOptionsStore;
    clientView: ClientViewStore;
    refetchTrigger: RefetchTriggerStore;
    coachesInGroup: CoachesInGroupStore;
    coachHours: CoachHoursStore;
    payment: PaymentStore;
    paymentDocument: PaymentDocumentStore;
    paymentType: PaymentTypeStore;
    updatePaymentType: UpdatePaymentTypeModal;
    createPaymentType: CreatePaymentTypeModal;
    paymentMethod: PaymentMethodStore;
    updatePaymentMethod: UpdatePaymentMethodModal;
    createPaymentMethod: CreatePaymentMethodModal;
    updatePrice: UpdatePriceModal;
    groupView: GroupViewStore;
    createGroupViewModal: CreateGroupViewModal;
    updateGroupViewModal: UpdateGroupViewModal;
};

export type StoreT = {
    instance: StoreInstanceT | null;
};

const store: StoreT = { instance: null };

export function getStore(reinitialize = false): StoreInstanceT {
    if (store.instance === null || reinitialize) {
        store.instance = {
            user: new UserStore(),
            price: new PriceStore(),
            priceSeason: new PriceSeasonStore(),
            priceTypes: new PriceTypeStore(),
            priceSalesRound: new PriceSalesRoundStore(),
            createPriceSalesRound: new CreatePriceSalesRoundModal(),
            updatePriceSalesRound: new UpdatePriceSalesRoundModal(),
            pageTitleStore: new PageTitleStore(),
            deleteItemModal: new DeleteItemModal(),
            createItemModal: new CreateItemModal(),
            updateItemModal: new UpdateItemModal(),
            updatePriceRateModal: new UpdatePriceRateModal(),
            createPriceModal: new CreatePriceModal(),
            duplicateItemModal: new DuplicateItemModal(),
            updatePriceMemberCardModal: new UpdatePriceMemberCardModal(),
            createMemberCardModal: new CreateMemberCardModal(),
            updateMemberCardModal: new UpdateMemberCardModal(),
            createScheduleModal: new CreateScheduleModal(),
            memberCard: new MemberCardStore(),
            serviceCategory: new ServiceCategoryStore(),
            updateServiceCategoryModal: new UpdateServiceCategoryModal(),
            createServiceCategoryModal: new CreateServiceCategoryModal(),
            memberCardType: new MemberCardTypeStore(),
            priceMemberCards: new PriceMemberCardsStore(),
            bulkOperation: new BulkOperationStore(),
            serviceApp: new ServiceAppStore(),
            scheduleSlotReserve: new ScheduleSlotReserveStore(),
            implementationDocument: new ImplementationDocumentStore(),
            implementation: new ImplementationStore(),
            courtType: new CourtTypeStore(),
            updateCourtTypeModal: new UpdateCourtTypeModal(),
            createCourtTypeModal: new CreateCourtTypeModal(),
            court: new CourtStore(),
            coach: new CoachStore(),
            updateCoachModal: new UpdateCoachModal(),
            createCoachModal: new CreateCoachModal(),
            coachCategory: new CoachCategoryStore(),
            updateCoachCategoryModal: new UpdateCoachCategoryModal(),
            createCoachCategoryModal: new CreateCoachCategoryModal(),
            updateCourtModal: new UpdateCourtModal(),
            createCourtModal: new CreateCourtModal(),
            courtCategory: new CourtCategoryStore(),
            updateCourtCategoryModal: new UpdateCourtCategoryModal(),
            createCourtCategoryModal: new CreateCourtCategoryModal(),
            courtVideoAnalytics: new CourtVideoAnalyticsStore(),
            organization: new OrganizationStore(),
            createOrganizationModal: new CreateOrganizationModal(),
            updateOrganizationModal: new UpdateOrganizationModal(),
            organizationLocation: new OrganizationLocationStore(),
            createOrganizationLocationModal: new CreateOrganizationLocationModal(),
            updateOrganizationLocationModal: new UpdateOrganizationLocationModal(),
            filterOptions: new FilterOptionsStore(),
            clientView: new ClientViewStore(),
            refetchTrigger: new RefetchTriggerStore(),
            coachesInGroup: new CoachesInGroupStore(),
            coachHours: new CoachHoursStore(),
            paymentDocument: new PaymentDocumentStore(),
            payment: new PaymentStore(),
            paymentType: new PaymentTypeStore(),
            updatePaymentType: new UpdatePaymentTypeModal(),
            createPaymentType: new CreatePaymentTypeModal(),
            paymentMethod: new PaymentMethodStore(),
            updatePaymentMethod: new UpdatePaymentMethodModal(),
            createPaymentMethod: new CreatePaymentMethodModal(),
            updatePrice: new UpdatePriceModal(),
            groupView: new GroupViewStore(),
            createGroupViewModal: new CreateGroupViewModal(),
            updateGroupViewModal: new UpdateGroupViewModal(),
        };
    }

    return store.instance;
}

export const Context = createContext({} as StoreInstanceT);

export function useGlobalStore(): StoreInstanceT {
    return useContext(Context);
}

export function useStore<K extends keyof StoreInstanceT>(storeName: K): StoreInstanceT[K] {
    return useGlobalStore()[storeName];
}
