import { PROPERTY_IS_ACTIVE, PROPERTY_POSITION, PROPERTY_TITLE } from 'constants/propertyNames';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreatePaymentMethod from 'shared/hook/paymentMethod/create/useCreatePaymentMethod';
import { useStore } from 'store';

import {
    PAYMENT_METHOD_DEFAULT_VALUES,
    PAYMENT_METHOD_FIELD_KEYS,
    PAYMENT_METHOD_VALIDATION_SCHEMA,
    PaymentMethodFormFields,
} from './formData';

const CreatePaymentMethodSettingModal: FC = observer(() => {
    const { isOpen, onClose } = useStore('createPaymentMethod');
    const { control, handleSubmit, formState: { isDirty, isValid } } = useForm<PaymentMethodFormFields>({
        mode: 'onBlur',
        defaultValues: PAYMENT_METHOD_DEFAULT_VALUES,
        resolver: yupResolver(PAYMENT_METHOD_VALIDATION_SCHEMA),
    });
    const { createPaymentMethod, loading } = useCreatePaymentMethod();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const handleCreatePaymentMethod = (input: PaymentMethodFormFields) => {
        createPaymentMethod(input).then(() => {
            onClose();
            toggleRefetchTrigger();
        });
    };

    return (
        <Modal open={ isOpen } maxWidth="sm">
            <ModalTitle onClose={ onClose }>Создание способа оплаты</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 spacing={ 2 } container padding={ 2 }>
                    <Grid2 xs={ 12 }>
                        <TextField
                            name={ PAYMENT_METHOD_FIELD_KEYS.title as keyof PaymentMethodFormFields }
                            control={ control }
                            label={ PROPERTY_TITLE }
                            type="text"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <TextField
                            name={ PAYMENT_METHOD_FIELD_KEYS.position as keyof PaymentMethodFormFields }
                            control={ control }
                            label={ PROPERTY_POSITION }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <Switch
                            name={ PAYMENT_METHOD_FIELD_KEYS.isActive as keyof PaymentMethodFormFields }
                            control={ control }
                            label={ PROPERTY_IS_ACTIVE }
                            type="boolean"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ onClose }>Отмена</Button>
                <LoadingButton
                    onClick={ handleSubmit(handleCreatePaymentMethod) }
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ loading }
                    disabled={ !isDirty || !isValid }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>

    );
});

export default CreatePaymentMethodSettingModal;
