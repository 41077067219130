import {
    PROPERTY_CATEGORY,
    PROPERTY_COACH,
    PROPERTY_COURT_TYPE,
    PROPERTY_DAY,
    PROPERTY_GROUP,
} from 'constants/propertyNames';

import { getValidationTimePeriod } from 'shared/validation/getValidationTimePeriod';
import { PriceTypeT } from 'types/PriceType';
import { array, object } from 'yup';

export type PricesFormFields = {
    days: number[];
    time: Array<{
        timeStart: string;
        timeEnd: string;
    }>;
    categoryIds: string[];
    courtTypeIds: string[];
    coachCategoryIds: string[];
    groupViewIds: string[];
};

export const PRICES_DEFAULT_VALUES: PricesFormFields = {
    days: [],
    time: [
        {
            timeStart: '',
            timeEnd: '',
        },
    ],
    categoryIds: [],
    courtTypeIds: [],
    coachCategoryIds: [],
    groupViewIds: [],
};

export enum PRICES_FIELDS_KEYS {
    days = 'days',
    timeStart = 'timeStart',
    timeEnd = 'timeEnd',
    categoryIds = 'categoryIds',
    courtTypeIds = 'courtTypeIds',
    coachCategoryIds = 'coachCategoryIds',
    groupViewIds = 'groupViewIds',
}

export const PRICE_LABEL_DATA = {
    days: PROPERTY_DAY,
    timeStart: 'Время начала',
    timeEnd: 'Время окончания',
    categoryIds: PROPERTY_CATEGORY,
    courtTypeIds: PROPERTY_COURT_TYPE,
    coachCategoryIds: PROPERTY_COACH,
    groupViewIds: PROPERTY_GROUP,
};

export const getPricesValidationSchema = (activePriceType: PriceTypeT | undefined) => (
    object().shape({
        days: array().min(1).required(),
        time: array().of(getValidationTimePeriod),
        categoryIds: array().min(1).required(),
        courtTypeIds: array().min(1).required(),
        coachCategoryIds: activePriceType?.isCoachAvailable ? array().min(1).required() : array(),
        groupViewIds: activePriceType?.isGroupAvailable ? array().min(1).required() : array(),
    }).required()
);
