import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceSeasonT } from 'types/PriceSeason';

const DTO = t.type({
    id: t.string,
    title: t.string,
    start_at: t.string,
    finish_at: t.string,
    is_active: t.boolean,
});

export type SeasonDTOT = t.TypeOf<typeof DTO>;

export const PriceSeasonDTO = tPromise.extendDecoder<SeasonDTOT, PriceSeasonT>(
    DTO,
    ({
        id, title, start_at, finish_at, is_active,
    }) => {
        return {
            id,
            title,
            startAt: new Date(start_at),
            finishAt: new Date(finish_at),
            isActive: is_active,
        };
    },
) as t.Type<PriceSeasonT, SeasonDTOT>;
