import { useMutation } from '@apollo/client';
import { PaymentTypeFormFields } from 'page/PaymentSetting/components/PaymentType/components/formData';
import {
    PAYMENT_TYPE_UPDATE,
    PaymentTypeUpdateResponseDataT,
    PaymentTypeUpdateVarT,
} from 'shared/graphql/mutation/paymentType/PaymentTypeUpdate';
import { PaymentTypeT } from 'types/PaymentType';

import { UpdatePaymentTypeDataT } from './UpdatePaymentTypeData';

const usePaymentTypeUpdate = (): UpdatePaymentTypeDataT => {
    const [update, { loading, error }] = useMutation<
            PaymentTypeUpdateResponseDataT,
            PaymentTypeUpdateVarT
        >(PAYMENT_TYPE_UPDATE);

    const updateAction = async (input: PaymentTypeFormFields, paymentTypeId: PaymentTypeT['id']) => {
        try {
            const { data } = await update({
                variables: {
                    id: paymentTypeId,
                    set: {
                        title: input.title,
                        position: input.position,
                        is_active: input.isActive,
                        default_bonus_payment: input.defaultBonusPayment,
                        default_fiat_payment: input.defaultFiatPayment,
                        fiat_payment_divisible: input.fiatPaymentDivisible,
                        bonus_payment_divisible: input.bonusPaymentDivisible,
                        payment_method_id: input.paymentMethodId,
                        should_confirm_by_sms: input.shouldConfirmBySms,
                        allow_negative_payments: input.allowNegativePayments,
                    },
                },
            });

            return data;
            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        paymentTypeUpdate: updateAction,
        loading,
        error,
    };
};

export default usePaymentTypeUpdate;
