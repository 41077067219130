import {
    PROPERTY_ACTIONS,
    PROPERTY_END,
    PROPERTY_SEASON,
    PROPERTY_START, PROPERTY_STATUS,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import CreateScheduleButton from 'shared/component/Button/CreateScheduleButton';
import DeleteButton from 'shared/component/Button/DeleteButton';
import DuplicateButton from 'shared/component/Button/DuplicateButton';
import EditButton from 'shared/component/Button/EditButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import useDeletePriceSeason from 'shared/hook/priceSeason/delete/useDeletePriceSeason';
import useDuplicatePriceSeason from 'shared/hook/priceSeason/duplicate/useDuplicatePriceSeason';
import useScheduleCreate from 'shared/hook/schedule/useScheduleCreate';
import { CustomHeaderData } from 'types/DataGrid';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row } = props;
    const { priceSeasonDelete } = useDeletePriceSeason();
    const { priceSeasonDuplicate } = useDuplicatePriceSeason();
    const { createSchedule, loading } = useScheduleCreate();

    return (
        <>
            <EditButton id={ row.id } />
            <DeleteButton id={ row.id } title={ row.title } actionDelete={ priceSeasonDelete } />
            <DuplicateButton id={ row.id } title={ row.title } actionDuplicate={ priceSeasonDuplicate } />
            <CreateScheduleButton id={ row.id } title={ row.title } actionCreateSchedule={ createSchedule } loading={ loading } />
        </>
    );
};

export const PRICE_SEASON_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_SEASON,
        field: 'title',
        flex: 2,
        minWidth: 200,
        type: 'string',
        renderCell: (params) => {
            const navigate = useNavigate();

            return (
                <MuiLink onClick={ () => navigate(`${params.id}`) } underline="hover" sx={ { cursor: 'pointer' } }>
                    { params.value!.toString() }
                </MuiLink>
            );
        },
    },
    {
        headerName: PROPERTY_START,
        field: 'startAt',
        type: 'date',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
    {
        headerName: PROPERTY_END,
        field: 'finishAt',
        type: 'date',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
    {
        headerName: PROPERTY_STATUS,
        field: 'isActive',
        renderCell: isActiveIcon,
        flex: 0.6,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
];
