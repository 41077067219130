import { ERROR_PAYMENT_TYPE_UPDATE, SUCCESS_PAYMENT_TYPE_UPDATE } from 'constants/notificationMessage';

import { PaymentTypeFormFields } from 'page/PaymentSetting/components/PaymentType/components/formData';
import usePaymentTypeUpdate from 'shared/data-hook/paymentType/update/usePaymentTypeUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { PaymentTypeT } from 'types/PaymentType';

const useUpdatePaymentType = () => {
    const { showError, showSuccess } = useNotification();
    const { error, loading, paymentTypeUpdate } = usePaymentTypeUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updatePaymentType = async (data: PaymentTypeFormFields, paymentTypeId: PaymentTypeT['id']) => {
        const response = await paymentTypeUpdate(data, paymentTypeId);

        if (error || !response || !response.updatePaymentType) {
            showError(ERROR_PAYMENT_TYPE_UPDATE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PAYMENT_TYPE_UPDATE);
        }
    };

    return {
        updatePaymentType,
        loading,
    };
};

export default useUpdatePaymentType;
