import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { PAYMENT_TYPE_QUERY, PaymentTypeResponseT } from 'shared/graphql/query/paymentType/PaymentTypeQuery';
import { EMPTY_PAYMENT_TYPE_RESULT } from 'types/PaymentType';

import { PaymentTypeDTO } from '../DTO/PaymentTypeDTO';

import { PaymentTypeDataT } from './PaymentTypeData';

const usePaymentType = (): PaymentTypeDataT => {
    const [fetch, { loading, error }] = useLazyQuery<PaymentTypeResponseT>(PAYMENT_TYPE_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchPaymentType = async (id: string) => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(PaymentTypeDTO, EMPTY_PAYMENT_TYPE_RESULT, (paymentType) => paymentType, data?.paymentType);
        } catch {
            return EMPTY_PAYMENT_TYPE_RESULT;
        }
    };

    return {
        fetchPaymentType,
        loading,
        error,
    };
};

export default usePaymentType;
