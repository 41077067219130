import { action, makeObservable, observable } from 'mobx';
import { CoachCategoryT } from 'types/CoachCategory';

import BaseModalStore from './BaseModalStore';

export default class UpdateCoachCategoryModal extends BaseModalStore {
    public coachCategory: CoachCategoryT | undefined = undefined;

    public setCoachCategory(coachCategory: CoachCategoryT) {
        this.coachCategory = coachCategory;
    }

    constructor() {
        super();

        makeObservable(this, {
            coachCategory: observable,
            setCoachCategory: action.bound,
        });
    }
}
