import { PriceSalesRoundT } from './PriceSalesRound';

export type PriceTypeT = {
    id: string;
    title: string;
    position: number;
    isActive: boolean;
    isGroupAvailable: boolean;
    isCoachAvailable: boolean;
    priceSalesRounds: PriceSalesRoundT[] | [];
};

export type PriceTypesT = {
    priceTypes: PriceTypeT[];
    priceTypesCount: number;
};

export const EMPTY_PRICE_TYPE_RESULT: PriceTypeT = {
    id: '',
    title: '',
    position: 0,
    isActive: false,
    isGroupAvailable: false,
    isCoachAvailable: false,
    priceSalesRounds: [],
};

export const EMPTY_PRICE_TYPES_RESULT: PriceTypesT = {
    priceTypes: [],
    priceTypesCount: 0,
};

export const PRICE_TYPES_IS_ACTIVE = {
    items: [{
        columnField: 'isActive', type: 'boolean', operatorValue: 'is', value: true, hasuraFilterFields: [],
    }],
    linkOperator: 'and',
};

export const PRICE_TYPES_POSITION_ASC = { position: 'asc' };
