import { BONUS } from 'constants/currency';
import { MODAL_TITLE_UPDATE_PAYMENT_TYPE } from 'constants/modalTitles';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { PAYMENT_METHOD_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/paymentMethod/PaymentMethodQuery';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import useFetchPaymentType from 'shared/hook/paymentType/fetch/fetchOne/useFetchPaymentType';
import useUpdatePaymentType from 'shared/hook/paymentType/update/useUpdatePaymentType';
import { useHeaderNameServiceApp } from 'shared/hook/useHeaderNameServiceApp';
import { useStore } from 'store';

import {
    PAYMENT_TYPE_DEFAULT_VALUES, PAYMENT_TYPE_FIELD_KEYS,
    PAYMENT_TYPE_LABEL_DATA, PAYMENT_TYPE_VALIDATION_SCHEMA,
    PaymentTypeFormFields,
} from './formData';

const UpdatePaymentTypeModal: FC = observer(() => {
    const {
        isOpen, onClose, paymentType, updatedItemId,
    } = useStore('updatePaymentType');
    const { getFilterOption } = useStore('filterOptions');
    const paymentMethodFilterOptions = getFilterOption('paymentMethod');
    const { loading: loadingFetch } = useFetchPaymentType();
    const { loading: loadingSave, updatePaymentType } = useUpdatePaymentType();
    const {
        loading: loadingPaymentMethod,
        refetch,
    } = useFetchFilterOptions(PAYMENT_METHOD_FILTER_OPTIONS_QUERY, 'paymentMethod');
    const { currencyHeaderName, divisibleHeaderName } = useHeaderNameServiceApp();

    const {
        control, handleSubmit, setValue, reset, formState: { isDirty, isValid },
    } = useForm<PaymentTypeFormFields>({
        defaultValues: PAYMENT_TYPE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(PAYMENT_TYPE_VALIDATION_SCHEMA),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdatePaymentType = (data: PaymentTypeFormFields) => {
        updatePaymentType(data, updatedItemId).then(() => handleCloseModal());
    };

    useEffect(() => {
        if (paymentType) {
            Object.keys(paymentType).forEach((key: string) => {
                setValue(key as keyof PaymentTypeFormFields, paymentType[key as keyof PaymentTypeFormFields] as never);
            });
        }
    }, [paymentType]);

    useEffect(() => {
        if (refetch) {
            refetch();
        }
    }, [updatedItemId]);

    return (
        <Modal open={ isOpen } maxWidth="lg">
            <ModalTitle onClose={ handleCloseModal }>
                { loadingFetch ? (
                    <Skeleton height={ 32 } />
                ) : (
                    `${MODAL_TITLE_UPDATE_PAYMENT_TYPE} ${paymentType?.title}`
                ) }
            </ModalTitle>
            <DialogContent dividers={ true }>
                {
                    loadingFetch ? (
                        <Skeleton height={ 200 } />
                    ) : (
                        <Grid2 spacing={ 2 } container>
                            <Grid2 xs={ 12 }>
                                <TextField
                                    control={ control }
                                    label={ PAYMENT_TYPE_LABEL_DATA.title }
                                    name={ PAYMENT_TYPE_FIELD_KEYS.title }
                                />
                            </Grid2>
                            <Grid2 xs={ 12 }>
                                <TextField
                                    control={ control }
                                    label={ PAYMENT_TYPE_LABEL_DATA.position }
                                    name={ PAYMENT_TYPE_FIELD_KEYS.position }
                                />
                            </Grid2>
                            <Grid2 xs={ 6 }>
                                <TextField
                                    control={ control }
                                    name={ PAYMENT_TYPE_FIELD_KEYS.defaultFiatPayment }
                                    label={ currencyHeaderName() }
                                    type="number"
                                />
                            </Grid2>
                            <Grid2 xs={ 6 }>
                                <TextField
                                    control={ control }
                                    name={ PAYMENT_TYPE_FIELD_KEYS.defaultBonusPayment }
                                    label={ currencyHeaderName(BONUS) }
                                    type="number"
                                />
                            </Grid2>
                            <Grid2 xs={ 6 }>
                                <TextField
                                    control={ control }
                                    name={ PAYMENT_TYPE_FIELD_KEYS.fiatPaymentDivisible }
                                    label={ divisibleHeaderName() }
                                    type="number"
                                />
                            </Grid2>
                            <Grid2 xs={ 6 }>
                                <TextField
                                    control={ control }
                                    name={ PAYMENT_TYPE_FIELD_KEYS.bonusPaymentDivisible }
                                    label={ divisibleHeaderName(BONUS) }
                                    type="number"
                                />
                            </Grid2>
                            <Grid2 xs={ 12 }>
                                <Switch
                                    name={ PAYMENT_TYPE_FIELD_KEYS.isActive }
                                    control={ control }
                                    label={ PAYMENT_TYPE_LABEL_DATA.isActive }
                                    type="boolean"
                                />
                            </Grid2>
                            <Grid2 xs={ 12 }>
                                <Switch
                                    name={ PAYMENT_TYPE_FIELD_KEYS.shouldConfirmBySms }
                                    control={ control }
                                    label={ PAYMENT_TYPE_LABEL_DATA.shouldConfirmBySms }
                                    type="boolean"
                                />
                            </Grid2>
                            <Grid2 xs={ 12 }>
                                <Switch
                                    name={ PAYMENT_TYPE_FIELD_KEYS.allowNegativePayments }
                                    control={ control }
                                    label={ PAYMENT_TYPE_LABEL_DATA.allowNegativePayments }
                                    type="boolean"
                                />
                            </Grid2>
                            <Grid2 xs={ 12 }>
                                <Select
                                    options={ paymentMethodFilterOptions }
                                    control={ control }
                                    loading={ loadingPaymentMethod }
                                    name={ PAYMENT_TYPE_FIELD_KEYS.paymentMethodId }
                                    label={ PAYMENT_TYPE_LABEL_DATA.paymentMethodId }
                                />
                            </Grid2>
                        </Grid2>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal } color="primary">
                    Отмена
                </Button>
                <LoadingButton
                    loading={ loadingSave }
                    variant="contained"
                    color="success"
                    autoFocus
                    onClick={ handleSubmit(handleUpdatePaymentType) }
                    disabled={ !isDirty || !isValid }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdatePaymentTypeModal;
