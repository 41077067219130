import { gql } from '@apollo/client';

export type PriceTypeCreateFields = {
    title: string;
    position: number;
    is_active: boolean;
    is_group_available: boolean;
    is_coach_available: boolean;
};

export type PriceTypeCreateVar = {
    object: PriceTypeCreateFields;
};

export type PriceTypeCreateResponseDataT = {
    createPriceType: {
        id: string;
    };
} | null | undefined;

export const PRICE_TYPE_CREATE = gql`
    mutation CreatePriceType($object: price_type_insert_input = {}) {
        createPriceType: insert_price_type_one(
            object: $object
        ) {
            id
        }
    }
`;
