import {
    NOTIFICATION_TEXT_INVALID_END_TIME_RANGE,
    NOTIFICATION_TEXT_INVALID_START_TIME_RANGE, NOTIFICATION_TEXT_REQUIRED_END_TIME,
    NOTIFICATION_TEXT_REQUIRED_START_TIME,
} from 'constants/notificationsText';

import { object, string } from 'yup';

export const getValidationTimePeriod = object().shape({
    timeStart: string()
        .required(NOTIFICATION_TEXT_REQUIRED_START_TIME)
        .test('isLarger', NOTIFICATION_TEXT_INVALID_START_TIME_RANGE, (value: any, testContext) => {
            return testContext.parent.timeEnd > value;
        }),
    timeEnd: string()
        .required(NOTIFICATION_TEXT_REQUIRED_END_TIME)
        .test('isLarger', NOTIFICATION_TEXT_INVALID_END_TIME_RANGE, (value: any, testContext) => {
            return testContext.parent.timeStart < value;
        }),
});
