import { alpha, Box, styled } from '@mui/material';
import {
    amber,
    blue, green, grey, orange, red, yellow,
} from '@mui/material/colors';
import { DataGridPremium as BaseDataGrid } from '@mui/x-data-grid-premium';

export const StyledDataGrid = styled(BaseDataGrid)({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    '& .data-grid-section': {
        background: blue[50],

        '& .MuiDataGrid-columnHeaderTitleContainer': {
            border: 0,
        },
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
        border: 0,
    },
    '& .data-grid-section-even': {
        background: amber[200],
    },
    '& .data-grid-section-odd': {
        background: amber[300],
    },
    '& .data-grid-section-court-odd': {
        background: orange[100],
    },
    '& .data-grid-section-court-even': {
        background: yellow[100],
    },
    '& .data-grid-cell-exceeded': {
        background: yellow[200],
    },
    '& .data-grid-cell-no-clients': {
        background: blue[100],
    },
    '& .data-grid-cell-empty': {
        background: red[100],
    },
    '& .data-grid-row-odd': {
        background: grey[50],
    },
    '& .border-right': {
        borderRight: `1px solid ${grey[300]}`,
    },
    '& .border-left': {
        borderLeft: `1px solid ${grey[300]}`,
    },
    '& .data-grid-sale-column': {
        background: alpha(green[100], 0.4),
    },
    '& .data-grid-cancel-column': {
        background: alpha(red[100], 0.4),
    },
    '& .data-grid-information-column': {
        background: alpha(blue[100], 0.4),
    },
    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { padding: '8px 0' },
    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { padding: '8px 8px' },
    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { padding: '22px 0' },
}) as typeof BaseDataGrid;

export const FlexWrapBox = styled(Box)({
    display: 'flex', gap: '4px', flexWrap: 'wrap', justifyContent: 'center',
}) as typeof Box;

export const DataGridWrap = styled('div')({
    flex: '1 1 auto',
    width: '100%',
    position: 'relative',
});
