import { useMutation } from '@apollo/client';
import { CourtTypeFormFields } from 'page/Court/components/formData';
import { CourtTypeUpdateDataT } from 'shared/data-hook/courtType/update/CourtTypeUpdateData';
import {
    COURT_TYPE_UPDATE,
    CourtTypeUpdateResponseDataT,
    CourtTypeUpdateVarT,
} from 'shared/graphql/mutation/courtType/CourtTypeUpdate';
import { CourtTypeT } from 'types/CourtType';

const useCourtTypeUpdate = (): CourtTypeUpdateDataT => {
    const [update, { error, loading }] = useMutation<CourtTypeUpdateResponseDataT, CourtTypeUpdateVarT
    >(COURT_TYPE_UPDATE);

    const updateAction = async (input: CourtTypeFormFields, courtTypeId: CourtTypeT['id']) => {
        if (!courtTypeId) {
            throw new Error('Не передан id типа корта');
        }

        try {
            const { data } = await update({
                variables: {
                    id: courtTypeId,
                    set: {
                        name: input.name,
                        duration: input.duration,
                        use_halves: input.useHalves,
                    },
                },
            });

            return data;
            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        courtTypeUpdate: updateAction,
        error,
        loading,
    };
};

export default useCourtTypeUpdate;
