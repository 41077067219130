import { COMPARE_OPERATOR } from 'constants/gqlOperators';

import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceSalesRoundsFilterT } from 'shared/graphql/query/priceSalesRound/PriceSalesRoundQuery';

const DTO = t.type({});

export type FilterPriceSalesRoundsDTOT = t.TypeOf<typeof DTO>;

export const FilterPriceSalesRoundsDTO = tPromise.extendType<FilterPriceSalesRoundsDTOT, PriceSalesRoundsFilterT>(
    DTO,
    () => ({}),
    ({ priceTypeId }) => ({
        price_type_id: {
            [COMPARE_OPERATOR.equals]: priceTypeId,
        },
    }),
) as t.Type<FilterPriceSalesRoundsDTOT, PriceSalesRoundsFilterT>;
