import { ERROR_DELETE } from 'constants/notificationMessage';

import usePriceTypeMultipleDelete from 'shared/data-hook/priceType/delete/multiple/usePriceTypeMultipleDelete';
import { useStore } from 'store';
import { PriceT } from 'types/Price';

import { useNotification } from '../../useNotification';

const useDeleteMultiplePriceType = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteMultiplePriceType: actionDelete } = usePriceTypeMultipleDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const deleteMultiplePriceType = async (priceTypeIds: [PriceT['id']]) => {
        const { affectedRows } = await actionDelete(priceTypeIds);

        if (error || !affectedRows) {
            showError(ERROR_DELETE);
        } else {
            showSuccess(`Строк удалено: ${affectedRows}`);
            toggleRefetchTrigger();
        }
    };

    return {
        deleteMultiplePriceType,
    };
};

export default useDeleteMultiplePriceType;
