import { PERCENT } from 'constants/currency';

import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { UpdatePriceRateFormFields } from 'page/Price/components/UpdatePriceRate/formData';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';

const DTO = t.intersection([
    t.type({
        price_sales_round_ids: t.array(t.string),
        category_ids: t.array(t.string),
        court_type_ids: t.array(t.string),
        days: t.array(t.number),
        time_periods: t.array(t.string),
        price_season_id: t.string,
        price_type_id: t.string,
        coach_category_id: t.string,
        group_view_id: t.string,
    }),
    t.partial({
        sale_rate: t.number,
        return_rate: t.number,
        min_rate: t.number,
        transfer_disabled_before_hours: t.number,
        forward_transfer_cost: t.number,
        back_transfer_cost: t.number,
        transferable: t.boolean,
        cancellable: t.boolean,
        performer_return_income_rate: Nullable(t.number),
        performer_return_income_sum: Nullable(t.number),
        performer_sale_income_rate: Nullable(t.number),
        performer_sale_income_sum: Nullable(t.number),
        share_of_payment_bonus: Nullable(t.number),
        share_of_payment_fiat: Nullable(t.number),
    }),
]);

export type UpdatePricesDTOT = t.TypeOf<typeof DTO>;

type UpdatePriceRateT = UpdatePriceRateFormFields & {
    priceSeasonId: string;
    priceTypeId: string;
    serviceAppFiat: string;
}

export const UpdatePriceRateDTO = tPromise.extendType<UpdatePricesDTOT, UpdatePriceRateT>(
    DTO,
    () => ({
        priceSalesRoundIds: [],
        categoryIds: [],
        courtTypeIds: [],
        days: [],
        timePeriods: [],
        saleRate: '',
        returnRate: '',
        minRate: '',
        forwardTransferCost: '',
        backTransferCost: '',
        transferable: null,
        cancellable: null,
        returnCurrency: '',
        saleCurrency: '',
        performerReturnIncomeValue: '',
        performerSaleIncomeValue: '',
        priceSeasonId: '',
        priceTypeId: '',
        shareOfPaymentFiat: 0,
        shareOfPaymentBonus: 0,
        shareOfPaymentFiatStatus: false,
        shareOfPaymentBonusStatus: false,
        coachCategoryIds: [],
        groupViewIds: [],
        transferDisabledBeforeHours: '',
        serviceAppFiat: '',
    }),
    (
        {
            priceSalesRoundIds, categoryIds, courtTypeIds, days, timePeriods, saleRate,
            coachCategoryIds, groupViewIds, serviceAppFiat, returnRate, minRate, forwardTransferCost,
            backTransferCost, transferable, cancellable, saleCurrency, performerSaleIncomeValue,
            performerReturnIncomeValue, priceSeasonId, priceTypeId, returnCurrency, shareOfPaymentFiat, shareOfPaymentBonus,
            shareOfPaymentFiatStatus, shareOfPaymentBonusStatus, transferDisabledBeforeHours,
        },
    ) => {
        const conditionReturnData: any = Object.entries({
            sale_rate: saleRate,
            min_rate: minRate,
            return_rate: returnRate,
            back_transfer_cost: backTransferCost,
            transfer_disabled_before_hours: transferDisabledBeforeHours,
            forward_transfer_cost: forwardTransferCost,
            performer_return_income_rate: returnCurrency === PERCENT ? performerReturnIncomeValue as number : null,
            performer_return_income_sum: returnCurrency === serviceAppFiat ? performerReturnIncomeValue as number : null,
            performer_sale_income_rate: saleCurrency === PERCENT ? performerSaleIncomeValue as number : null,
            performer_sale_income_sum: saleCurrency === serviceAppFiat ? performerSaleIncomeValue as number : null,
            transferable,
            cancellable,
            share_of_payment_bonus: shareOfPaymentBonusStatus ? shareOfPaymentBonus : '',
            share_of_payment_fiat: shareOfPaymentFiatStatus ? shareOfPaymentFiat : '',
        })
            .filter(([, value]) => value !== null && value !== undefined && value !== '')
            .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

        return {
            price_sales_round_ids: priceSalesRoundIds,
            category_ids: categoryIds,
            court_type_ids: courtTypeIds,
            days,
            time_periods: timePeriods,
            coach_category_ids: coachCategoryIds,
            group_view_ids: groupViewIds,
            price_season_id: priceSeasonId,
            price_type_id: priceTypeId,
            ...conditionReturnData,
        };
    },
) as t.Type<UpdatePriceRateT, UpdatePricesDTOT>;
