import { PERCENT, PERCENT_SYMBOL } from 'constants/currency';

export const getPercentAndFiatOptions = (fiat: string) => [
    {
        value: PERCENT,
        label: 'Проценты',
        adornment: PERCENT_SYMBOL,
    },
    {
        value: fiat,
        label: fiat,
        adornment: fiat,
    },
];
