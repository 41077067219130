import { ERROR_PAYMENT_TYPE_CREATE, SUCCESS_PAYMENT_TYPE_CREATE } from 'constants/notificationMessage';

import { PaymentTypeFormFields } from 'page/PaymentSetting/components/PaymentType/components/formData';
import useCreatePaymentType from 'shared/data-hook/paymentType/create/useCreatePaymentType';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { PaymentMethodT } from 'types/PaymentMethod';

const usePaymentTypeCreate = () => {
    const { paymentTypeCreate, loading, error } = useCreatePaymentType();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const createPaymentType = async (input: PaymentTypeFormFields, paymentTypeId: PaymentMethodT['id']) => {
        const response = await paymentTypeCreate(input, paymentTypeId);

        if (error || !response) {
            showError(ERROR_PAYMENT_TYPE_CREATE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_PAYMENT_TYPE_CREATE);
        }
    };

    return {
        createPaymentType,
        loading,
    };
};

export default usePaymentTypeCreate;
