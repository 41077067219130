import { useMutation } from '@apollo/client';
import {
    COACH_CATEGORY_DELETE,
    CoachCategoryDeleteResponseDataT,
    CoachCategoryDeleteVarT,
} from 'shared/graphql/mutation/coachCategory/CoachCategoryDelete';
import { CoachCategoryT } from 'types/CoachCategory';

import { CoachCategoryDeleteDataT } from './CoachCategoryDeleteData';

const useCoachCategoryDelete = (): CoachCategoryDeleteDataT => {
    const [deleteCoachCategory, { loading, error }] = useMutation<
        CoachCategoryDeleteResponseDataT,
        CoachCategoryDeleteVarT
    >(COACH_CATEGORY_DELETE);

    const deleteAction = async (coachCategoryId: CoachCategoryT['id']) => {
        try {
            const { data } = await deleteCoachCategory({
                variables: {
                    id: coachCategoryId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteCoachCategory: deleteAction,
        loading,
        error,
    };
};

export default useCoachCategoryDelete;
