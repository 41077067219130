import { useMutation } from '@apollo/client';
import { camelizeKeys } from 'humps';
import { PriceMemberCardFormFields } from 'page/PriceMemberCard/components/formData';
import {
    EMPTY_UPDATE_PRICE_MEMBER_CARD_TYPE_RESPONSE,
    UPDATE_PRICE_MEMBER_CARD_TYPE, UpdatePriceMemberCardTypeResponseT,
    UpdatePriceMemberCardTypeT, UpdatePriceMemberCardTypeVarT,
} from 'shared/graphql/mutation/priceMemberCard/UpdatePriceMemberCardTypeAction';
import { useStore } from 'store';

import { UpdatePriceMemberCardTypeDataT } from './UpdatePriceMemberCardTypeData';
import { UpdatePriceMemberCardTypeDTO } from './UpdatePriceMemberCardTypeDTO';

const useUpdatePriceMemberCardType = (): UpdatePriceMemberCardTypeDataT => {
    const [update, { loading, error }] = useMutation<UpdatePriceMemberCardTypeT, UpdatePriceMemberCardTypeVarT>(
        UPDATE_PRICE_MEMBER_CARD_TYPE,
    );

    const { activePriceSeason, activePriceType } = useStore('priceMemberCards');
    const { updatedItemId: memberCardTypeId } = useStore('updatePriceMemberCardModal');
    const { serviceApp } = useStore('serviceApp');

    const updatePriceMemberCardType = async (priceMemberCardTypeData: PriceMemberCardFormFields) => {
        const priceSeasonId = activePriceSeason?.id || '';
        const priceTypeId = activePriceType?.id || '';

        try {
            const { data } = await update({
                variables: {
                    appId: serviceApp.id,
                    payload: UpdatePriceMemberCardTypeDTO.encode({
                        ...priceMemberCardTypeData, priceSeasonId, memberCardTypeId, priceTypeId,
                    }),
                },
            });

            return camelizeKeys(data?.adminPriceUpdateMemberCardTypePrices) as UpdatePriceMemberCardTypeResponseT;
        } catch {
            return EMPTY_UPDATE_PRICE_MEMBER_CARD_TYPE_RESPONSE;
        }
    };

    return {
        updatePriceMemberCardType,
        loading,
        error,
    };
};

export default useUpdatePriceMemberCardType;
