import { useMutation } from '@apollo/client';
import {
    EMPTY_SCHEDULE_SLOT_RATE_RECREATE_FOR_PRICE_RESPONSE,
    SCHEDULE_SLOT_RATE_RECREATE_FOR_PRICE,
    ScheduleSlotRateRecreateForPriceResponseT,
    ScheduleSlotRateRecreateForPriceT,
    ScheduleSlotRateRecreateForPriceVarT,
} from 'shared/graphql/mutation/scheduleSlotRate/ScheduleSlotRateRecreateByPrice';
import { useStore } from 'store';

import { ScheduleSlotRateRecreateForPriceDataT } from './ScheduleSlotRateRecreateByPriceData';

const useScheduleSlotRateRecreateForPriceData = (): ScheduleSlotRateRecreateForPriceDataT => {
    const [recreate, { loading, error }] = useMutation<ScheduleSlotRateRecreateForPriceT, ScheduleSlotRateRecreateForPriceVarT>(
        SCHEDULE_SLOT_RATE_RECREATE_FOR_PRICE,
    );

    const { serviceApp } = useStore('serviceApp');

    const recreateScheduleSlotRate = async (priceSeasonId: string, priceIds: string[]) => {
        try {
            const { data } = await recreate({
                variables: {
                    appId: serviceApp.id,
                    payload: priceIds.map((id) => ({
                        price_id: id,
                        price_season_id: priceSeasonId,
                    })),
                },
            });

            return data?.adminScheduleSlotRateRecreateForPrice as ScheduleSlotRateRecreateForPriceResponseT;
        } catch {
            return EMPTY_SCHEDULE_SLOT_RATE_RECREATE_FOR_PRICE_RESPONSE;
        }
    };

    return {
        recreateScheduleSlotRate,
        loading,
        error,
    };
};

export default useScheduleSlotRateRecreateForPriceData;
