import React, {
    FC, memo, useEffect,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { TabContext as BaseTabContext, TabList } from '@mui/lab';
import {
    Box, Button, Tab,
} from '@mui/material';
import { GridColDef, GridColumns } from '@mui/x-data-grid-premium';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import DataGrid from 'shared/component/DataGrid';
import useFetchDataByPriceTypeIdAndPriceSeasonId from 'shared/hook/dataGrid/useFetchDataByPriceTypeIdAndPriceSeasonId';
import useDeleteMultiplePrice from 'shared/hook/price/delete/useDeleteMultiplePrice';
import useScheduleSlotRateRecreateForPrice
    from 'shared/hook/scheduleSlotRate/create/useScheduleSlotRateRecreateForPrice';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { Wrapper } from '../../Main/styles';
import { GROUP_HEADER_DATA, PRICE_HEADER_DATA } from '../domain/HeaderData';
import { preparePriceHeaderData } from '../domain/preparePriceHeaderData';
import { setGroupHeaderData } from '../domain/setGroupHeaderData';
import { setHeaderData } from '../domain/setHeaderData';
import { StyledPricePageTabPanel, StyledPricePageWrap } from '../domain/Styled';

type PropsT = {
    tab: string;
    handleTabChange: (newTab: string) => void;
    fetchPrices: Function;
    loading: boolean;
    fetchSalesRounds: (priceTypeId?: string | undefined) => Promise<void>;
    priceSeasonId: string;
} & DataGridPropsT;

const TabContext: FC<PropsT> = observer(({
    tab,
    handleTabChange,
    fetchPrices,
    loading,
    pageSize,
    setPageSize,
    sortModel,
    page,
    setPage,
    setSortModel,
    filterModel,
    setFilterModel,
    fetchSalesRounds,
    priceSeasonId,
}) => {
    const { priceSalesRounds } = useStore('priceSalesRound');
    const { onOpen: openFilPriceModal } = useStore('updatePriceRateModal');
    const { onOpen: openCreatePriceModal } = useStore('createPriceModal');
    const {
        activePriceType, prices, count, priceTypes, setActivePriceType,
    } = useStore('price');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { deleteMultiplePrice } = useDeleteMultiplePrice();
    const { recreateScheduleSlotRateForPrice } = useScheduleSlotRateRecreateForPrice();

    const { getFilterOption } = useStore('filterOptions');
    const courtTypeFilterOptions = getFilterOption('courtType');
    const serviceCategoryFilterOptions = getFilterOption('serviceCategory');
    const coachCategoryFilterOptions = getFilterOption('coachCategory');
    const groupViewFilterOptions = getFilterOption('groupView');

    const headerData = [...PRICE_HEADER_DATA];
    const groupHeaderData = [...GROUP_HEADER_DATA];

    const prepareHeaderData = (data: GridColumns): GridColumns => {
        let preparedHeaderData = [...data];

        preparedHeaderData = activePriceType?.isGroupAvailable ? preparedHeaderData : preparedHeaderData.filter((item: GridColDef) => item.field !== 'groupView');
        preparedHeaderData = activePriceType?.isCoachAvailable ? preparedHeaderData : preparedHeaderData.filter((item: GridColDef) => item.field !== 'coachCategory');

        return preparePriceHeaderData(preparedHeaderData, courtTypeFilterOptions, serviceCategoryFilterOptions, coachCategoryFilterOptions, groupViewFilterOptions);
    };

    useEffect(() => {
        if (tab) {
            return;
        }

        handleTabChange(priceTypes[0]?.id ?? '');
    }, [priceTypes]);

    useEffect(() => {
        const priceType = priceTypes.find((item) => item.id === tab);

        if (priceType) {
            setActivePriceType(priceType);
        }
    }, [tab, priceTypes]);

    useEffect(() => {
        if (tab === '' || priceSeasonId === '') {
            return;
        }

        useFetchDataByPriceTypeIdAndPriceSeasonId(tab, priceSeasonId, sortModel, filterModel, page, pageSize, PRICE_HEADER_DATA, fetchPrices);
        fetchSalesRounds(tab).then();
    }, [sortModel, filterModel, page, pageSize, tab, refetchTrigger, priceSeasonId]);

    const handleRefresh = (priceIds: string[]) => {
        recreateScheduleSlotRateForPrice(priceSeasonId, priceIds).then();
    };

    return (
        <Wrapper>
            <BaseTabContext value={ tab }>
                <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
                    <TabList
                        scrollButtons={ true }
                        variant="scrollable"
                        onChange={ (event, newTab) => {
                            handleTabChange(newTab);
                        } }
                    >
                        {
                            tab && priceTypes.map((item) => (
                                <Tab label={ item.title } value={ item.id } key={ item.id } />
                            ))
                        }
                    </TabList>
                </Box>
                <StyledPricePageWrap>
                    <ControlPanel>
                        <Button variant="outlined" size="small" onClick={ openCreatePriceModal } startIcon={ <AddIcon /> }>создать строки</Button>
                        <Button variant="outlined" size="small" onClick={ openFilPriceModal } startIcon={ <PlaylistAddIcon /> }>Заполнить тарифы</Button>
                    </ControlPanel>
                    {
                        priceTypes.map((item) => {
                            priceSalesRounds.forEach((priceSalesRound, index) => {
                                if (!groupHeaderData.find((findItem) => findItem.groupId === priceSalesRound.id)) {
                                    setGroupHeaderData(groupHeaderData, priceSalesRound, index);
                                    setHeaderData(headerData, priceSalesRound, index);
                                }
                            });

                            return (
                                <StyledPricePageTabPanel value={ item.id } key={ item.id } sx={ { padding: 0 } }>
                                    <DataGrid
                                        rows={ prices }
                                        rowCount={ count }
                                        columns={ prepareHeaderData(headerData) }
                                        loading={ loading }
                                        pageSize={ pageSize }
                                        page={ page }
                                        setPageSize={ setPageSize }
                                        setPage={ setPage }
                                        setSortModel={ setSortModel }
                                        setFilterModel={ setFilterModel }
                                        columnGroupingModel={ groupHeaderData }
                                        sortModel={ sortModel }
                                        filterModel={ filterModel }
                                        multipleDeleteAction={ deleteMultiplePrice }
                                        multipleRefreshAction={ handleRefresh }
                                        className="price-data-grid"
                                    />
                                </StyledPricePageTabPanel>
                            );
                        })
                    }
                </StyledPricePageWrap>
            </BaseTabContext>
        </Wrapper>
    );
});

export default memo(TabContext);
