import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PaymentTypeDTO } from 'shared/data-hook/paymentType/fetch/DTO/PaymentTypeDTO';
import { PaymentMethodT } from 'types/PaymentMethod';

const DTO = t.intersection([
    t.type({
        id: t.string,
        title: t.string,
        position: t.number,
        is_active: t.boolean,
    }),
    t.partial({
        payment_types: t.array(PaymentTypeDTO),
    }),
]);

export type PaymentMethodDTOT = t.TypeOf<typeof DTO>;

export const PaymentMethodDTO = tPromise.extendDecoder<PaymentMethodDTOT, PaymentMethodT>(
    DTO,
    ({
        id,
        title,
        position,
        is_active,
        payment_types,
    }) => ({
        id,
        title,
        position,
        isActive: is_active,
        paymentTypes: payment_types ?? [],
    }),
) as t.Type<PaymentMethodT, PaymentMethodDTOT>;
