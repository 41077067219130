import {
    PROPERTY_ALLOW_NEGATIVE_PAYMENTS,
    PROPERTY_IS_ACTIVE, PROPERTY_PAYMENT_METHOD, PROPERTY_POSITION, PROPERTY_SHOULD_CONFIRM_BY_SMS,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import { number, object, string } from 'yup';

export type PaymentTypeFormFields = {
    title: string;
    position: number;
    isActive: boolean;
    defaultBonusPayment?: number;
    defaultFiatPayment?: number;
    fiatPaymentDivisible: number;
    bonusPaymentDivisible: number;
    paymentMethodId: string;
    shouldConfirmBySms: boolean;
    allowNegativePayments: boolean;
};

export enum PAYMENT_TYPE_FIELD_KEYS {
    title = 'title',
    position = 'position',
    isActive = 'isActive',
    defaultBonusPayment = 'defaultBonusPayment',
    defaultFiatPayment = 'defaultFiatPayment',
    fiatPaymentDivisible = 'fiatPaymentDivisible',
    bonusPaymentDivisible = 'bonusPaymentDivisible',
    shouldConfirmBySms ='shouldConfirmBySms',
    paymentMethodId = 'paymentMethodId',
    allowNegativePayments = 'allowNegativePayments',
}

export const PAYMENT_TYPE_LABEL_DATA = {
    title: PROPERTY_TITLE,
    position: PROPERTY_POSITION,
    isActive: PROPERTY_IS_ACTIVE,
    paymentMethodId: PROPERTY_PAYMENT_METHOD,
    shouldConfirmBySms: PROPERTY_SHOULD_CONFIRM_BY_SMS,
    allowNegativePayments: PROPERTY_ALLOW_NEGATIVE_PAYMENTS,
};

export const PAYMENT_TYPE_VALIDATION_SCHEMA = object().shape({
    title: string().required(),
    position: number().required(),
    fiatPaymentDivisible: number().required(),
    bonusPaymentDivisible: number().required(),
}).required();

export const PAYMENT_TYPE_DEFAULT_VALUES: PaymentTypeFormFields = {
    title: '',
    position: 0,
    isActive: false,
    defaultBonusPayment: 0,
    defaultFiatPayment: 0,
    fiatPaymentDivisible: 0,
    bonusPaymentDivisible: 0,
    paymentMethodId: '',
    shouldConfirmBySms: false,
    allowNegativePayments: false,
};
