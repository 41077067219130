import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';

type PropsT = {
    snackbarKey: string | number;
};

export const SnackbarCloseButton: FC<PropsT> = (props) => {
    const { closeSnackbar } = useSnackbar();
    const { snackbarKey } = props;

    return (
        <IconButton onClick={ () => closeSnackbar(snackbarKey) }>
            <CloseIcon htmlColor="#fff" />
        </IconButton>
    );
};
