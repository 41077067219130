import { useEffect } from 'react';
import useServiceCategory from 'shared/data-hook/serviceCategory/fetch/fetchOne/useServiceCategory';
import { useStore } from 'store';

const useFetchServiceCategory = (id: string) => {
    const { fetchServiceCategory, loading } = useServiceCategory();
    const {
        updatedItemId, isOpen, setServiceCategory,
    } = useStore('updateServiceCategoryModal');

    useEffect(() => {
        if (isOpen && updatedItemId && id) {
            fetchServiceCategory(updatedItemId).then((fetchedServiceCategory) => {
                setServiceCategory(fetchedServiceCategory);
            });
        }
    }, [updatedItemId, isOpen, id]);

    return {
        loading,
    };
};

export default useFetchServiceCategory;
