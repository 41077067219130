import { LOGOUT } from 'constants/routes';

import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Person2Icon from '@mui/icons-material/Person2';
import { MenuItem, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import useMenu from '../hooks/useMenu';

import HeaderMenu from './HeaderMenu';

const UserMenu: FC = () => {
    const { anchorEl, handleOpen, handleClose } = useMenu();

    return (
        <>
            <IconButton onClick={ handleOpen }>
                <Person2Icon color="inherit" />
            </IconButton>
            <HeaderMenu id="user-app-bar" anchor={ anchorEl } handleClose={ handleClose }>
                <MenuItem onClick={ handleClose }>
                    <Typography component={ Link } to={ LOGOUT } textAlign="center">
                        Выход
                    </Typography>
                </MenuItem>
            </HeaderMenu>
        </>
    );
};

export default UserMenu;
