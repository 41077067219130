import {
    PROPERTY_ACTIONS,
    PROPERTY_COLOR, PROPERTY_POSITION, PROPERTY_TITLE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { Box } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeleteServiceCategory from 'shared/hook/serviceCategory/delete/useDeleteServiceCategory';

import ServiceCategoryUpdate from '../ServiceCategoryUpdate';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row } = props;
    const { serviceCategoryDelete } = useDeleteServiceCategory();

    return (
        <>
            <ServiceCategoryUpdate id={ row.id } />
            <DeleteButton id={ row.id } title={ row.title } actionDelete={ serviceCategoryDelete } />
        </>
    );
};

export const SERVICE_CATEGORY_HEADER_DATA: GridColumns = [
    {
        headerName: PROPERTY_POSITION,
        field: 'position',
        flex: 1,
        type: 'string',
    },
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        flex: 1,
        type: 'string',
    },
    {
        headerName: PROPERTY_COLOR,
        field: 'color',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params) => (
            <Box sx={ {
                display: 'flex', alignItems: 'center', position: 'relative', width: '100%', justifyContent: 'center',
            } }
            >
                { params.row.color }
                <Box sx={ {
                    width: '20px', height: '20px', borderRadius: '50%', position: 'absolute', right: '12px', border: '1px solid black', background: params.row.color,
                } }
                />
            </Box>
        ),
    },
    {
        headerName: 'Мин. длительность',
        field: 'minDuration',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',

    },
    {
        headerName: 'Мин. доп. длительность',
        field: 'minAdditionalDuration',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',

    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
];
