import {
    PROPERTY_COACH_CATEGORY,
    PROPERTY_COLOR, PROPERTY_FULL_NAME, PROPERTY_IS_ACTIVE, PROPERTY_SERVICE_APP, PROPERTY_WORK_DAYS,
} from 'constants/propertyNames';

import { object, string } from 'yup';

export type CoachFormFields = {
    name: string;
    categoryId: string;
    serviceAppId: string;
    color: string;
    active: boolean;
    workDays: Array<{
        day: number;
        start: string;
        end: string;
    }>;
}

export enum COACH_FIELD_KEYS {
    name = 'name',
    color = 'color',
    active = 'active',
    workDays = 'workDays',
    categoryId = 'categoryId',
    serviceAppId = 'serviceAppId',
}

export const COACH_DEFAULT_VALUES = {
    color: '',
    active: false,
    categoryId: '',
    serviceAppId: '',
    name: '',
    workDays: [
        {
            day: 1,
            start: '',
            end: '',
        },
        {
            day: 2,
            start: '',
            end: '',
        },
        {
            day: 3,
            start: '',
            end: '',
        },
        {
            day: 4,
            start: '',
            end: '',
        },
        {
            day: 5,
            start: '',
            end: '',
        },
        {
            day: 6,
            start: '',
            end: '',
        },
        {
            day: 7,
            start: '',
            end: '',
        },
    ],
};

export const COACH_LABEL_DATA = {
    name: PROPERTY_FULL_NAME,
    color: PROPERTY_COLOR,
    active: PROPERTY_IS_ACTIVE,
    workDays: PROPERTY_WORK_DAYS,
    serviceAppId: PROPERTY_SERVICE_APP,
    coachCategoryId: PROPERTY_COACH_CATEGORY,
};

export const COACH_VALIDATION_SCHEMA = object().shape({
    serviceAppId: string().required(),
    name: string().required(),
});
