import {
    PROPERTY_END, PROPERTY_IS_ACTIVE, PROPERTY_START, PROPERTY_TITLE,
} from 'constants/propertyNames';

import { date, object, string } from 'yup';

export type SeasonFormFields = {
    title: string;
    startAt: Date;
    finishAt: Date;
    isActive: boolean;
};

export const PRICE_SEASON_DEFAULT_VALUES: SeasonFormFields = {
    title: '',
    startAt: new Date(),
    finishAt: new Date(),
    isActive: false,
};

export const CREATE_PRICE_SEASON_DEFAULT_VALUES = { ...PRICE_SEASON_DEFAULT_VALUES };

export enum PRICE_SEASON_FIELDS_KEYS {
    title = 'title',
    isActive = 'isActive',
    startAt = 'startAt',
    finishAt = 'finishAt',
}

type SeasonLabelDataT = Record<keyof SeasonFormFields, string>;

export const PRICE_SEASON_LABEL_DATA: SeasonLabelDataT = {
    title: PROPERTY_TITLE,
    isActive: PROPERTY_IS_ACTIVE,
    startAt: PROPERTY_START,
    finishAt: PROPERTY_END,
};

export const PRICE_SEASON_VALIDATION_SCHEMA = object().shape({
    title: string().required(),
    startAt: date().required(),
    finishAt: date().required(),
}).required();
