import { useMutation } from '@apollo/client';
import {
    PAYMENT_METHOD_DELETE, PaymentMethodDeleteResponseDataT,
    PaymentMethodDeleteVarT,
} from 'shared/graphql/mutation/paymentMethod/PaymentMethodDelete';
import { PaymentMethodT } from 'types/PaymentMethod';

import { PaymentMethodDeleteDataT } from './PaymentMethodDeleteData';

const usePaymentMethodDelete = (): PaymentMethodDeleteDataT => {
    const [deletePaymentMethod, { loading, error }] = useMutation<
        PaymentMethodDeleteResponseDataT,
        PaymentMethodDeleteVarT
    >(PAYMENT_METHOD_DELETE);
    const deleteAction = async (paymentMethodId: PaymentMethodT['id']) => {
        try {
            const { data } = await deletePaymentMethod({
                variables: {
                    id: paymentMethodId,
                },
            });

            return data;
        } catch (errorDelete) {
            // TODO: use Sentry
            console.error((errorDelete as Error).message); // eslint-disable-line
            throw errorDelete;
        }
    };

    return {
        deletePaymentMethod: deleteAction,
        loading,
        error,
    };
};

export default usePaymentMethodDelete;
