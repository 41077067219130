import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    PRICE_SALES_ROUNDS_QUERY,
    PriceSalesRoundsQueryVarT,
    PriceSalesRoundsResponseT,
} from 'shared/graphql/query/priceSalesRound/PriceSalesRoundQuery';
import { EMPTY_PRICE_SALES_ROUNDS_RESULT } from 'types/PriceSalesRound';

import { FilterPriceSalesRoundsDTO } from '../DTO/FilterPriceSalesRoundsDTO';
import { PriceSalesRoundsDTO } from '../DTO/PriceSalesRoundsDTO';

import { PriceSalesRoundsDataT } from './PriceSalesRoundsData';

const usePriceSalesRounds = (): PriceSalesRoundsDataT => {
    const [fetch, { data: priceSalesRoundsData, loading }] = useLazyQuery<PriceSalesRoundsResponseT, PriceSalesRoundsQueryVarT>(
        PRICE_SALES_ROUNDS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedSalesRoundsData = decodeDTO(
        PriceSalesRoundsDTO,
        EMPTY_PRICE_SALES_ROUNDS_RESULT,
        (priceSalesRounds) => priceSalesRounds,
        {
            priceSalesRounds: priceSalesRoundsData?.priceSalesRounds,
        },
    );

    const fetchSalesRounds = async (priceTypeId?: string): Promise<void> => {
        try {
            await fetch({
                variables: {
                    where: priceTypeId ? FilterPriceSalesRoundsDTO.encode({ priceTypeId }) : {},
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedSalesRoundsData.priceSalesRounds,
        fetchSalesRounds,
        loading,
    };
};

export default usePriceSalesRounds;
