import { useMutation } from '@apollo/client';
import { ServiceCategoryFormFields } from 'page/ServiceCategory/components/formData';
import {
    SERVICE_CATEGORY_UPDATE,
    ServiceCategoryUpdateResponseDataT, ServiceCategoryUpdateVar,
} from 'shared/graphql/mutation/serviceCategory/ServiceCategoryUpdate';
import { ServiceCategoryT } from 'types/ServiceCategory';

import {
    UpdateServiceCategoryDataT,
} from './UpdateServiceCategoryData';

const useUpdateServiceCategory = (): UpdateServiceCategoryDataT => {
    const [update, { loading, error }] = useMutation<
        ServiceCategoryUpdateResponseDataT,
        ServiceCategoryUpdateVar
    >(SERVICE_CATEGORY_UPDATE);

    const updateAction = async (
        input: ServiceCategoryFormFields,
        serviceCategoryId: ServiceCategoryT['id'],
    ) => {
        if (!serviceCategoryId) {
            // TODO: use Sentry
            throw new Error('Service category is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: serviceCategoryId,
                    set: {
                        color: input.color,
                        name: input.name,
                        min_duration: input.minDuration,
                        min_additional_duration: input.minAdditionalDuration,
                        position: input.position,
                    },
                },
            });

            return data;
        } catch (caughtError) {
            // eslint-disable-next-line no-console
            console.error((caughtError as Error).message); // TODO: use Sentry
            throw caughtError;
        }
    };

    return {
        serviceCategoryUpdate: updateAction,
        loading,
        error,
    };
};

export default useUpdateServiceCategory;
