import { PAGE_TITLE_PRICE_SEASON_CREATE } from 'constants/pageTitles';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Box, Button, Stack, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import useCheckPriceSeasonCrossing from 'shared/hook/priceSeason/checkCrossing/useCheckPriceSeasonCrossing';
import { useStore } from 'store';

import {
    CREATE_PRICE_SEASON_DEFAULT_VALUES,
    PRICE_SEASON_VALIDATION_SCHEMA,
    SeasonFormFields,
} from './components/formData';
import PriceSeasonForm from './components/PriceSeasonForm';

const Create = observer(() => {
    const { checkSeason, checkSeasonLoading, createPriceSeasonLoading } = useCheckPriceSeasonCrossing();
    const { setPageTitle } = useStore('pageTitleStore');

    useEffect(() => {
        setPageTitle(PAGE_TITLE_PRICE_SEASON_CREATE);
    }, []);

    const { control, handleSubmit, setValue } = useForm<SeasonFormFields>({
        defaultValues: CREATE_PRICE_SEASON_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(PRICE_SEASON_VALIDATION_SCHEMA),
    });

    const handleCreateSeason = (data: SeasonFormFields) => {
        checkSeason(data).then();
    };

    return (
        <Box p={ 3 }>
            <Typography variant="h5">Создание сезона</Typography>
            <form onSubmit={ handleSubmit(handleCreateSeason) }>
                <PriceSeasonForm control={ control } setValue={ setValue } />
                <Stack direction="row" spacing={ 1 }>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={ checkSeasonLoading || createPriceSeasonLoading }
                    >
                        Создать
                    </LoadingButton>
                    <Link to="/price-season">
                        <Button
                            variant="outlined"
                        >
                            Отменить
                        </Button>
                    </Link>
                </Stack>
            </form>
        </Box>
    );
});

export default Create;
