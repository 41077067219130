import { PROPERTY_TITLE } from 'constants/propertyNames';

import { object, string } from 'yup';

export type CoachCategoryFormFields = {
    id: string;
    name: string;
}

export enum COACH_CATEGORY_FIELD_KEYS {
    name = 'name',
}

export const COACH_CATEGORY_DEFAULT_VALUES = {
    name: '',
};

export const COACH_CATEGORY_LABEL_DATA = {
    name: PROPERTY_TITLE,
};

export const COACH_CATEGORY_VALIDATION_SCHEMA = object().shape({
    name: string().required(),
});
