import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { CoachT } from 'types/Coach';

const DTO = t.type({
    id: t.string,
    employee: t.type({
        full_name: t.string,
    }),
    color: Nullable(t.string),
    active: t.boolean,
    work_days: t.array(t.type({
        day: t.number,
        start: t.string,
        end: t.string,
    })),
    coach_category: Nullable(t.string),
    service_app: Nullable(t.string),
});

export type CoachDTOT = t.TypeOf<typeof DTO>;

export const CoachDTO = tPromise.extendDecoder<CoachDTOT, CoachT>(
    DTO,
    ({
        id, color, active, employee, work_days, coach_category, service_app,
    }) => ({
        id,
        name: employee.full_name,
        color: color ?? '',
        categoryId: coach_category ?? '',
        serviceAppId: service_app ?? '',
        active,
        workDays: work_days,
    }),
) as t.Type<CoachT, CoachDTOT>;
