import { gql } from '@apollo/client';

export type UpdatePriceRatePayloadT = {
    price_sales_round_ids: string[];
    category_ids: string[];
    court_type_ids: string[];
    days: number[];
    time_periods: string[];
    sale_rate?: number;
    return_rate?: number;
    min_rate?: number;
    forward_transfer_cost?: number;
    back_transfer_cost?: number;
    transferable?: boolean;
    cancellable?: boolean;
    performer_return_income_rate?: number | null;
    performer_return_income_sum?: number | null;
    performer_sale_income_rate?: number | null;
    performer_sale_income_sum?: number | null;
    price_season_id: string;
    price_type_id: string;
    coach_category_id: string | null;
    group_view_id: string | null;
}

export type UpdatePriceRateResponseT = {
    status: string;
    reason: null | string;
    pricesCount: number;
}

export type UpdatePriceRateVarT = {
    appId: string;
    payload: UpdatePriceRatePayloadT;
};

export type UpdatePriceRateT = {
    priceMultipleUpdateRate: UpdatePriceRateResponseT;
}

export const EMPTY_UPDATE_PRICE_RATE_RESPONSE: UpdatePriceRateResponseT = {
    status: 'error',
    reason: '',
    pricesCount: 0,
};

export const UPDATE_PRICE_RATE = gql`
    mutation UpdatePriceRates($appId: String!, $payload: json!) {
        priceMultipleUpdateRate(app_id: $appId, payload: $payload) {
            status
            reason
            prices_count
        }
    }
`;
