import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import useDeleteMultiplePriceSeason from 'shared/hook/priceSeason/delete/useDeleteMultiplePriceSeason';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { PRICE_SEASON_HEADER_DATA } from '../domain/HeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT

const PriceSeasonList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;
    const { priceSeasons, count } = useStore('priceSeason');
    const { deleteMultiplePriceSeason } = useDeleteMultiplePriceSeason();

    return (
        <DataGrid
            rows={ priceSeasons }
            rowCount={ count }
            columns={ PRICE_SEASON_HEADER_DATA }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
            multipleDeleteAction={ deleteMultiplePriceSeason }
        />
    );
});

export default PriceSeasonList;
