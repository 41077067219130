import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    SERVICE_CATEGORY_QUERY,
    ServiceCategoryResponseT,
} from 'shared/graphql/query/serviceCategory/ServiceCategoryQuery';
import { EMPTY_SERVICE_CATEGORY_RESULT } from 'types/ServiceCategory';

import { ServiceCategoryDTO } from '../DTO/ServiceCategoryDTO';

import { ServiceCategoryDataT } from './ServiceCategoryData';

const useServiceCategory = (): ServiceCategoryDataT => {
    const [fetch, { loading }] = useLazyQuery<ServiceCategoryResponseT>(
        SERVICE_CATEGORY_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchServiceCategory = async (id: string): Promise<any> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                ServiceCategoryDTO,
                EMPTY_SERVICE_CATEGORY_RESULT,
                (serviceCategory) => serviceCategory,
                data?.serviceCategory,
            );
        } catch {
            return EMPTY_SERVICE_CATEGORY_RESULT;
        }
    };

    return {
        fetchServiceCategory,
        loading,
    };
};

export default useServiceCategory;
