import { gql } from '@apollo/client';
import { PriceSeasonT } from 'types/PriceSeason';

export type PriceDeleteSeasonResponseDataT = {
    deletePriceSeason: {
        id: PriceSeasonT['id'];
    };
} | null | undefined;

export type PriceDeleteSeasonVarT = {
    id: PriceSeasonT['id'];
};

export const PRICE_SEASON_DELETE = gql`
    mutation DeletePriceSeason($id: String = "") {
        deletePriceSeason: delete_price_season_by_pk(id: $id) {
            id
        }
    }
`;
