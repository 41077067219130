export type PriceSeasonT = {
    id: string;
    title: string;
    startAt: Date;
    finishAt: Date;
    isActive: boolean;
};

export type PriceSeasonsT = {
    priceSeasons: PriceSeasonT[];
    priceSeasonsCount: number;
};

export const EMPTY_PRICE_SEASON_RESULT: PriceSeasonT = {
    id: '',
    title: '',
    startAt: new Date(0),
    finishAt: new Date(0),
    isActive: false,
};

export const EMPTY_PRICE_SEASONS_RESULT: PriceSeasonsT = {
    priceSeasons: [],
    priceSeasonsCount: 0,
};

export const PRICE_SEASONS_IS_ACTIVE = {
    items: [{
        columnField: 'isActive', type: 'boolean', operatorValue: 'is', value: true, hasuraFilterFields: [],
    }],
    linkOperator: 'and',
};
