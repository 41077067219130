import React, { FC, MouseEventHandler } from 'react';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { IconButton, Tooltip } from '@mui/material';
import { useStore } from 'store';

import CreateScheduleModal from '../../Modal/CreateScheduleModal';

type PropsT = {
    id: string;
    title: string;
    actionCreateSchedule: (id: string) => Promise<any>;
    loading: boolean;
};

const CreateScheduleButton: FC<PropsT> = (props) => {
    const {
        id, title, actionCreateSchedule, loading,
    } = props;
    const { onOpen } = useStore('createScheduleModal');

    const handleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onOpen(id);
    };

    const onCreateScheduleButtonClick = () => {
        actionCreateSchedule(id).then();
    };

    return (
        <>
            <Tooltip title="Сгенерировать расписание" placement="top">
                <IconButton onClick={ handleButtonClick }>
                    <PostAddIcon color="success" />
                </IconButton>
            </Tooltip>
            <CreateScheduleModal
                id={ id }
                title={ title }
                loading={ loading }
                onCreateScheduleButtonClick={ onCreateScheduleButtonClick }
            />
        </>
    );
};

export default CreateScheduleButton;
