import { MODAL_TITLE_CREATE_GROUP } from 'constants/modalTitles';

import React, { FC } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    Button, DialogActions, DialogContent, IconButton, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import {
    AGE_TYPE_OPTIONS_QUERY,
    GROUP_CATEGORY_OPTIONS_QUERY,
} from 'shared/graphql/query/groupView/GroupViewQuery';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import useCreateGroupView from 'shared/hook/groupView/create/useCreateGroupView';
import { useStore } from 'store';

import {
    getGroupViewValidationSchema,
    GROUP_VIEW_DEFAULT_VALUES, GROUP_VIEW_FIELD_KEYS,
    GROUP_VIEW_LABEL_DATA,
    GroupViewFormFields,
} from './formData';

const CreateGroupViewModal: FC = observer(() => {
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { isOpen, onClose } = useStore('createGroupViewModal');
    const { getFilterOption } = useStore('filterOptions');
    const ageTypeFilterOptions = getFilterOption('ageType');
    const groupCategoryFilterOptions = getFilterOption('groupCategory');
    const { loading: ageTypeLoading } = useFetchFilterOptions(AGE_TYPE_OPTIONS_QUERY, 'ageType');
    const { loading: groupCategoryLoading } = useFetchFilterOptions(GROUP_CATEGORY_OPTIONS_QUERY, 'groupCategory');
    const { createGroupView } = useCreateGroupView();

    const { control, handleSubmit, reset } = useForm<GroupViewFormFields>({
        defaultValues: GROUP_VIEW_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getGroupViewValidationSchema()),
    });

    const { fields, insert, remove } = useFieldArray<GroupViewFormFields>({
        control,
        name: 'correctionFactors',
    });

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleAdd = (index: number) => {
        insert(index + 1, {
            label: `${fields.length + 1}`, value: '1',
        });
    };

    const handleCreateGroupView = (data: GroupViewFormFields) => {
        createGroupView(data).then(() => {
            onClose();
            toggleRefetchTrigger();
        });
    };

    return (
        <Modal open={ isOpen } maxWidth="lg">
            <ModalTitle onClose={ handleClose }>
                { MODAL_TITLE_CREATE_GROUP }
            </ModalTitle>
            <DialogContent>
                <Grid2 container xs={ 12 } spacing={ 2 }>
                    <Grid2 xs={ 12 }>
                        <TextField
                            label={ GROUP_VIEW_LABEL_DATA.name }
                            control={ control }
                            name={ GROUP_VIEW_FIELD_KEYS.name }
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <Switch
                            label={ GROUP_VIEW_LABEL_DATA.groupConstantPrice }
                            control={ control }
                            name={ GROUP_VIEW_FIELD_KEYS.groupConstantPrice }
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <Switch
                            label={ GROUP_VIEW_LABEL_DATA.ignoreBusyCoaches }
                            control={ control }
                            name={ GROUP_VIEW_FIELD_KEYS.ignoreBusyCoaches }
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <Select
                            name={ GROUP_VIEW_FIELD_KEYS.ageTypeId }
                            label={ GROUP_VIEW_LABEL_DATA.ageTypeId }
                            control={ control }
                            options={ ageTypeFilterOptions }
                            loading={ ageTypeLoading }
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <Select
                            name={ GROUP_VIEW_FIELD_KEYS.categoryId }
                            label={ GROUP_VIEW_LABEL_DATA.categoryId }
                            control={ control }
                            options={ groupCategoryFilterOptions }
                            loading={ groupCategoryLoading }
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <TextField
                            label={ GROUP_VIEW_LABEL_DATA.clientsForMainCoach }
                            control={ control }
                            name={ GROUP_VIEW_FIELD_KEYS.clientsForMainCoach }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <TextField
                            label={ GROUP_VIEW_LABEL_DATA.minSize }
                            control={ control }
                            name={ GROUP_VIEW_FIELD_KEYS.minSize }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <TextField
                            label={ GROUP_VIEW_LABEL_DATA.avgSize }
                            control={ control }
                            name={ GROUP_VIEW_FIELD_KEYS.avgSize }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <TextField
                            label={ GROUP_VIEW_LABEL_DATA.maxSize }
                            control={ control }
                            name={ GROUP_VIEW_FIELD_KEYS.maxSize }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <Typography variant="h6">
                            { GROUP_VIEW_LABEL_DATA.correctionFactors }
                        </Typography>
                    </Grid2>
                    { fields.length > 0 ? fields.map((field, index) => (
                        <Grid2 key={ field.id } container xs={ 12 } spacing={ 2 } display="flex" alignItems="center">
                            <Grid2>
                                <Typography>
                                    { index + 1 }.
                                </Typography>
                            </Grid2>
                            <Grid2 flex={ 1 }>
                                <TextField
                                    control={ control }
                                    name={ `correctionFactors.${index}.value` }
                                    type="number"
                                />
                            </Grid2>
                            <Grid2>
                                <IconButton
                                    color="info"
                                    onClick={ () => handleAdd(index) }
                                >
                                    <AddIcon />
                                </IconButton>
                                <IconButton
                                    color="error"
                                    onClick={ () => remove(index) }
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </Grid2>
                        </Grid2>
                    )) : (
                        <Grid2>
                            <Button
                                variant="outlined"
                                startIcon={ <AddIcon /> }
                                onClick={ () => handleAdd(0) }
                            >
                                Добавить поле
                            </Button>
                        </Grid2>
                    ) }
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose } color="primary">
                    Отмена
                </Button>
                <Button
                    color="success"
                    variant="contained"
                    onClick={ handleSubmit(handleCreateGroupView) }
                    autoFocus
                >
                    Сохранить
                </Button>
            </DialogActions>
        </Modal>
    );
});

export default CreateGroupViewModal;
