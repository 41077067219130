import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { PRICE_SEASON_QUERY, PriceSeasonResponseT } from 'shared/graphql/query/priceSeason/PriceSeasonQuery';
import { EMPTY_PRICE_SEASON_RESULT, PriceSeasonT } from 'types/PriceSeason';

import { PriceSeasonDTO } from '../DTO/PriceSeasonDTO';

import { PriceSeasonDataT } from './PriceSeasonData';

const usePriceSeason = (): PriceSeasonDataT => {
    const [fetch, { loading, error }] = useLazyQuery<PriceSeasonResponseT>(PRICE_SEASON_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchPriceSeason = async (id: string): Promise<PriceSeasonT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                PriceSeasonDTO,
                EMPTY_PRICE_SEASON_RESULT,
                (priceSeason) => priceSeason,
                data?.priceSeason,
            );
        } catch {
            return EMPTY_PRICE_SEASON_RESULT;
        }
    };

    return {
        fetchPriceSeason,
        loading,
        error,
    };
};

export default usePriceSeason;
