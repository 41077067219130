import { gql } from '@apollo/client';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

export type DeletePriceSalesRoundResponseData = {
    deletePriceSalesRound: {
        id: PriceSalesRoundT['id'];
    };
};

export type DeletePriceSalesRoundVarT = {
    id: PriceSalesRoundT['id'];
};

export const PRICE_SALES_ROUND_DELETE = gql`
    mutation DeletePriceSalesRound($id: String = "") {
        delete_price_sales_round: delete_price_sales_round_by_pk(id: $id) {
            id
        }
    }
`;
