import { COMPARE_OPERATOR } from 'constants/gqlOperators';

import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceTimePeriodFilterT } from 'types/Price';

const DTO = t.type({
    price_type_id: t.string,
    price_season_id: t.string,
    days: t.array(t.number),
    court_type_ids: t.array(t.string),
    service_category_ids: t.array(t.string),
    coach_category_ids: t.array(t.string),
    group_view_ids: t.array(t.string),
});

export type PriceTimePeriodsDTOT = t.TypeOf<typeof DTO>;

export const PriceTimePeriodsDTO = tPromise.extendType<PriceTimePeriodsDTOT, PriceTimePeriodFilterT>(
    DTO,
    ({
        price_type_id, price_season_id, days, court_type_ids, service_category_ids, coach_category_ids, group_view_ids,
    }) => ({
        priceTypeId: price_type_id,
        priceSeasonId: price_season_id,
        days: days || [],
        courtTypeIds: court_type_ids,
        serviceCategoryIds: service_category_ids,
        coachCategoryIds: coach_category_ids,
        groupViewIds: group_view_ids,
    }),
    ({
        priceTypeId, priceSeasonId, courtTypeIds, serviceCategoryIds, days, groupViewIds, coachCategoryIds,
    }) => {
        const filter: any = Object.entries({
            price_type_id: priceTypeId && { [COMPARE_OPERATOR.is]: priceTypeId },
            price_season_id: priceSeasonId && { [COMPARE_OPERATOR.is]: priceSeasonId },
            day: days.length > 0 && { [COMPARE_OPERATOR.isAnyOf]: days },
            court_type: courtTypeIds.length > 0 && { id: { [COMPARE_OPERATOR.isAnyOf]: courtTypeIds } },
            service_category: serviceCategoryIds.length > 0 && { id: { [COMPARE_OPERATOR.isAnyOf]: serviceCategoryIds } },
            group_view_id: groupViewIds.length > 0 && { [COMPARE_OPERATOR.isAnyOf]: groupViewIds },
            coach_category_id: coachCategoryIds.length > 0 && { [COMPARE_OPERATOR.isAnyOf]: coachCategoryIds },
        })
            .filter(([, value]) => !!value)
            .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

        return {
            ...filter,
        };
    },
) as t.Type<any, PriceTimePeriodsDTOT>;
