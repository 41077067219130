export type CoachT = {
    id: string;
    name: string;
    color?: string;
    active?: boolean;
    workDays?: Array<{
        day: number;
        start: string;
        end: string;
    }>;
    categoryId?: string;
    serviceAppId?: string;
};

export type CoachesT = {
    coaches: CoachT[];
    coachesCount: number;
}

export const EMPTY_COACHES_RESULT: CoachesT = {
    coaches: [],
    coachesCount: 0,
};

export const EMPTY_COACH_RESULT: CoachT = {
    id: '',
    name: '',
    color: '',
    active: false,
    workDays: [],
    categoryId: '',
    serviceAppId: '',
};
