import { ERROR_SCHEDULE_CREATE } from 'constants/notificationMessage';

import { gql } from '@apollo/client';

export type CreateSchedulePayloadT = {
    price_season_id: string;
    slot_duration: number;
}

export type CreateScheduleResponseT = {
    status: string;
    reason: string;
}

export type CreateScheduleVarT = {
    appId: string;
    payload: CreateSchedulePayloadT;
};

export type CreateScheduleT = {
    createSchedule: CreateScheduleResponseT;
}

export const EMPTY_CREATE_SCHEDULE_RESPONSE: CreateScheduleResponseT = {
    status: 'error',
    reason: ERROR_SCHEDULE_CREATE,
};

export const CREATE_SCHEDULE = gql`
    mutation CreateSchedule($appId: String!, $payload: json!) {
        createSchedule: bulkOperationCreateSchedule(app_id: $appId, payload: $payload) {
            status
            reason
        }
    }
`;
