import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceSalesRoundsT } from 'types/PriceSalesRound';

import { PriceSalesRoundDTO } from './PriceSalesRoundDTO';

const DTO = t.type({
    priceSalesRounds: t.array(PriceSalesRoundDTO),
});

export type PriceSalesRoundsDTOT = t.TypeOf<typeof DTO>;

export const PriceSalesRoundsDTO = tPromise.extendDecoder<PriceSalesRoundsDTOT, PriceSalesRoundsT>(
    DTO,
    ({
        priceSalesRounds,
    }) => ({
        priceSalesRounds,
    }),
) as t.Type<PriceSalesRoundsT, PriceSalesRoundsDTOT>;
