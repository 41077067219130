import { FC } from 'react';
import {
    Drawer, DrawerProps, styled, Toolbar,
} from '@mui/material';
import { withTheme } from '@mui/styles';

export const StyledDrawer: FC<DrawerProps> = styled(withTheme(Drawer))((props) => ({
    width: `${props.theme.sidebarWidth}px`,
    flexShrink: 0,

    '& .MuiDrawer-paper': {
        width: `${props.theme.sidebarWidth}px`,
        boxSizing: 'border-box',
    },
}));

export const StyledDrawerToolbar = styled(Toolbar)({
    justifyContent: 'center',
});
