import { useMemo } from 'react';
import { GridColumns } from '@mui/x-data-grid-premium';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

import { SCHEDULE_SLOT_RESERVE_HEADER_DATA } from './HeaderData';

export const prepareScheduleSlotReserveHeaderData = (serviceCategories: FilterOptionT[], coaches: FilterOptionT[], groups: FilterOptionT[]): GridColumns =>
    useMemo(() => mapFields(SCHEDULE_SLOT_RESERVE_HEADER_DATA, {
        serviceCategory: serviceCategories,
        coachesIds: coaches,
        group: groups,
    }), [serviceCategories, coaches, groups]);
