import { PriceFormFieldsPayloadT } from '../graphql/mutation/price/PriceMultipleCreate';

export const preparePriceMultipleCreateData = (
    input: PriceFormFieldsPayloadT,
) => {
    const {
        categoryIds,
        courtTypeIds,
        days,
        time,
        priceSeasonId,
        priceTypeId,
        coachCategoryIds,
        groupViewIds,
    } = input;
    const payload = [];

    for (let i = 0; i < categoryIds.length; i++) {
        for (let j = 0; j < courtTypeIds.length; j++) {
            for (let k = 0; k < days.length; k++) {
                for (let n = 0; n < time.length; n++) {
                    for (let m = 0; m < (coachCategoryIds.length || 1); m++) {
                        for (let l = 0; l < (groupViewIds.length || 1); l++) {
                            payload.push({
                                category_id: categoryIds[i],
                                court_type_id: courtTypeIds[j],
                                price_type_id: priceTypeId ?? '',
                                price_season_id: priceSeasonId ?? '',
                                day: days[k],
                                time_start: time[n].timeStart,
                                time_end: time[n].timeEnd,
                                coach_category_id: coachCategoryIds[m],
                                group_view_id: groupViewIds[l],
                            });
                        }
                    }
                }
            }
        }
    }

    return payload;
};
