import {
    PROPERTY_ACTIONS, PROPERTY_COLOR, PROPERTY_FULL_NAME, PROPERTY_IS_ACTIVE, PROPERTY_WORK_DAYS,
} from 'constants/propertyNames';

import React, { FC, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Chip, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import { EmptyCell } from 'shared/component/DataGrid/Cell/EmptyCell';
import { FlexWrapBox } from 'shared/component/DataGrid/domain/Styled';
import useDeleteCoach from 'shared/hook/coach/delete/useDeleteCoach';
import { filterOperators } from 'shared/utils/headerData/filterOperators';
import { getDayByNumber } from 'shared/utils/headerData/getDayByNumber';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';
import { WorkTime } from 'types/Organization';

const renderWorkDays: FC<GridRenderCellParams> = (props) => {
    const { row: { workDays, id } } = props;

    if (workDays.length === 0) {
        return <EmptyCell />;
    }

    const renderWorkTimeItem = useMemo(() => workDays.map((time: WorkTime) => (
        <Chip
            label={ `${getDayByNumber(time.day)} | ${time.start} - ${time.end}` }
            variant="outlined"
            color="primary"
            size="small"
            key={ `${id}-${time.day}` }
        />
    )), [workDays]);

    return (
        <FlexWrapBox>
            { renderWorkTimeItem }
        </FlexWrapBox>
    );
};

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, title } } = props;
    const { coachDelete } = useDeleteCoach();
    const { onOpen } = useStore('updateCoachModal');

    return (
        <>
            <IconButton onClick={ () => onOpen(id) }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ title } actionDelete={ coachDelete } />
        </>
    );
};

export const COACH_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_FULL_NAME,
        field: 'name',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_COLOR,
        field: 'color',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params) => (
            <Box sx={ {
                display: 'flex', alignItems: 'center', position: 'relative', width: '100%', justifyContent: 'center',
            } }
            >
                { params.row.color }
                <Box sx={ {
                    width: '20px', height: '20px', borderRadius: '50%', position: 'absolute', right: '12px', border: '1px solid black', background: params.row.color,
                } }
                />
            </Box>
        ),
    },
    {
        headerName: PROPERTY_IS_ACTIVE,
        field: 'active',
        renderCell: isActiveIcon,
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_WORK_DAYS,
        field: 'workDays',
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['work_days', '_cast', 'String'],
        filterOperators,
        renderCell: renderWorkDays,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 0.3,
        align: 'center',
        headerAlign: 'center',
        renderCell: RenderActionCell,
    },
];
