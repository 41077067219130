import { ERROR_SERVICE_CATEGORY_UPDATE, SUCCESS_SERVICE_CATEGORY_UPDATE } from 'constants/notificationMessage';

import { ServiceCategoryFormFields } from 'page/ServiceCategory/components/formData';
import useUpdateServiceCategory from 'shared/data-hook/serviceCategory/update/useUpdateServiceCategory';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { ServiceCategoryT } from 'types/ServiceCategory';

const useServiceCategoryUpdate = () => {
    const {
        loading,
        error,
        serviceCategoryUpdate: updateAction,
    } = useUpdateServiceCategory();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updateServiceCategory = async (input: ServiceCategoryFormFields, serviceCategoryId: ServiceCategoryT['id']) => {
        const response = await updateAction(input, serviceCategoryId);

        if (error || !response || !response.updateServiceCategory) {
            showError(ERROR_SERVICE_CATEGORY_UPDATE);
        } else {
            showSuccess(SUCCESS_SERVICE_CATEGORY_UPDATE);
        }

        toggleRefetchTrigger();
    };

    return {
        updateServiceCategory,
        loading,
    };
};

export default useServiceCategoryUpdate;
