import { useLazyQuery } from '@apollo/client';
import { CoachCategoryDataT } from 'shared/data-hook/coachCategory/fetch/fetchOne/CoachCategoryData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    COACH_CATEGORY_QUERY,
    CoachCategoryResponseT,
} from 'shared/graphql/query/coachCategory/CoachCategoryQuery';
import { CoachCategoryT, EMPTY_COACH_CATEGORY_RESULT } from 'types/CoachCategory';

import { CoachCategoryDTO } from '../DTO/CoachCategory';

const useCoachCategory = (): CoachCategoryDataT => {
    const [fetch, { loading, error }] = useLazyQuery<CoachCategoryResponseT>(
        COACH_CATEGORY_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchCoachCategory = async (id: string): Promise<CoachCategoryT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                CoachCategoryDTO,
                EMPTY_COACH_CATEGORY_RESULT,
                (coachCategory) => coachCategory,
                data?.coachCategory,
            );
        } catch {
            return EMPTY_COACH_CATEGORY_RESULT;
        }
    };

    return {
        fetchCoachCategory,
        loading,
        error,
    };
};

export default useCoachCategory;
