import { gql } from '@apollo/client';
import { PaymentMethodT } from 'types/PaymentMethod';

export type PaymentMethodDeleteResponseDataT = {
    deletePaymentMethod: {
        id: PaymentMethodT['id'];
    };
} | null | undefined;

export type PaymentMethodDeleteVarT = {
    id: PaymentMethodT['id'];
};

export const PAYMENT_METHOD_DELETE = gql`
    mutation DeletePaymentMethod($id: String = "") {
        deletePaymentMethod: delete_payment_method_by_pk(id: $id) {
            id
        }
    }
`;
