import { BREAKPOINTS } from 'config/Breakpoints';
import { css, FlattenSimpleInterpolation } from 'styled-components';
import { ViewPorts } from 'types/Breakpoints';

export const withBreakpoint = (vp: ViewPorts) => (content: FlattenSimpleInterpolation) => {
    const size = BREAKPOINTS[vp];

    return css`
        @media (max-width: ${size}px) {
            ${content}
        }
    `;
};
