export enum FilterOptions {
    coachCategory = 'coachCategory',
    coach = 'coach',
    court = 'court',
    courtType = 'courtType',
    employee = 'employee',
    group = 'group',
    groupView = 'groupView',
    memberCardType = 'memberCardType',
    organization = 'organization',
    serviceApp = 'serviceApp',
    serviceCategory = 'serviceCategory',
    priceSeason = 'priceSeason',
    personalManager = 'personalManager',
    priceType = 'priceType',
    organizationLocation = 'organizationLocation',
    paymentType = 'paymentType',
    paymentMethod = 'paymentMethod',
    priceSalesRound = 'priceSalesRound',
    ageType = 'ageType',
    groupCategory = 'groupCategory',
    bonusCard = 'bonusCard'
}

export type FilterOptionsT = keyof typeof FilterOptions;
