import { useEffect } from 'react';
import usePriceMemberCard from 'shared/data-hook/priceMemberCard/fetch/fetchOne/usePriceMemberCard';
import { useStore } from 'store';

const useFetchPriceMemberCard = (priceSeasonId: string, priceTypeId: string) => {
    const { fetchPriceMemberCard, loading } = usePriceMemberCard();
    const {
        updatedItemId, isOpen, setPriceMemberCard,
    } = useStore('updatePriceMemberCardModal');

    useEffect(() => {
        if (isOpen && updatedItemId && priceTypeId && priceSeasonId) {
            fetchPriceMemberCard(updatedItemId, priceSeasonId, priceTypeId).then((fetchedPriceMemberCard) => {
                setPriceMemberCard(fetchedPriceMemberCard);
            });
        }
    }, [updatedItemId, priceSeasonId, priceTypeId]);

    return {
        loading,
    };
};

export default useFetchPriceMemberCard;
