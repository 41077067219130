import {
    ERROR_SERVICE_APP_UPDATE,
    SUCCESS_SERVICE_APP_UPDATE,
} from 'constants/notificationMessage';
import { SERVICE_APP } from 'constants/routes';

import { useNavigate } from 'react-router-dom';
import { ServiceAppFormFields } from 'page/ServiceApp/formData';
import useServiceAppUpdate from 'shared/data-hook/serviceApp/update/useServiceAppUpdate';
import { useStore } from 'store';

import { useNotification } from '../../useNotification';

const useUpdateServiceApp = () => {
    const { error, serviceAppUpdate, loading } = useServiceAppUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();
    const navigate = useNavigate();
    const updateServiceApp = async (data: ServiceAppFormFields, id: string) => {
        const response = await serviceAppUpdate(data, id);

        if (error || !response || !response.updateServiceApp) {
            showError(ERROR_SERVICE_APP_UPDATE);
        } else {
            showSuccess(SUCCESS_SERVICE_APP_UPDATE);
            toggleRefetchTrigger();
        }

        return navigate(SERVICE_APP);
    };

    return {
        updateServiceApp,
        loading,
    };
};

export default useUpdateServiceApp;
