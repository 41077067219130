import {
    PROPERTY_ACTIONS, PROPERTY_IS_ACTIVE, PROPERTY_POSITION, PROPERTY_TITLE,
} from 'constants/propertyNames';

import React, { FC, MouseEventHandler } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import useDeletePaymentMethod from 'shared/hook/paymentMethod/delete/useDeletePaymentMethod';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, title } } = props;
    const { paymentMethodDelete } = useDeletePaymentMethod();
    const { onOpen } = useStore('updatePaymentMethod');

    const handleUpdateClick: MouseEventHandler<HTMLButtonElement> = () => {
        onOpen(id);
    };

    return (
        <>
            <IconButton onClick={ handleUpdateClick }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ title } actionDelete={ paymentMethodDelete } />
        </>
    );
};

export const PAYMENT_METHOD_SETTING_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'title',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['title'],
        hasuraSortFields: ['title'],
    },
    {
        headerName: PROPERTY_POSITION,
        field: 'position',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['position'],
        hasuraSortFields: ['position'],
    },
    {
        headerName: PROPERTY_IS_ACTIVE,
        field: 'isActive',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
        hasuraFilterFields: ['is_active'],
        hasuraSortFields: ['is_active'],
        renderCell: isActiveIcon,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
    },
];
