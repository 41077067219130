import { PAGE_TITLE_SERVICE_APP } from 'constants/pageTitles';

import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import useFetchServiceApp from 'shared/data-hook/serviceApp/fetch/fetchAll/useFetchServiceApp';
import usePageTitle from 'shared/hook/usePageTitle';
import { useStore } from 'store';

import ServiceAppForm from './components/ServiceAppForm';

const Update: FC = observer(() => {
    const { fetch: fetchServiceApp } = useFetchServiceApp();
    const { setServiceApp } = useStore('serviceApp');
    const { refetchTrigger } = useStore('refetchTrigger');

    usePageTitle(PAGE_TITLE_SERVICE_APP);

    useEffect(() => {
        fetchServiceApp().then((result) => {
            setServiceApp(result);
        });
    }, [refetchTrigger]);

    return <ServiceAppForm />;
});

export default Update;
