import { useEffect } from 'react';
import usePrice from 'shared/data-hook/price/fetch/fetchOne/usePrice';
import { useStore } from 'store';

const useFetchPrice = (id: string) => {
    const { fetchPrice, loading } = usePrice();
    const { updatePriceId, isOpen, setPrice } = useStore('updatePrice');

    useEffect(() => {
        if (isOpen && updatePriceId && id) {
            fetchPrice(updatePriceId).then((fetchedPrice) => {
                setPrice(fetchedPrice);
            });
        }
    }, [isOpen, updatePriceId, id]);

    return {
        loading,
    };
};

export default useFetchPrice;
