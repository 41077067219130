import { useMutation } from '@apollo/client';
import { camelizeKeys } from 'humps';
import {
    DELETE_MULTIPLE_SERVICE_CATEGORY,
    DeleteMultipleServiceCategoryResponseDataT,
    DeleteMultipleServiceCategoryResponseT,
    DeleteMultipleServiceCategoryVarT,
} from 'shared/graphql/mutation/serviceCategory/ServiceCategoryMultipleDelete';
import { ServiceCategoryT } from 'types/ServiceCategory';

import { ServiceCategoryMultipleDeleteDataT } from './ServiceCategoryMultipleDeleteData';

const useServiceCategoryMultipleDelete = (): ServiceCategoryMultipleDeleteDataT => {
    const [deleteServiceCategories, { loading: deleteLoading, error: errorDelete }] = useMutation<
        DeleteMultipleServiceCategoryResponseT,
        DeleteMultipleServiceCategoryVarT
    >(DELETE_MULTIPLE_SERVICE_CATEGORY);

    const deleteAction = async (serviceCategoryIds: [ServiceCategoryT['id']]) => {
        try {
            const { data } = await deleteServiceCategories({
                variables: {
                    ids: serviceCategoryIds,
                },
            });

            return camelizeKeys(data?.deleteMultipleServiceCategory) as DeleteMultipleServiceCategoryResponseDataT;
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        loading: deleteLoading,
        deleteMultipleServiceCategory: deleteAction,
        error: errorDelete,
    };
};

export default useServiceCategoryMultipleDelete;
